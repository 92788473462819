import React, { useEffect, useState } from "react";
import {
    Box,
    SimpleGrid,
    Button,
    Flex,
    Text,
    useColorModeValue,
    Avatar,
} from "@chakra-ui/react";
import banner from "assets/img/layout/banner.png";
import avatar from "assets/img/layout/logo_21.png";
import Card from "components/card/Card";
import Notifications from "views/admin/profile/components/Notifications";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { LogoutUsuario } from '../../../util/ValidarAutenticacao';
import LoaderBlack from 'components/loading/LoaderBlack';
import { useHistory } from "react-router-dom";
import ModalConfirmacao from 'components/modal/ModalConfirmacao';
import ModalExcluirUsuario from 'components/modal/ModalExcluirUsuario';
import MessageSreen from 'components/message/MessageSreen';

export default function Overview() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [nomeUsuario, setNomeUsuario] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');

    const [tipoUsuario, setTipoUsuario] = useState('');
    const [objPermissoes, setObjPermissoes] = useState({});
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [idUsuarioLogado, setIdUsuarioLogado] = useState('');
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [listUa, setListUa] = useState([]);
    const [isValidSenha, setIsValidSenha] = useState(true);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("white !important", "#111C44 !important");

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const idUsuario = localStorage.getItem('@HE-idUsuarioLogado');
            setIdUsuarioLogado(idUsuario);

            const val = localStorage.getItem('@HE-obj-com-permissoes-acesso');
            const obj = JSON.parse(val);
            setObjPermissoes(obj);

            const nomeUser = localStorage.getItem('@HE-primeiroNomeUsuarioLogado');
            const tel = localStorage.getItem('@HE-telefoneUsuariLogado');
            const emailPessoal = localStorage.getItem('@HE-emailPessoalUsuario');
            const perfil = localStorage.getItem('@HE-descPerfilUsuarioLogado');

            setTipoUsuario(perfil);
            setNomeUsuario(nomeUser);
            setTelefone(tel);
            setEmail(emailPessoal);

            await carregaInformacoesTela(idUsuario);
        }
        loaderScreen();
    }, []);

    const carregaInformacoesTela = async (idUsuarioP) => {

        setLoading(true);
        const url = `delete/listaUsuarioAndUaAndSafraAndPessoa?idUsuario=${idUsuarioP}`;
        const response = await Api.getRequest(url);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {

            const res = response.respostaRequisicao.data;
            const listUaP = res.listUa;
            setListUa(listUaP);
            setLoading(false);

        } else {
            setLoading(false);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const ativarFlagInativo = async (inputValue) => {
        setOpenModalDialog(false);

        const json = { idUsuario: idUsuarioLogado };

        setLoading(true);
        const response = await Api.putRequest(`api/usuario/deleteUsuario/${idUsuarioLogado}`, json);
        setLoading(false);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            setOpenModalConfimacao(true);
        } else {
            setLoading(false);
        }
    }

    async function deletarUsuarioPropriedadesSafrasSeringueiros(inputValue) {     // ATENCAOOOOOOOOOOO ESSE CARA APAGA TODOS OS DADOS DO USUARIO DE VERDADE, ESTOU USANDO O METODO ACIMA ATE DECIDIR 

        const url = `api/usuario/validarSenhaUsuario?idUsuario=${idUsuarioLogado}&senha=${inputValue}`;
        const responseValidSenha = await Api.getRequest(url);
        if (responseValidSenha.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const resValid = responseValidSenha.respostaRequisicao.data;

            if (resValid == true) {
                setIsValidSenha(true);

                setOpenModalDialog(false);
                const jsonDelete = {
                    idUa: null,
                    idSafra: null,
                    idUsuario: idUsuarioLogado,
                    listPessoaSimple: [],
                    tipoDelete: ActionTypes.TIPO_DELETE_USUARIO_PROPRIEDADES_SAFRAS_PESSOAS,
                }

                setLoading(true);
                const response = await Api.deleteRequest('delete/deletarRegistros', jsonDelete);
                setLoading(false);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    setOpenModalConfimacao(true);
                } else {
                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();
                }
            } else {
                setIsValidSenha(false);
            }
        } else {
            setOpenModalDialog(false);
            setNumStatusResposta(responseValidSenha.numeroStatusResposta);
            showAlert();
        }
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const logout = async () => {
        setOpenModalConfimacao(false);
        await LogoutUsuario();
        history.push("/auth/sign-in");
    }


    const editarProdutor = () => {
        const json = {
            idUsuario: idUsuarioLogado,
            insertOuUpdate: ActionTypes.OPERACAO_UPDATE,
            idTipoUsuario: ActionTypes.TIPO_USUARIO_PRODUTOR,
        }
        localStorage.setItem('@HE-dadosProdutorParaEdicao', JSON.stringify(json));
        history.push("/admin/produtores-form");
    }


    function renderDadosUsuario() {
        return (
            <Box w={'100%'}>
                {listUa.length > 0 &&
                    <Card direction='column' w='100%' px='10px' py='10px'>
                        <Text mt={'10px'} color={textColor} fontSize='18px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Minhas propriedades'}</Text>
                        <Text mb={'10px'} color={'gray.500'} fontSize='14px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Visão geral'}</Text>
                        {listUa.map((item, index) => {
                            return (
                                <Box key={index} px={'10px'} py={'10px'} mb={'20px'} borderWidth={'1px'} borderColor={'blackAlpha.700'} borderRadius={'10px'}>
                                    <Flex justify={'start'} align={'center'}>
                                        <Text color={textColor} fontSize='18px' fontWeight={'bold'} lineHeight={'100%'}>{'Propriedade:'}</Text>
                                        <Text color={'orange.700'} fontSize='18px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{item.nomeFazenda}</Text>
                                    </Flex>
                                    {item.listSafra.length > 0 &&
                                        item.listSafra.map((itemP, indexP) => {
                                            return (
                                                <Box key={indexP} pl={'20px'} mt={'10px'}>
                                                    <Flex justify={'start'} align={'center'}>
                                                        <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{'Safra:'}</Text>
                                                        <Text color={'orange.700'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{itemP.nomeSafra}</Text>
                                                    </Flex>
                                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} pl={'20px'} py={'5px'}>{'Seringueiros:'}</Text>
                                                    {itemP.listSafraPessoa.length > 0 &&
                                                        itemP.listSafraPessoa.map((iteX, indX) => {
                                                            return (
                                                                <Box key={indX} pl={'30px'}>
                                                                    <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{iteX.nomePessoa}</Text>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            )
                                        })
                                    }
                                </Box>
                            )
                        })}
                    </Card>
                }
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'} zIndex="14">
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    return (
        <Box pt={{ base: "110px", md: "60px", xl: "40px" }}>
            {renderAlert()}
            <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='2px'>
                <Card mb={{ base: "0px", lg: "20px" }} align='center'>
                    <Box bg={`url(${banner})`} bgSize='cover' borderRadius='16px' h='131px' w='100%' />
                    <Box position={'relative'}>
                        <Avatar mx='auto' src={avatar} h='87px' w='87px' mt='-43px' border='4px solid' borderColor={borderColor} />
                        <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>{nomeUsuario}</Text>
                        <Text color={'gray.600'} fontSize='sm'>{tipoUsuario}</Text>
                        <Box position={'absolute'} top={'5px'} right={'15px'}>
                            <Button w={'80px'} variant="miniBrand" onClick={() => { editarProdutor() }}>Editar</Button>
                        </Box>
                    </Box>
                </Card>
            </SimpleGrid>

            {objPermissoes.gerente &&
                <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
                    <Notifications
                        banner={banner}
                        avatar={avatar}
                        name='Adela Parkson'
                        job='Product Designer'
                        posts='17'
                        followers='9.7k'
                        following='274'
                    />
                </SimpleGrid>
            }

            <Flex px={'25px'} justify={'center'} align={'center'}>
                {renderDadosUsuario()}
            </Flex>

            <Flex px={'25px'} justify={'end'} align={'center'}>
                <Button variant="redBrand" onClick={() => { setOpenModalDialog(true) }}>Excluir Conta</Button>
            </Flex>

            {loading && <LoaderBlack isOpen={loading} />}
            {openModalConfimacao && <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { logout() }} titulo={'Sucesso!'} />}

            {openModalDialog &&
                <ModalExcluirUsuario
                    isOpen={openModalDialog}
                    onPressCancel={() => { setOpenModalDialog(false) }}
                    onConfirm={deletarUsuarioPropriedadesSafrasSeringueiros}
                    valueInput={''}
                    isValidSenha={isValidSenha}
                />
            }
        </Box>
    );
}
