import React, { useEffect, useState, useRef } from "react";
import {
    Box,
    useColorModeValue,
    Text,
    Flex,
    Button,
    Textarea,
    Checkbox,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    IconButton,
    Wrap,
    WrapItem,
    Input,
    Image,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import MessageSreen from 'components/message/MessageSreen';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import ImageModal from 'components/gallery/ImageModal';
import ModalDialog from 'components/modal/ModalDialog';
import { IoAddCircleSharp } from "react-icons/io5";
import { UPLOAD_FILE_REGRAS_DE_NEGOCIO } from '../../../util/SalvarImagem';
import { ChevronLeftIcon, ChevronRightIcon, DeleteIcon } from '@chakra-ui/icons';

export default function RegrasDeNegocio() {

    const inputRef = useRef(null);
    const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [moduloSistema, setModuloSistema] = useState('');
    const [regras, setRegras] = useState('');
    const [observacao, setObservacao] = useState('');
    const [idModuloSistema, setIdModuloSistema] = useState(null);
    const [idRegrasNegocio, setIdRegrasNegocio] = useState(null);
    const [dtUltimaAtualizacao, setDtUltimaAtualizacao] = useState('');
    const [maxCharacterObservacao, setMaxCharacterObservacao] = useState(2000);
    const [maxCharacterRegras, setMaxCharacterRegras] = useState(5000);
    const [checkPrecisaRevisao, setCheckPrecisaRevisao] = useState(false);
    const [idUsuarioLogado, setIdUsuarioLogado] = useState(0);

    const [numMesP, setNumMesP] = useState(0);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [fileImageModal, setFileImageModal] = useState(null);
    const [arquivoImagem, setArquivoImagem] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [idUrldArquivoTemporario, setIdUrldArquivoTemporario] = useState(0);

    const [qtdImages, setQtdImages] = useState(0);
    const [listUrlImages, setListUrlImages] = useState([]);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const cardShadowObs = useColorModeValue('0px 2px 10px rgba(0, 0, 0, 0.8)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            //window.scrollTo(0, 0);

            const idUserLogadoPP = localStorage.getItem('@HE-idUsuarioLogado');
            setIdUsuarioLogado(idUserLogadoPP);

            const idModuloSistemaP = localStorage.getItem('@HE-idModuloSistemaParaTelaDeRegras');
            setIdModuloSistema(idModuloSistemaP);
            await carregaInformacoesDaTela(idModuloSistemaP);
            setTelaCarregada(true);
        }
        loaderScreen();
        return () => {
            setIsVisibleAlert(false);
        }
    }, []);

    const carregaInformacoesDaTela = async (idModuloSistem) => {

        const url = `regrasDeNegocio/retornaListaDeRegrasDeNecio?idModuloSistema=${idModuloSistem}&idTipoAnexo=12`;
        const response = await Api.getRequest(url);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = response.respostaRequisicao.data;

            if (res.length > 0) {
                const obj = res[0];

                const listUrlImagesP = obj.listUrlImages;
                setListUrlImages(listUrlImagesP);

                const descricaoP = obj.descricao != null && obj.descricao != '' ? obj.descricao : '';
                const observacaoP = obj.observacao != null && obj.observacao != '' ? obj.observacao : '';
                const nomeModuloP = obj.nomeModulo;
                const idRegrasNegocioP = obj.idRegrasNegocio;
                const precisaRevisaoP = obj.precisaRevisao != null ? obj.precisaRevisao : false;
                const dtUltimaAtualizacaoP = obj.dtUltimaAtualizacao != null ? moment(obj.dtUltimaAtualizacao).format('DD/MM/YYYY') : '';

                setRegras(descricaoP);
                setObservacao(observacaoP);
                setModuloSistema(nomeModuloP);
                setIdRegrasNegocio(idRegrasNegocioP);
                setCheckPrecisaRevisao(precisaRevisaoP);
                setDtUltimaAtualizacao(dtUltimaAtualizacaoP);

                const zz1 = descricaoP.length;
                const ttCaracter = 5000 - zz1;
                setMaxCharacterRegras(ttCaracter);

                const obs = observacaoP.length;
                const ttObs = 2000 - obs;
                setMaxCharacterObservacao(ttObs);
            }
        } else {
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const salvarRegra = async () => {
        try {
            const json = {
                idRegrasNegocio: idRegrasNegocio,
                idModuloSistema: idModuloSistema,
                descricao: regras,
                observacao: observacao,
                nomeModulo: moduloSistema,
                precisaRevisao: checkPrecisaRevisao,
                idUsuarioAtualizacao: idUsuarioLogado,
            }

            const response = await Api.postRequest(`regrasDeNegocio/inserirAndUpdateRegraDeNecio`, json);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                await carregaInformacoesDaTela(idModuloSistema);

                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            console.log('Erro na classe RegrasDeNegocio metodo salvarRegra', error);
        }
    }

    const changeRegras = (val) => {
        setRegras(val);
        const xx = val.length;
        const num = 5000 - xx;
        setMaxCharacterRegras(num);
    }

    const changeObservacao = (val) => {
        setObservacao(val);
        const xx = val.length;
        const num = 2000 - xx;
        setMaxCharacterObservacao(num);
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    async function salvarImagens() {
        setShowImageModal(false);

        const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');

        const jsonObj = {
            idUsuario: idUsuarioLogado,
            idModuloSistema: idModuloSistema,
            idTipoAnexo: ActionTypes.ID_TIPO_ANEXO_REGRAS_DE_NEGOCIO,
            img: arquivoImagem,
        }
        
        const response = await UPLOAD_FILE_REGRAS_DE_NEGOCIO(jsonObj);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {

            setFileImageModal(null);
            setArquivoImagem(null);
            await carregaInformacoesDaTela(idModuloSistema);

            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        } else {
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const closeModalImagem = () => {
        setShowImageModal(false);
        setFileImageModal(null);
        setArquivoImagem(null);
    }
    const handleClick = () => {
        inputRef.current.click();
    };
    const nextImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === listUrlImages.length - 1 ? 0 : prevIndex + 1
        );
    };
    const prevImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? listUrlImages.length - 1 : prevIndex - 1
        );
    };
    const handleImageClickCarrossel = (index) => {
        setCurrentIndex(index);
        onOpen();
    };
    const organizarExclusaoDeArquivo = (item) => {
        setIdUrldArquivoTemporario(item.idUrldArquivo);
        setOpenModalDialog(true);
    }

    const inserirFlagDeletado = async () => {

        setOpenModalDialog(false);
        const json = {
            idUrldArquivo: idUrldArquivoTemporario,
        }
        const url = `api/urlArquivo/gravarFlagDeArquivoDeletado`;
        const response = await Api.putRequest(url, json);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const arrayFil = listUrlImages.filter(x => x.idUrldArquivo != idUrldArquivoTemporario);
            setListUrlImages(arrayFil);
        } else {
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileSizeInMB = await file.size / (1024 * 1024); // CONVERT FILE SIZE TO MB, SE O RESULTADO DA CONTA FOR 1 E IGUAL A 1MB, 3 E IGUAL A 3MB...

            if (fileSizeInMB > 5) {
                setNumStatusResposta(ActionTypes.ARQUIVO_MUITO_GRANDE);
                showAlert();
            } else {
                const imageUrl = URL.createObjectURL(file);
                setArquivoImagem(file);
                setFileImageModal(imageUrl);
                setShowImageModal(true);
            }
        } else {
            console.log('Arquivo nao existe');
        }
    }

    function remderRegras() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex flexDirection={'column'} align='center' justify={'center'}>
                    <Text mb={'10px'} color={textColor} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Descreva as regras da tela/modulo'}</Text>
                    <Textarea
                        value={regras}
                        onChange={(e) => { changeRegras(e.target.value) }}
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={'blackAlpha.300'}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'medium'}
                        maxLength={5000}
                        h={'350px'}
                    />
                </Flex>
                <Text mt={'5px'} color={'gray.500'} fontSize={'14px'} fontWeight='bold' align={'end'}>{`Caracteres restantes ${maxCharacterRegras}`}</Text>
            </Box>
        )
    }
    function remderObservacao() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex flexDirection={'column'} align='center' justify={'center'}>
                    <Text mb={'10px'} color={textColor} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Itens para revisão'}</Text>
                    <Textarea
                        value={observacao}
                        onChange={(e) => { changeObservacao(e.target.value) }}
                        errorBorderColor='crimson'
                        variant="filled"
                        placeholder="Descreva os itens que precisam de refinamentos"
                        borderRadius="10px"
                        borderColor={'blackAlpha.300'}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'medium'}
                        maxLength={2000}
                        h={'200px'}
                    />
                </Flex>
                <Flex mt={'5px'} px={'10px'} py={'10px'} justify={'space-between'} align={'center'}>
                    <Checkbox
                        size='lg'
                        colorScheme='blue'
                        isChecked={checkPrecisaRevisao}
                        onChange={() => { setCheckPrecisaRevisao(!checkPrecisaRevisao) }}
                        borderColor={'black'}
                    >
                        <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Precisa de revisão</Text>
                    </Checkbox>
                    <Text mt={'5px'} color={'gray.500'} fontSize={'14px'} fontWeight='bold' align={'end'}>{`Caracteres restantes ${maxCharacterObservacao}`}</Text>
                </Flex>
            </Box>
        )
    }

    function renderUploadFile() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex px={'10px'} pb={'15px'} justify='space-between' align='center'>
                    <Box>
                        <Text color={textColor} fontSize={'18px'} fontWeight='bold' lineHeight={'100%'}>{`Imagens`}</Text>
                        <Text color={textColor} fontSize={'15px'} fontWeight='normal' mt={'5px'} lineHeight={'100%'}>{`Clique na imagem para visualizar`}</Text>
                    </Box>
                    <Box>
                        <Input type="file" ref={inputRef} display="none" onChange={handleFileChange} accept="image/png, image/jpeg, image/jpg" />
                        <Button variant="miniBrand" rightIcon={<IoAddCircleSharp fontSize={'20px'} />} onClick={handleClick}>Adicionar Imagem</Button>
                        <Text color={'gray.500'} fontSize='13px' fontWeight='700' mt={'5px'} lineHeight={'100%'} align={'center'}>{'Max 5 MB'}</Text>
                    </Box>
                </Flex>
                {listUrlImages.length > 0 ?
                    <Flex pl={'0px'} w={'100%'} justify='start' align='start' bg={'blackAlpha.200'} borderRadius={'10px'}>
                        <Box textAlign="center">
                            <Wrap p={'5px'} spacing='15px' justify={'start'} align={'center'}>
                                {listUrlImages.map((image, index) => (
                                    <WrapItem
                                        key={index}
                                        boxSize="150px"
                                        flex="0 0 auto"
                                        borderRadius={'10px'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        borderWidth={currentIndex === index ? "0px" : "0px"}
                                        borderColor={'red'}>
                                        <Box position={'relative'}>
                                            <Box mt={'20px'} cursor={'pointer'} onClick={() => handleImageClickCarrossel(index)}>
                                                <Image
                                                    src={image.urlStorage}
                                                    alt={`Thumbnail ${index + 1}`}
                                                    boxSize={'100px'}
                                                    objectFit="cover"
                                                    borderRadius={'8px'}
                                                />
                                            </Box>
                                            <Box mt={'10px'}>
                                                <IconButton
                                                    isRound={true}
                                                    aria-label="Previous Image"
                                                    icon={<DeleteIcon />}
                                                    color={'black'}
                                                    fontSize={'15px'}
                                                    size='xs'
                                                    borderWidth={'1px'}
                                                    borderColor={'black'}
                                                    onClick={() => { organizarExclusaoDeArquivo(image) }}
                                                />
                                            </Box>
                                        </Box>
                                    </WrapItem>
                                ))}
                            </Wrap>
                            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                                <ModalOverlay />
                                <ModalContent maxW="600px" position="relative" bg={'whiteAlpha.800'}>
                                    <ModalCloseButton borderWidth={'1px'} borderColor={'blackAlpha.700'} bg={'whiteAlpha.700'} />
                                    <IconButton
                                        aria-label="Previous Image"
                                        icon={<ChevronLeftIcon />}
                                        position="absolute"
                                        left="0"
                                        top="50%"
                                        transform="translateY(-50%)"
                                        onClick={prevImage}
                                        zIndex={1}
                                    />
                                    <ModalBody p={0}>
                                        <Box px={'10px'} pb={'10px'}>
                                            <Text my={'10px'} color={'black'} fontSize={'18px'} fontWeight={'bold'} align={'center'}>{'Imagem'}</Text>
                                            <Flex flexDirection={'column'} justify={'center'} align={'center'} bg={'gray.100'} borderWidth={'1px'} borderColor={'blackAlpha.400'} borderRadius={'10px'}>
                                                <Box px={'10px'} py={'10px'} maxH={'500px'}>
                                                    <Image
                                                        src={listUrlImages[currentIndex].urlStorage}
                                                        alt={`Image ${currentIndex + 1}`}
                                                        w="100%"
                                                        h={'400px'}
                                                        objectFit="contain"
                                                    />
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </ModalBody>
                                    <IconButton
                                        aria-label="Next Image"
                                        icon={<ChevronRightIcon />}
                                        position="absolute"
                                        right="0"
                                        top="50%"
                                        transform="translateY(-50%)"
                                        onClick={nextImage}
                                        zIndex={1}
                                    />
                                </ModalContent>
                            </Modal>
                        </Box>
                    </Flex>
                    :
                    <Flex pl={'10px'} minH={'100px'} w={'100%'} overflowX="auto" justify='center' align='center' bg={'blackAlpha.200'} borderRadius={'10px'}>
                        <Text color={'gray.400'} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{'Sem Imagens'}</Text>
                    </Flex>
                }
            </Box>
        )
    }


    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'30px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                <Button variant="brand" onClick={salvarRegra}>Salvar</Button>
            </Wrap>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px='20px' py='10px'>
                {isVisibleAlert && renderAlert()}
                <Text color={textColor} fontSize={'20px'} fontWeight={'bold'} align={'center'}>Editar ou inserir Regras</Text>
                <Flex justify='start' align='center'>
                    <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} align={'center'}>{'Tela/modulo:'}</Text>
                    <Text color={'orange.600'} fontSize={'15px'} fontWeight={'bold'} align={'center'} ml={'15px'}>{moduloSistema}</Text>
                </Flex>
                <Flex justify='start' align='center'>
                    <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} align={'center'}>{'Ultima Atualização:'}</Text>
                    <Text color={'orange.600'} fontSize={'14px'} fontWeight={'bold'} align={'center'} ml={'15px'}>{dtUltimaAtualizacao}</Text>
                </Flex>
                <Box w={'100%'} mt={'0px'}>
                    {remderRegras()}
                    {remderObservacao()}
                    {/* {renderUploadFile()} */}
                </Box>
                {renderButton()}
            </Card>
            {showImageModal && <ImageModal isOpen={showImageModal} onPressOk={() => { salvarImagens() }} onPressCancel={() => { closeModalImagem() }} image={fileImageModal} />}
            {openModalDialog && <ModalDialog isOpen={openModalDialog} onPressOk={() => { inserirFlagDeletado() }} onPressCancel={() => { setOpenModalDialog(false) }} titulo={'Excluir'} descricao={'Tem certeza que deseja excluir?'} />}

        </Box>
    );
}
