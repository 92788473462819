import React, { useEffect, useState, useContext } from "react";
import {
    Text,
    useColorModeValue,
    Box,
    Flex,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import { UtilContext } from 'contexts/UtilContext';
import moment from 'moment';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';

const IMG = `${ActionTypes.LINK_GOOGLE_STORAGE}/book_anotation.png`;

export default function Notifications(props) {

    const history = useHistory();
    const { isAtualisarNotificacao } = useContext(UtilContext);
    const [arrayNotificacoes2, setArrayNotificacoes2] = useState([]);

    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const colorIcon = useColorModeValue("blue.800", "white");
    const bg = useColorModeValue("white", "navy.700");

    useEffect(() => {
        async function loaderScreen() {

            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');
            const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
            const fim = localStorage.getItem('@HE-dtFimSafraAsync');

            const url = `notificacao/listNotificacao?idUa=${ua}&idSafra=${idSafra}&dtInicio=${ini}&dtFim=${fim}&idTipoCalendario=${ActionTypes.ID_TIPO_CALENDARIO_ESTIMULACAO}`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                const list = res.listNotificacaoPessoa;

                if (list.length > 0)
                    await validarNotificacoes2(list);
            }
        }
        loaderScreen();
    }, []);

    async function validarNotificacoes2(arr) {
        let arrPessoa = [];

        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];

            const objRes = await validarNotificacoes(item);
            const obj = {
                nome: item.nome,
                arrayPrevistos: objRes.arryPrev,
                arrayAtrasados: objRes.arryAtras,
            }
            arrPessoa.push(obj);
        }
        setArrayNotificacoes2(arrPessoa);
    }

    const validarNotificacoes = async (arr) => {
        let result = null;
        try {
            let arryPrev = [];
            let arryAtras = [];

            if (arr.listNotificaocaoItem.length > 0) {

                await new Promise(resolve => {
                    resolve(
                        arr.listNotificaocaoItem.map(item => {

                            const anoInicio = moment(item.dtSelecionada);
                            const end = moment();
                            const duration = moment.duration(end.diff(anoInicio));
                            const dias = duration.asDays();
                            const ss = parseFloat(dias.toFixed(0));

                            if (ss < 0) {
                                arryPrev.push(item);
                            } else {
                                arryAtras.push(item);
                            }
                        })
                    )
                    const xxx = {
                        arryPrev,
                        arryAtras
                    }
                    result = xxx;
                })
            } else {
                const xxx = {
                    arryPrev,
                    arryAtras
                }
                result = xxx;
            }
        } catch (error) {
            console.log('Erro na classe Notifications metodo validarNotificacoes', error);
        }
        return result;
    }

    const cleanNotification = () => {
        isAtualisarNotificacao(false);
        history.push("/admin/estimulacao");
    }

    return (
        <Card mb={{ base: "0px", "2xl": "20px" }}>
            <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='10px'>Notificações</Text>
            <Card bg={bg} p='14px'>
                {arrayNotificacoes2.length > 0 ?
                    <Flex align='center' justifyContent={'space-between'}>
                        <Box>
                            <Flex align='center' justifyContent={'flex-start'}>
                                <Text color={textColorPrimary} fontSize='18px' fontWeight='semibold'>{'Calendário Estimulação'}</Text>
                            </Flex>
                        </Box>
                        <Box mt={{ base: "10px", md: "0" }} >
                            <Button variant="miniBrand" onClick={cleanNotification}>Acessar</Button>
                        </Box>
                    </Flex>
                    :
                    <Flex align='center' justifyContent={'space-between'}>
                        <Box>
                            <Flex align='center' justifyContent={'flex-start'}>
                                <Text color={textColorPrimary} fontSize='16px' fontWeight='semibold'>{'Não há notificações'}</Text>
                            </Flex>
                        </Box>
                    </Flex>
                }
                {arrayNotificacoes2.map((item, index) => {
                    return (
                        <Box key={index} mb={'10px'}>
                            <Flex align='center'>
                                <Box mt={'2'} w={'100%'} pb={'5px'} alignItems={'center'} justifyContent={'flex-start'} borderWidth={'1px'} borderRadius={'10px'} borderColor={'blackAlpha.900'}>
                                    <Flex align='center' justifyContent={'flex-start'} bg={'blackAlpha.300'} borderTopLeftRadius={'10px'} borderTopRightRadius={'10px'}>
                                        <Text color={textColorPrimary} fontWeight='100' fontSize='15px' ml={'10px'}>Seringueiro:</Text>
                                        <Text color={textColorPrimary} fontWeight='bold' fontSize='16px' ml={'10px'}>{item.nome}</Text>
                                    </Flex>
                                    <Box mx={'3'} pb={'3'} justifyContent={'space-around'} >
                                        {item.arrayAtrasados.length > 0 &&
                                            <Box mt={'4'}>
                                                <Box mb={'0'} bg={'white'} borderWidth={'0px'} borderColor={'red'} borderRadius={'10px'}>
                                                    <Text ml={'5px'} color={textColorPrimary} fontWeight='bold' fontSize='15px' bg={'yellow.100'} borderTopLeftRadius={'10px'} borderTopRightRadius={'10px'}>Lançamentos Atrasados</Text>
                                                    <Box ml={'4'} py={'1'}>
                                                        {item.arrayAtrasados.map((r, y) => {
                                                            return (
                                                                <Box key={y}>
                                                                    <Flex align='center' justifyContent={'flex-start'}>
                                                                        <Text color={textColorPrimary} fontWeight='100' fontSize='15px'>Letra:</Text>
                                                                        <Text color={textColorPrimary} fontWeight='semibold' fontSize='15px' ml={'5px'}>{r.letra}</Text>
                                                                        <Text color={textColorPrimary} fontWeight='100' fontSize='15px' ml={'10px'}>Num Semana:</Text>
                                                                        <Text color={textColorPrimary} fontWeight='700' fontSize='15px' ml={'5px'}>{r.numeroSemana}</Text>
                                                                        <Text color={textColorPrimary} fontWeight='100' fontSize='15px' ml={'10px'}>Data:</Text>
                                                                        <Text color={textColorPrimary} fontWeight='700' fontSize='15px' ml={'5px'}>{r.dtSelecionada}</Text>
                                                                    </Flex>
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                        {item.arrayPrevistos.length > 0 &&
                                            <Box mt={'4'}>
                                                <Box mb={'0'} bg={'white'} borderWidth={'0px'} borderColor={'red'} borderRadius={'10px'}>
                                                    <Text ml={'5px'} color={textColorPrimary} fontWeight='bold' fontSize='15px' bg={'yellow.100'} borderTopLeftRadius={'10px'} borderTopRightRadius={'10px'}>Lançamentos para próxima semana</Text>
                                                    <Box ml={'4'} py={'1'}>
                                                        {item.arrayPrevistos.map((r, y) => {
                                                            return (
                                                                <Box key={y}>
                                                                    <Flex align='center' justifyContent={'flex-start'}>
                                                                        <Text color={textColorPrimary} fontWeight='100' fontSize='15px'>Letra:</Text>
                                                                        <Text color={textColorPrimary} fontWeight='700' fontSize='15px' ml={'5px'}>{r.letra}</Text>
                                                                        <Text color={textColorPrimary} fontWeight='100' fontSize='15px' ml={'10px'}>Num Semana:</Text>
                                                                        <Text color={textColorPrimary} fontWeight='700' fontSize='15px' ml={'5px'}>{r.numeroSemana}</Text>
                                                                        <Text color={textColorPrimary} fontWeight='100' fontSize='15px' ml={'10px'}>Data:</Text>
                                                                        <Text color={textColorPrimary} fontWeight='700' fontSize='15px' ml={'5px'}>{r.dtSelecionada}</Text>
                                                                    </Flex>
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    )
                })}
            </Card>
        </Card>
    );
}
