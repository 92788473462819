import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    useColorModeValue,
    Button,
    Box,
    Wrap,
    Select,
    Input,
    Checkbox,
    SimpleGrid,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import { STATUS_PERIODO_ESTIMULACAO } from "model/MockData";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import moment from 'moment';
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
import ModalDialog from 'components/modal/ModalDialog';
import CardEstimulacao from 'components/card/CardEstimulacao';

export default function LancarEstimulacao() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');

    const [screenFullLoad, setScreenFullLoad] = useState(false);
    const [idUa, setIdUa] = useState('');
    const [idSafra, setIdSafra] = useState('');
    const [dataInput, setDataInput] = useState('');
    const [statuaPeriodoP, setStatuaPeriodoP] = useState('');
    const [idPessoaP, setidPessoaP] = useState('');
    const [numeroSemanaSelecionado, setNumeroSemanaSelecionado] = useState(0);
    const [letra, setLetra] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [minDate, setMinDate] = useState('');
    const [acao, setAcao] = useState('');
    const [idEstimulacaoP, setIdEstimulacaoP] = useState(null);
    const [isValidForm, setIsValidForm] = useState(false);
    const [anoEstimulacao, setAnoEstimulacao] = useState(null);
    const [dtInicioSafra, setDtInicioSafra] = useState('');
    const [dtFimSafra, setDtFimSafra] = useState('');
    const [isButtonExcluir, setIsButtonExcluir] = useState(true);
    const [listAtividadesGeral, setListAtividadesGeral] = useState([]);
    const [qtdArvoes, setQtdArvoes] = useState('');
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [checkFungicida, setCheckFungicida] = useState(false);
    const [checkAdubacao, setCheckAdubacao] = useState(false);
    const [inputVolumeSolucao, setInputVolumeSolucao] = useState('');
    const [inputConcentracao, setInputConcentracao] = useState('');
    const [idTarefa, setIdTarefa] = useState('');
    const [frequencia, setFrequencia] = useState('');
    const [nomeSeringueiro, setNomeSeringueiro] = useState('');
    const [producaoEsperada, setProducaoEsperada] = useState('');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const inputText = useColorModeValue("gray.700", "gray.100");

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            const est1 = localStorage.getItem('@HE-DadosTela-formEstimulacao');
            if (est1 != null) {
                const dadosEstimulacao = JSON.parse(est1);

                const idEstimula = dadosEstimulacao.idEstimulacao;
                const statusPerio = dadosEstimulacao.statusPeriodo;
                const dtFimSema = dadosEstimulacao.dtFimSemana;
                const dtInicioSemaX = dadosEstimulacao.dtInicioSemana;
                const numeroSema = dadosEstimulacao.numeroSemana;
                const letraPar = dadosEstimulacao.letraParam;
                const idPess = dadosEstimulacao.idPessoa;
                const idTarefaP = dadosEstimulacao.idTarefa;

                const fungicidaP = dadosEstimulacao.fungicida != null ? dadosEstimulacao.fungicida : false;
                const adubacaoP = dadosEstimulacao.adubacao != null ? dadosEstimulacao.adubacao : false;
                const volumeSolucaoP = dadosEstimulacao.volumeSolucao != null ? dadosEstimulacao.volumeSolucao : '';
                const concetracaoP = dadosEstimulacao.concetracao != null ? dadosEstimulacao.concetracao : '';

                setCheckFungicida(fungicidaP);
                setCheckAdubacao(adubacaoP);
                setInputVolumeSolucao(volumeSolucaoP);
                setInputConcentracao(concetracaoP);

                localStorage.setItem('@HE-id-pessoa-retorno', `${idPess}`);      // ESSE CARA E IMPORTANTE PARA O RETORNO DESSA TELA, E SERA REMOVIDO SEMPRE NO RETORNO

                setIdUa(dadosEstimulacao.idUa);
                setIdSafra(dadosEstimulacao.idSafra);
                setidPessoaP(idPess);
                setLetra(letraPar);
                setNumeroSemanaSelecionado(numeroSema);
                setStatuaPeriodoP(statusPerio);
                setIdEstimulacaoP(idEstimula);
                setFrequencia(dadosEstimulacao.frequencia);
                setNomeSeringueiro(dadosEstimulacao.nomeSeringueiro);
                setListAtividadesGeral(dadosEstimulacao.listAtividadesGeral);
                setProducaoEsperada(dadosEstimulacao.producaoEsperada);
                setQtdArvoes(dadosEstimulacao.qtdArvoes);
                setIdTarefa(idTarefaP);

                const dtInt = localStorage.getItem('@HE-dtInicioSafraAsync');
                const dtFim = localStorage.getItem('@HE-dtFimSafraAsync');
                setDtInicioSafra(dtInt);
                setDtFimSafra(dtFim);

                const ano = moment(dtFimSema).format('YYYY');
                setAnoEstimulacao(ano);

                const max = dtFimSema;
                const min = dtInicioSemaX;
                setMaxDate(max);
                setMinDate(min);

                const dtParam = dadosEstimulacao.dtParam;

                if (idEstimula != 0) {
                    const dt = dtParam != '' && dtParam != null ? moment(dtParam).format('YYYY-MM-DD') : '';
                    const status = statusPerio != '' && statusPerio != null ? statusPerio : '1';
                    const id = idEstimula;

                    setDataInput(dt);
                    setStatuaPeriodoP(status);
                    setIdEstimulacaoP(id);

                    setIsButtonExcluir(false);
                    setAcao('update');
                } else {
                    setDataInput(min);
                    setStatuaPeriodoP('1');
                    setIsButtonExcluir(true);
                    setAcao('insert');
                }
                setScreenFullLoad(true);
            }
        }
        loaderScreen();
    }, [])

    const excluirEstimulacao = async () => {
        try {
            setOpenModalDialog(false);
            setIsValidForm(false);

            if (statuaPeriodoP == '' || dataInput == '') {
                setIsValidForm(true);
                return;
            }
            const json = {
                idEstimulacao: idEstimulacaoP,
                idUa: idUa,
                idSafra: idSafra,
                idPessoa: idPessoaP,
                numeroSemana: numeroSemanaSelecionado,
                ano: anoEstimulacao,
                statusPeriodo: statuaPeriodoP,
                dtSelecionada: dataInput,
                letra: letra,
                dtInicio: dtInicioSafra,
                dtFim: dtFimSafra,
                idTipoCalendario: ActionTypes.ID_TIPO_CALENDARIO_ESTIMULACAO,
            }

            setLoading(true);
            const response = await Api.postRequest(`estimulacao/deleteEstimulacao`, json);
            setLoading(false);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                history.goBack();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            setLoading(false);
            console.log('Erro na classe LancarEstimulacao metodo salvarEstimulacao', error);
        }
    }

    const salvarEstimulacao = async () => {
        try {
            setIsValidForm(false);

            if (statuaPeriodoP == '' || dataInput == '') {
                setIsValidForm(true);
                return;
            }
            const json = {
                idEstimulacao: idEstimulacaoP,
                idUa: idUa,
                idSafra: idSafra,
                idPessoa: idPessoaP,
                numeroSemana: numeroSemanaSelecionado,
                ano: anoEstimulacao,
                statusPeriodo: statuaPeriodoP,
                dtSelecionada: dataInput,
                letra: letra,
                dtInicio: dtInicioSafra,
                dtFim: dtFimSafra,
                idTipoCalendario: ActionTypes.ID_TIPO_CALENDARIO_ESTIMULACAO,
                idTarefa: idTarefa,
                fungicida: checkFungicida,
                adubacao: checkAdubacao,
                volumeSolucao: inputVolumeSolucao,
                concetracao: inputConcentracao,
            }

            let response = null;
            setLoading(true);

            if (acao == 'insert') {
                response = await Api.postRequest(`estimulacao/insertEstimulacao`, json);
            } else {
                response = await Api.putRequest(`estimulacao/updatetEstimulacao`, json);
            }

            setLoading(false);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                history.goBack();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            setLoading(false);
            console.log('Erro na classe LancarEstimulacao metodo salvarEstimulacao', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    function renderLancarEstimulacao() {
        return (
            <Box px={'30px'} my={'20px'}>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap='20px' mb='2px' >
                    <Flex flexDirection={'column'} justify={'center'} align={'center'}>
                        <Box>
                            {isValidForm && <Text color={'red.500'} fontSize='14px' fontWeight='700' align={'center'}>Selecione pelo menos um item</Text>}
                            <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Status</Text>
                            <Select
                                value={statuaPeriodoP}
                                errorBorderColor='crimson'
                                placeholder=""
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize='13px'
                                fontWeight={'bold'}
                                onChange={(e) => setStatuaPeriodoP(e.target.value)}
                                w={'400px'}
                                h={'35px'}>
                                {STATUS_PERIODO_ESTIMULACAO.map((it, idx) => {
                                    return (
                                        <option key={idx} style={{ background: 'white', color: it.bgColor }} value={it.value} >{it.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex flexDirection={'column'} justify={'center'} align={'center'}>
                        <Box>
                            <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Data</Text>
                            <Input
                                value={dataInput}
                                errorBorderColor='crimson'
                                placeholder=""
                                variant='filled'
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize='13px'
                                fontWeight={'bold'}
                                onChange={(e) => setDataInput(e.target.value)}
                                w={'400px'}
                                h={'35px'}
                                bg={'white'}
                                color={inputText}
                                type="date"
                                min={minDate}
                                max={maxDate}
                            />
                        </Box>
                    </Flex>
                    <Flex flexDirection={'column'} justify={'center'} align={'center'}>
                        <Box>
                            <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Volume da Solução</Text>
                            <Input
                                value={inputVolumeSolucao}
                                errorBorderColor='crimson'
                                placeholder=""
                                variant='filled'
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize='13px'
                                fontWeight={'bold'}
                                onChange={(e) => setInputVolumeSolucao(e.target.value)}
                                w={'400px'}
                                h={'35px'}
                                bg={'white'}
                                color={inputText}
                            />
                        </Box>
                    </Flex>
                    <Flex flexDirection={'column'} justify={'center'} align={'center'}>
                        <Box>
                            <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Concentração</Text>
                            <Input
                                value={inputConcentracao}
                                errorBorderColor='crimson'
                                placeholder=""
                                variant='filled'
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize='13px'
                                fontWeight={'bold'}
                                onChange={(e) => setInputConcentracao(e.target.value)}
                                w={'400px'}
                                h={'35px'}
                                bg={'white'}
                                color={inputText}
                            />
                        </Box>
                    </Flex>
                    <Flex flexDirection={'column'} justify={'center'} align={'center'}>
                        <Flex w={'400px'} justify={'space-between'} align={'center'}>
                            <Flex justify={'start'} align={'center'}>
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={checkFungicida}
                                    onChange={(e) => { setCheckFungicida(!checkFungicida) }}
                                    mb={'5px'}
                                    borderWidth={'1px'}
                                    borderColor={'blackAlpha.700'} />
                                <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Com fungicida</Text>
                            </Flex>
                            <Flex justify={'start'} align={'center'}>
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={checkAdubacao}
                                    onChange={(e) => { setCheckAdubacao(!checkAdubacao) }}
                                    mb={'5px'}
                                    borderWidth={'1px'}
                                    borderColor={'blackAlpha.700'} />
                                <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Com Adubação</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </SimpleGrid>
            </Box>
        )
    }

    function renderBooton() {
        return (
            <Box px={'30px'} my={'20px'}>
                <Wrap spacing='3px' w={'100%'} mx={'3px'} my={'30px'} justify='space-between' >
                    <Box>
                        <Button variant="brand" onClick={() => { history.goBack() }} >Voltar</Button>
                    </Box>
                    <Box>
                        <Button variant="redBrand" onClick={() => { setOpenModalDialog(true) }} disabled={isButtonExcluir} >Excluir</Button>
                        <Button variant="brand" ml={3} onClick={salvarEstimulacao}>Salvar</Button>
                    </Box>
                </Wrap>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!screenFullLoad) {
        return (
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Carregando...</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' px='0px' py='10px' overflowX={{ sm: "scroll", lg: "hidden" }}>
                {renderAlert()}
                {listAtividadesGeral.length > 0 && <CardEstimulacao listAtividadesGeral={listAtividadesGeral} qtdArvoes={qtdArvoes} tipoCalendario={1} />}
                {renderLancarEstimulacao()}
                {renderBooton()}
                <Loader isOpen={loading} />
            </Card>
            {openModalDialog && <ModalDialog isOpen={openModalDialog} titulo={'Excluir'} onPressOk={() => { excluirEstimulacao() }} onPressCancel={() => { setOpenModalDialog(false) }} descricao={'Deseja Realmente excluir?'} />}
        </Box>
    );
};


