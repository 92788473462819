import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    useColorModeValue,
    Button,
    Box,
    Select,
    SimpleGrid,
    Table,
    Tbody,
    Thead,
    Tr,
    Th,
    Td,
    Wrap,
    WrapItem,
    RadioGroup,
    Radio,
    Switch,
    Input,
    Icon,
    Center,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
import moment from 'moment';
import { QTD_PAGE } from 'model/MockData';
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import ModalDialog from 'components/modal/ModalDialog';
import ModalDialogDesativarSeringueiro from 'components/modal/ModalDialogDesativarSeringueiro';
import { GiFarmer } from "react-icons/gi";
import TooltipIcon from 'components/tooltip/TooltipIcon';

export default function ReativarSeringueiro() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listUa, setListUa] = useState([]);
    const [listUaCompleto, setListUaCompleto] = useState([]);
    const [uaSelecionado, setUaSelecionado] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [safraSelecionado, setSafraSelecionado] = useState('');
    const [listaSafraCombo, setListaSafraCombo] = useState([]);
    const [listaSeringueiros, setListaSeringueiros] = useState([]);
    const [radioPermitirProgramacao, setRadioPermitirProgramacao] = useState('3');
    const [radioSangriaAtiva, setRadioSangriaAtiva] = useState('3');
    const [radioSeringueiroDesativado, setRadioSeringueiroDesativado] = useState('3');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [messageDialog, setMessageDialog] = useState('');
    const [selecionadaParaExclusao, setSelecionadaParaExclusao] = useState(null);
    const [openModalDesativarSeringueiro, setOpenModalDesativarSeringueiro] = useState(false);
    const [valueInputDesativado, setvalueInputDesativado] = useState('');
    const [idSafraPessoaParaExclusao, setIdSafraPessoaParaExclusao] = useState('');
    const [nomeSeringueiro, setNomeSeringueiro] = useState('');
    const [idUsuarioLogado, setIdUsuarioLogado] = useState('');
    const [nomeInput, setNomeInput] = useState('');
    const [isValidNomePessoa, setIsValidNomePessoa] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const cardShadow = useColorModeValue('0px 0px 5px rgba(0, 0, 0, 0.5)', 'unset');
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradienGray = 'linear-gradient(gray.300, gray.200, white)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            setvalueInputDesativado(moment().format('YYYY-MM-DD'));

            const idUsuarioLogadoP = localStorage.getItem('@HE-idUsuarioLogado');
            setIdUsuarioLogado(idUsuarioLogadoP);

            await pesquisarPropriedades();
        }
        loaderScreen();
    }, [])

    const totalPages = Math.ceil(listaSeringueiros.length / itemsPerPage);
    const paginatedData = listaSeringueiros.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const changePage = (e) => {
        const val = e.target.value;
        setItemsPerPage(val);
    }

    const pesquisarPropriedades = async () => {
        setUaSelecionado('');

        const response = await Api.getRequest(`api/ua/retornaUaAndSafras`);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = await response.respostaRequisicao.data;
            setListUaCompleto(res);

            if (res.length > 0) {
                let arrayCombo = null;
                await new Promise(resolve => {
                    resolve(
                        arrayCombo = res.map(function (item) {
                            return {
                                value: `${item.idUa}`,
                                label: item.nomeFazenda
                            }
                        })
                    )
                });
                setListUa(arrayCombo);
            }
            setTelaCarregada(true);
        } else {
            setTelaCarregada(true);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const changePropriedade = async (e) => {
        const idUa = e;

        await limpaCampos();
        setUaSelecionado(idUa);
        setListaSafraCombo([]);

        if (idUa != '') {
            const arrUa = listUaCompleto.filter(x => x.idUa == idUa);
            const listaSafraItem = arrUa[0].listaSafraItem;

            if (listaSafraItem.length > 0) {
                let arrayCombo = null;
                await new Promise(resolve => {
                    resolve(
                        arrayCombo = listaSafraItem.map(function (item) {

                            const bgColor = item.sangriaAtiva == true ? 'white' : '#ffcccc';
                            return {
                                value: `${item.idSafra}`,
                                label: item.nomeSafra,
                                color: bgColor
                            }
                        })
                    )
                });
                setListaSafraCombo(arrayCombo);
            }
        }
    }

    const limpaCampos = async () => {
        setListaSafraCombo([]);

        setUaSelecionado('');
        setSafraSelecionado('');
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const pesquisar = async () => {
        try {
            setIsValidNomePessoa(false);
            const nomePe = nomeInput.trim();

            if (uaSelecionado == '' && nomePe.length < 2 && radioSangriaAtiva == '3' && radioSeringueiroDesativado == '3') {
                setNumStatusResposta(ActionTypes.SELECIONE_PELO_MENUS_UM_CAMPO);
                showAlert();
                if (nomePe.length > 0 && nomePe.length < 2) setIsValidNomePessoa(true);
                return;
            }

            let saAtiva = '';
            if (radioSangriaAtiva == '1') {
                saAtiva = true;
            } else if (radioSangriaAtiva == '2') {
                saAtiva = false;
            }

            let permitPro = '';
            if (radioPermitirProgramacao == '1') {
                permitPro = true;
            } else if (radioPermitirProgramacao == '2') {
                permitPro = false;
            }

            let desativado = '';
            if (radioSeringueiroDesativado == '1') {
                desativado = false;
            } else if (radioSeringueiroDesativado == '2') {
                desativado = true;
            }

            const idUa = uaSelecionado != '' ? uaSelecionado : 0;
            const idSafra = safraSelecionado != '' ? safraSelecionado : 0;

            const url = `safraPessoa/retonaStatusSeringueiroAndSafra?idUa=${idUa}&idSafra=${idSafra}&sangriaAtiva=${saAtiva}&idPessoa=0&permitirRodarProgramacao=${permitPro}&nome=${nomePe}&deletado=${desativado}`;
            setLoading(true);
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setListaSeringueiros(res);

                setLoading(false);
            } else {
                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            console.log('Erro na classe ReativarSeringueiro metodo ', error);
        }
    }

    const ativarProgramacao = async (item) => {

        const isPermitir = item.permitirRodarProgramacao != null ? !item.permitirRodarProgramacao : false;
        const json = {
            idSafra: item.idSafra,
            idPessoa: item.idPessoa,
            desativarSeringueiro: isPermitir,
        }

        const response = await Api.putRequest(`safraPessoa/ativarPermissaoParaProgramacao`, json);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = await response.respostaRequisicao.data;

            await pesquisar();
            setLoading(false);
        } else {
            setLoading(false);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const changeRodarProgramacao = (e) => {
        setRadioPermitirProgramacao(e);
    }
    const changeSangria = (e) => {
        setRadioSangriaAtiva(e);
    }
    const changeSeringueiroDesativado = (e) => {
        setRadioSeringueiroDesativado(e);
    }

    const changeOpenDialogModal = (item) => {
        setSelecionadaParaExclusao(item);

        if (item.deletado == true) {
            const dtDeletado = item.dtDeletado != null ? moment(item.dtDeletado).format('DD/MM/YY') : '';

            setMessageDialog(`Será gerada programação do dia em que foi desativo ${dtDeletado} até a data atual`);
            setOpenModalDialog(true);

        } else if (item.deletado == false) {
            functionOpenModalDialog(item);
        }
    }

    const changeAtivaAndDesativaPessoa = async () => {

        setOpenModalDialog(false);
        setLoading(true);

        const jsonParam = {
            idPessoa: selecionadaParaExclusao.idPessoa,
            idSafra: selecionadaParaExclusao.idSafra,
            desativarSeringueiro: !selecionadaParaExclusao.deletado,
        }

        const response = await Api.putRequest(`safraPessoa/ativarAndDesativarSeringueiroAndAtualizarProgramacao`, jsonParam);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            await pesquisar();
            setLoading(false);
        } else {
            setLoading(false);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const functionOpenModalDialog = async (item) => {
        setNomeSeringueiro(item.nome);
        setIdSafraPessoaParaExclusao(item.idSafraPessoa);
        setOpenModalDesativarSeringueiro(true);
    }

    const confirmDesativar = async (inputValue) => {
        setvalueInputDesativado(inputValue);
        setOpenModalDesativarSeringueiro(false);

        const jsonParam = {
            idSafraPessoa: idSafraPessoaParaExclusao,
            dtDeletado: inputValue,
        }

        const response = await Api.putRequest(`safraPessoa/ativarCampoDeletadoPessoa`, jsonParam);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            await pesquisar();
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    function renderPropriedadeAndSangria() {
        const isDisabledSangria = listaSafraCombo.length > 0 ? false : true;
        const opacityText = isDisabledSangria ? '0.5' : '1';
        return (
            <Box
                px={'20px'}
                py={'10px'}
                borderRadius={'20px'}
                borderWidth={'2px'}
                borderColor={'blackAlpha.900'}
                bg={'white'}
                boxShadow={cardShadow}>
                <Text color={textColor} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Filtros para pesquisa'}</Text>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='10px' mt={'10px'}>
                    <Flex px='5px' mb='2px' flexDirection={'column'} justify='center' align='center'>
                        <Box  >
                            <Text ml={'10px'} color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Propriedades</Text>
                            <Select
                                value={uaSelecionado}
                                errorBorderColor='crimson'
                                placeholder="Selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                onChange={(e) => changePropriedade(e.target.value)}
                                h={'35px'}
                                w={'300px'}
                            >
                                {listUa.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex px='5px' mb='2px' flexDirection={'column'} justify='center' align='center'>
                        <Box>
                            <Text opacity={opacityText} ml={'10px'} color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Safras</Text>
                            <Select
                                value={safraSelecionado}
                                errorBorderColor='crimson'
                                placeholder="Selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                isDisabled={isDisabledSangria}
                                onChange={(e) => setSafraSelecionado(e.target.value)}
                                h={'35px'}
                                w={'300px'}
                            >
                                {listaSafraCombo.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: item.color, fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex px='5px' mb='2px' flexDirection={'column'} justify='center' align='center'>
                        <Box>
                            <Text ml={'10px'} color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Nome Seringueiro</Text>
                            <Input
                                value={nomeInput}
                                onChange={(e) => setNomeInput(e.target.value)}
                                isInvalid={isValidNomePessoa}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'300px'}
                            />
                        </Box>
                    </Flex>
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 3, "2xl": 3 }} gap='10px' mb={'10px'} mt={'20px'}>
                    <Flex justify='center' align='center'>
                        <Box py={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.800'} borderRadius={'10px'} maxW={'400px'} bgGradient={gradienGray}>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Sangria Ativa</Text>
                            <RadioGroup onChange={(e) => { changeSangria(e) }} value={radioSangriaAtiva}>
                                <Wrap spacing='2px' mt={'15px'} px={'15px'} justify={'space-between'} align={'center'}>
                                    <WrapItem>
                                        <Flex w='90px' justify={'start'} align={'center'}>
                                            <Radio value='1' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Sim</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                    <WrapItem>
                                        <Flex w='90px' justify={'start'} align={'center'}>
                                            <Radio value='2' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Não</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                    <WrapItem>
                                        <Flex w='90px' justify={'start'} align={'center'}>
                                            <Radio value='3' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Todos</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                </Wrap>
                            </RadioGroup>
                        </Box>
                    </Flex>
                    <Flex justify='center' align='center'>
                        <Box py={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.800'} borderRadius={'10px'} maxW={'400px'} bgGradient={gradienGray}>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Seringueiro Ativo</Text>
                            <RadioGroup onChange={(e) => { changeSeringueiroDesativado(e) }} value={radioSeringueiroDesativado}>
                                <Wrap spacing='2px' mt={'15px'} px={'15px'} justify={'space-between'} align={'center'}>
                                    <WrapItem>
                                        <Flex w='90px' justify={'start'} align={'center'}>
                                            <Radio value='1' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Sim</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                    <WrapItem>
                                        <Flex w='90px' justify={'start'} align={'center'}>
                                            <Radio value='2' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Não</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                    <WrapItem>
                                        <Flex w='90px' justify={'start'} align={'center'}>
                                            <Radio value='3' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                                <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Todos</Text>
                                            </Radio>
                                        </Flex>
                                    </WrapItem>
                                </Wrap>
                            </RadioGroup>
                        </Box>
                    </Flex>
                    <Flex justify={'center'} align="center" >
                        <Button variant="brand" onClick={() => { pesquisar() }}>Pesquisar</Button>
                    </Flex>
                </SimpleGrid>
            </Box>
        )
    }

    function renderListaSafra() {
        let qtdFim = currentPage * itemsPerPage;
        let qtdIni = (qtdFim - itemsPerPage) + 1;
        const colorTextHeader = 'white';
        const espacoW = 2;
        const corBorder = 'blackAlpha.400';
        let corLinha = false;
        return (
            <Box py={'20px'}>
                <Flex px={'10px'} direction="column" align="start" overflowY="hidden" overflowX={'auto'} maxWidth={'100%'} >
                    <Table variant="striped" colorScheme='blackAlpha' size="sm" >
                        <Thead position="sticky" top="0" zIndex="10">
                            <Tr h={'40px'} bgGradient={gradientHeader}>
                                <Th sx={{ paddingX: espacoW }}>
                                    <Flex justify='start' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Nome Propriedade'}</Text>
                                    </Flex>
                                </Th>
                                <Th sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Nome Safra'}</Text>
                                    </Flex>
                                </Th>
                                <Th sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Nome Seringueiro'}</Text>
                                    </Flex>
                                </Th>
                                <Th sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Seringueiro Desativado'}</Text>
                                    </Flex>
                                </Th>
                                <Th sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Datas Safra'}</Text>
                                    </Flex>
                                </Th>
                                <Th sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Período do seringueiro na safra'}</Text>
                                    </Flex>
                                </Th>
                                {idUsuarioLogado == 15 &&
                                    <Th w={'100px'} >
                                        <Flex justify='center' align='center' textTransform={'none'}>
                                            <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'IDs'}</Text>
                                            <Center h={'100%'} justifyContent={'center'} alignItems={'end'} ml={'5px'}>
                                                <TooltipIcon
                                                    label={
                                                        <Box py={'10px'} px={'5px'} pb={'10px'}>
                                                            <Text align={'center'} fontSize={'16px'} lineHeight={'100%'}>{'Sangria'}</Text>
                                                            <Text align={'start'} mt={'10px'} color={'green'} lineHeight={'100%'}>{'Verde'}</Text>
                                                            <Text align={'start'} mt={'5px'} lineHeight={'100%'}>{'Sangria está ativa'}</Text>
                                                            <Text align={'start'} mt={'10px'} color={'red.400'} lineHeight={'100%'}>{'Vermelho'}</Text>
                                                            <Text align={'start'} mt={'5px'} lineHeight={'100%'}>{'Sangria está finalizada'}</Text>

                                                            <Text align={'center'} fontSize={'16px'} mt={'30px'} lineHeight={'100%'}>{'Status do seringueiro'}</Text>
                                                            <Text align={'start'} mt={'5px'} color={'yellow'} lineHeight={'100%'}>{'Amarelo'}</Text>
                                                            <Text align={'start'} mt={'5px'} lineHeight={'100%'}>{'O campo permitir-rodar-programação esta false, e não esta rodando a programação'}</Text>
                                                            <Text align={'start'} mt={'10px'} color={'red.400'} lineHeight={'100%'}>{'Vermelho'}</Text>
                                                            <Text align={'start'} mt={'5px'} lineHeight={'100%'}>{'O seringueiro desativado, mas o permitir-rodar-programação ainda está true'}</Text>
                                                        </Box>
                                                    }
                                                    height={'16px'} color={'white'} />
                                            </Center>
                                        </Flex>
                                    </Th>
                                }
                                {idUsuarioLogado == 15 &&
                                    <Th sx={{ paddingX: espacoW }}>
                                        <Flex justify='center' align='center' textTransform={'none'}>
                                            <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Rodar Programação'}</Text>
                                        </Flex>
                                    </Th>
                                }
                            </Tr>
                        </Thead>
                        <Tbody>
                            {paginatedData.map((item, index) => {
                                const existeProgramacao = item.isExisteProgramacao == true ? true : false;
                                const isPermitirProgramacao = item.permitirRodarProgramacao == true ? true : false;
                                const sangriaAtiva = item.sangriaAtiva == true ? true : false;
                                const isDeletado = item.deletado == true ? true : false;
                                const dtInicioSafra = item.dtInicio != null ? moment(item.dtInicio).format('DD/MM/YY') : '';
                                const dtFimSafra = item.dtFim != null ? moment(item.dtFim).format('DD/MM/YY') : '';
                                const dtUltimaProgramacao = item.dtUltimaProgramacao != null ? moment(item.dtUltimaProgramacao).format('DD/MM/YY') : '';
                                const dtPrimeiraPro = item.dtPrimeiraProgramacao != null ? moment(item.dtPrimeiraProgramacao).format('DD/MM/YY') : '';
                                corLinha = !corLinha;
                                let colorLinha = corLinha ? 'blackAlpha.100' : 'white';
                                let colorIcon = 'black';
                                let colorSangria = sangriaAtiva ? 'green' : 'red';
                                if (existeProgramacao && !isPermitirProgramacao && sangriaAtiva && !isDeletado) {
                                    colorIcon = 'yellow';
                                }
                                if (isDeletado && isPermitirProgramacao) {
                                    colorIcon = 'red';
                                }
                                return (
                                    <Tr key={index} bg={colorLinha} whiteSpace={'nowrap'}>
                                        <Td pl={'10px'} sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex pr={'10px'} justify='start' align='center'>
                                                <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`${item.nomeFazenda}`}</Text>
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex justify='center' align='center'>
                                                <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`${item.nomeSafra}`}</Text>
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex justify='start' align='center'>
                                                <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`${item.nome}`}</Text>
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex justify='center' align='center'>
                                                <Switch
                                                    isChecked={!isDeletado}
                                                    onChange={() => { changeOpenDialogModal(item) }}
                                                    colorScheme="green"
                                                    sx={{ ".chakra-switch__track": { backgroundColor: !isDeletado ? undefined : "red" } }}
                                                />
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex flexDirection={'column'} justify='center' align='center'>
                                                <Flex justify='start' align='center'>
                                                    <Box w={'40px'}>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Início:`}</Text>
                                                    </Box>
                                                    <Box w={'60px'}>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'5px'}>{dtInicioSafra}</Text>
                                                    </Box>
                                                </Flex>
                                                <Flex mt={'5px'} justify='center' align='center'>
                                                    <Box w={'40px'}>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Fim:`}</Text>
                                                    </Box>
                                                    <Box w={'60px'}>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'5px'}>{dtFimSafra}</Text>
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        </Td>
                                        <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                            <Flex flexDirection={'column'} justify='space-between' align='center'>
                                                <Flex justify='start' align='center'>
                                                    <Box w={'80px'}>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Iníciou em:`}</Text>
                                                    </Box>
                                                    <Box w={'60px'}>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'5px'}>{dtPrimeiraPro}</Text>
                                                    </Box>
                                                </Flex>
                                                <Flex mt={'5px'} justify='center' align='center'>
                                                    <Box w={'80px'}>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Finalizou:`}</Text>
                                                    </Box>
                                                    <Box w={'60px'}>
                                                        {dtUltimaProgramacao != '' ?
                                                            <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'5px'}>{dtUltimaProgramacao}</Text>
                                                            :
                                                            <Text color={'green'} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%' ml={'5px'}>{'Ativo'}</Text>
                                                        }
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        </Td>
                                        {idUsuarioLogado == 15 &&
                                            <Td w={'100px'} borderLeftWidth={'1px'} borderColor={corBorder}>
                                                <Flex justify='start' align='center'>
                                                    <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%'>{`idSafra ${item.idSafra}`}</Text>
                                                    <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{`idSerin ${item.idPessoa}`}</Text>
                                                    <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{`idUa ${item.idUa}`}</Text>
                                                </Flex>
                                                <Flex w={'100%'} justify='space-between' align='center' mt={'5px'}>
                                                    <Flex justify='start' align='center'>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%'>{`Sangria`}</Text>
                                                        <Box w={'14px'} h={'14px'} borderRadius={'7px'} bg={colorSangria} ml={'10px'} />
                                                    </Flex>
                                                    <Flex w={'100%'} justify='center' align='center'>
                                                        <Icon as={GiFarmer} width={'20px'} height={'20px'} color={colorIcon} />
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                        }
                                        {idUsuarioLogado == 15 &&
                                            <Td sx={{ paddingX: espacoW }} borderLeftWidth={'1px'} borderColor={corBorder}>
                                                <Flex flexDirection={'column'} justify='center' align='center'>
                                                    <Switch
                                                        isChecked={isPermitirProgramacao}
                                                        onChange={() => { ativarProgramacao(item) }}
                                                        colorScheme="green"
                                                        sx={{ ".chakra-switch__track": { backgroundColor: isPermitirProgramacao ? undefined : "red" } }}
                                                    />
                                                </Flex>
                                            </Td>
                                        }
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                    <Flex px={'10px'} w={'100%'} mt={5} justify='space-between' align='center'>
                        <Flex justify='center' align='center'>
                            <Text color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>Qtd Linhas</Text>
                            <Box ml={'10px'}>
                                <Select
                                    value={itemsPerPage}
                                    placeholder=""
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={'gray.200'}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    textColor={textColor}
                                    size="sm"
                                    h={'35px'}
                                    onChange={changePage}>
                                    {QTD_PAGE.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                        </Flex>
                        <Flex justify='center' align='center'>
                            <Text mr={'20px'} color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>{`${qtdIni}-${qtdFim} de ${listaSeringueiros.length}`}</Text>
                            <Button variant="outline" leftIcon={<RiArrowLeftSLine />} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            <Button variant="outline" rightIcon={<RiArrowRightSLine />} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} ml={2} />
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Flex px='25px' justify='start' mt={'20px'} align='center' >
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Flex>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "80px", md: "60px", xl: "40px" }}>
            <Card px='0px' py='0px' bg={'transparent'}>
                <Box>
                    {isVisibleAlert && renderAlert()}
                    {renderPropriedadeAndSangria()}
                    {renderListaSafra()}
                    {renderButton()}
                </Box>
                {loading && <Loader isOpen={loading} />}
                {openModalDialog &&
                    <ModalDialog
                        isOpen={openModalDialog}
                        onPressOk={() => { changeAtivaAndDesativaPessoa() }}
                        onPressCancel={() => { setOpenModalDialog(false) }}
                        descricao={messageDialog}
                        colorTitle={textColor}
                        descricao2={'Deseja continuar?'}
                        titulo={'Reativar Seringueiro'}
                    />
                }
                {openModalDesativarSeringueiro && (
                    <ModalDialogDesativarSeringueiro
                        isOpen={openModalDesativarSeringueiro}
                        onPressCancel={() => { setOpenModalDesativarSeringueiro(false) }}
                        onConfirm={confirmDesativar}
                        titulo={'Desativar'}
                        valueInput={valueInputDesativado}
                        nomeSeringueiro={nomeSeringueiro}
                    />
                )}
            </Card>
        </Box>
    );
};
