import React from "react";
import {
    Text,
    useColorModeValue,
    Box,
    TableContainer,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";

function CardEstimulacao({ listAtividadesGeral = [], qtdArvoes, tipoCalendario = 1 }) {

    const textColor = useColorModeValue("black", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientGray = 'linear-gradient(gray.700, gray.600, gray.500)';

    function renderTabela() {
        const sizeF = '13px';
        const largTr = 0;
        const altura = 0;
        return (
            <Box>
                <TableContainer borderTopRadius={'8px'} borderBottomRadius={'8px'}>
                    <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                        <Thead>
                            <Tr h={'25px'} bgGradient={tipoCalendario == 1 ? gradientHeader : gradientGray}>
                                <Th w={'10%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'} >
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Tarefa/Lote'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Árvores em Sangria'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Clone'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Painel'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Idade Sangria'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Realizações'}</Text>
                                    </Box>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {listAtividadesGeral.map((item, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.letraCustomizada}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.tamanhoLote}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.nomeClone}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.nomePainel}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.idadeSangria}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.qtdRealizadas}</Text>
                                        </Td>
                                    </Tr>
                                )
                            })}
                            {listAtividadesGeral.length > 0 &&
                                <Tr h={'25px'} bgGradient={tipoCalendario == 1 ? gradientHeader : gradientGray} >
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'} >
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Total'}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{qtdArvoes}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                        </Box>
                                    </Th>
                                </Tr>
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    return (
        <Box>
            {renderTabela()}
        </Box>
    );
}

export default CardEstimulacao;