import { Flex, useColorModeValue, Text } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function CardInfo(props) {

    const { textColorValue, name, value } = props;
    const textColor = useColorModeValue("black", "white");
    const bg = useColorModeValue("white", "navy.700");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.4)', 'unset');

    let colorResultValue = textColorValue != null || textColorValue != '' ? textColorValue : textColor;

    return (
        <Card py='10px' align='center' justify={'start'} boxShadow={cardShadow} bg={bg}>
            <Flex
                my='auto'
                h='100%'
                align={{ base: "center", xl: "start" }}
                justify={{ base: "center", xl: "center" }}>
                <Flex w={'100%'} h={'100%'} flexDirection={'column'} justify={'space-between'} align={'center'}>
                    <Flex justify={'center'} align={'center'} >
                        <Text color={textColor} fontSize={{ base: "sm", }} fontWeight={'light'} lineHeight={'100%'} me='5px'>{name}</Text>
                    </Flex>
                    <Flex justify={'center'} align={'center'} >
                        <Text color={colorResultValue} fontSize={{ base: "xl", }} fontWeight='700' me='5px'>{value}</Text>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
}
