import React, { useState, useEffect } from "react";
import {
    Flex,
    useColorModeValue,
    Box,
    Text,
    Button,
    Wrap,
    WrapItem,
    Radio,
    RadioGroup,
    Select,
    Input,
    Textarea,
    Grid,
    GridItem,
    Checkbox,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import MessageSreen from 'components/message/MessageSreen';
import Loader from 'components/loading/Loader';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import moment from 'moment';
import { MOTIVO_TAREFA_NAO_RELIZADA } from 'model/MockData';
import ModalDialog from 'components/modal/ModalDialog';

export default function LancarTarefa() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);

    const [maximumDate, setMaximumDate] = useState('');
    const [minimumDate, setMinimumDate] = useState('');
    const [dataRealizadoP, setDataRealizadoP] = useState('');
    const [idControleSangriaP, setIdControleSangriaP] = useState(null);
    const [loading, setLoading] = useState(false);
    const [letraCustomizada, setLetraCustomizada] = useState('');
    const [idPessoaP, setIdPessoaP] = useState(0);
    const [idUaP, setIdUaP] = useState(0);
    const [idUsuarioLogado, setIdUsuarioLogado] = useState(0);

    const [idSafra, setIdSafra] = useState(0);
    const [valueRadioRealizadaNoDia, setValueRadioRealizadaNoDia] = useState('1');
    const [valueRadioForaDoPrazo, setValueRadioForaDoPrazo] = useState('3');
    const [showMotivo, setShowMotivo] = useState(false);
    const [motivoSelecionado, setMotivoSelecionado] = useState('0');
    const [motivoOriginal, setMotivoOriginal] = useState('0');
    const [isValidMotivo, setIsValidMotivo] = useState(false);
    const [isValidData, setIsValidData] = useState(false);
    const [listaDiasUteisSeringueiroP, setListaDiasUteisSeringueiroP] = useState([]);

    const [idStatusTarefaAtual, setIdStatusTarefaAtual] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [observacao, setObservacao] = useState('');
    const [observacaoOriginal, setObservacaoOriginal] = useState('');
    const [maxCharacterObservacao, setMaxCharacterObservacao] = useState(1000);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [messageDialog, setMessageDialog] = useState('');

    const [nomeSeringueiro, setNomeSeringueiro] = useState('--');
    const [selecionadaParaExclusao, setSelecionadaParaExclusao] = useState(null);
    const [listLetraTarefaItem, setListLetraTarefaItem] = useState([]);
    const [listLetraTarefaItemOriginal, setListLetraTarefaItemOriginal] = useState([]);
    const [dataSelecionada, setDataSelecionada] = useState('');
    const [dtInicioSangria, setDtInicioSangria] = useState('');
    const [checkNaoHouveSangria, setCheckNaoHouveSangria] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const inputBg = useColorModeValue("white", "navy.700");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const cardShadowObs = useColorModeValue('0px 2px 10px rgba(0, 0, 0, 0.8)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const res2 = localStorage.getItem('@HE-dadosParaTelaLancarTarefa');
            if (res2 != null) {
                const obj2 = JSON.parse(res2);

                const idUa = localStorage.getItem('@HE-idUa');
                const idSafraP = localStorage.getItem('@HE-idSafra');
                const idUserLogadoPP = localStorage.getItem('@HE-idUsuarioLogado');
                const dtInicioSafraP = localStorage.getItem('@HE-dtInicioSafraAsync');
                setIdUaP(idUa);
                setIdSafra(idSafraP);
                setIdUsuarioLogado(idUserLogadoPP);
                setDtInicioSangria(dtInicioSafraP);

                const dataSelecionadaP = obj2.dtMes != null ? moment(obj2.dtMes).format('YYYY-MM-DD') : '';
                const idStatusTarefaAt = obj2.idStatusTarefa != null && obj2.idStatusTarefa != '' ? obj2.idStatusTarefa : 0;
                const statusNaoRealizadaP = obj2.statusNaoRealizada != null ? obj2.statusNaoRealizada : 0;
                const idMotivoP = obj2.idMotivo != null && obj2.idMotivo != '' ? `${obj2.idMotivo}` : '0';

                //const idTarefaP = obj2.idTarefa != null ? obj2.idTarefa : '';  `${obj2.idMotivo}`
                //const observacaoP = obj2.observacao != null ? obj2.observacao : '';
                //const listTarefaP = obj2.listTarefa != null ? obj2.listTarefa : [];
                //const listMaisAtrasadaPacaIdTarefaP = obj2.listMaisAtrasadaPacaIdTarefa != null ? obj2.listMaisAtrasadaPacaIdTarefa : [];

                setDataSelecionada(dataSelecionadaP);
                setNomeSeringueiro(obj2.nomeSeringueiro);
                setIdStatusTarefaAtual(idStatusTarefaAt);
                setListaDiasUteisSeringueiroP(obj2.listaDiasUteisSeringueiro);
                setIdPessoaP(obj2.idPessoa);
                setLetraCustomizada(obj2.letraCustomizada);
                setIdControleSangriaP(obj2.idControleSangria);
                //setStatusNaoRealizada(statusNaoRealizadaP);
                //setDtProgramadoP(obj2.dtProgramado);
                //setidTarefa(idTarefaP);

                if (statusNaoRealizadaP == 4) {
                    setCheckNaoHouveSangria(true);
                    setShowMotivo(true);

                    if (idMotivoP != '0') {
                        setMotivoSelecionado(idMotivoP);
                        setMotivoOriginal(idMotivoP);
                    }
                }

                await carregarInformacaoNaTela(idUa, idSafraP, obj2.idPessoa, obj2.dtMes);

                const maxDate = moment().format('YYYY-MM-DD');
                setMaximumDate(maxDate);
                const mimDate = moment(obj2.dtProgramado).format('YYYY-MM-DD');
                setMinimumDate(mimDate);
                setDataRealizadoP(mimDate);
            }
            setTelaCarregada(true);
        }
        loaderScreen();
    }, []);

    async function carregarInformacaoNaTela(idUa, idSafra, idPessoa, dataSelecionadaP) {
        try {
            setListLetraTarefaItem([]);
            setListLetraTarefaItemOriginal([]);

            const url = `atividadeSangria/retornaControleSangriaDasTarefas?idUa=${idUa}&idSafra=${idSafra}&idPessoa=${idPessoa}&dataSelecionada=${dataSelecionadaP}`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                const observacaoP = res.observacao != null ? res.observacao : '';;
                setObservacao(observacaoP);
                setObservacaoOriginal(observacaoP);
                calcObservacao(observacaoP);

                const listLetraTarefaItemP = res.listLetraTarefaItem;
                setListLetraTarefaItem(listLetraTarefaItemP);
                setListLetraTarefaItemOriginal(listLetraTarefaItemP);

            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            console.log('Error classe LancarTarefa metodo carregarInformacaoNaTela', error);
        }
    }

    const changeRadioButton = async (val) => {
        setValueRadioRealizadaNoDia(val);
        if (val == '2') {
            setValueRadioForaDoPrazo('3');
            //setShowRadioForaDoPrazo(true);
            //setShowInserirData(true);
            setShowMotivo(false);
        } else {
            //setShowRadioForaDoPrazo(false);
            //setShowInserirData(false);
            setShowMotivo(false);
        }
    }

    const changeRadioForaDoPrazo = async (val) => {
        setValueRadioForaDoPrazo(val);
        if (val == '3') {
            //setShowInserirData(true);
            setShowMotivo(false);
        } else {
            //setShowInserirData(false);
            setShowMotivo(true);
        }
    }

    const onChangeRealizado = (val) => {
        setDataRealizadoP(val);
        setIsValidData(false);
    }

    async function salvarTarefa() {
        try {
            let dtRealiza = null;
            let idMotivoP = null;
            let tarefaNaoRealizadaP = false;
            let idStatusTarefaP = ActionTypes.ID_STATUS_TAREFA_REALIZADA;
            let valueRadioRealizadaNoDiaP = true;

            if (checkNaoHouveSangria) {
                if (motivoSelecionado == '') {
                    setNumStatusResposta(ActionTypes.CODIGO_VERIFICAR_CAMPOS_OBRIGATORIOS);
                    showAlert();
                    setIsValidMotivo(true);
                    return;
                } else {
                    setIsValidMotivo(false);
                }
            }

            const idSafraP = localStorage.getItem('@HE-idSafra');
            const observacaoSemEspacosLaterais = observacao.trim();
            const arrayLancamentos = [];
            const somenteTarefasAlteradas = await retornaTarefasAlteradas(listLetraTarefaItemOriginal, listLetraTarefaItem);

            if (somenteTarefasAlteradas.length > 0 || observacaoOriginal != observacaoSemEspacosLaterais || motivoOriginal != motivoSelecionado) {      // VERIFICANDO ALTERACOES NA TELA  

                for (let i = 0; i < somenteTarefasAlteradas.length; i++) {
                    const item = somenteTarefasAlteradas[i];

                    dtRealiza = null;
                    idMotivoP = null;
                    tarefaNaoRealizadaP = false;
                    idStatusTarefaP = ActionTypes.ID_STATUS_TAREFA_REALIZADA;
                    valueRadioRealizadaNoDiaP = false;

                    if (item.statusNaoRealizada == 4) {
                        idMotivoP = item.idMotivo;
                    } else {
                        idMotivoP = null;
                    }

                    if (item.checkdTarefa) {
                        dtRealiza = `${moment(dataSelecionada).format('YYYY-MM-DD')} 00:00:00`;
                        idStatusTarefaP = ActionTypes.ID_STATUS_TAREFA_REALIZADA;
                        tarefaNaoRealizadaP = false;

                        if (moment(item.dtProgramado).format("YYYY-MM-DD") === moment(dataSelecionada).format("YYYY-MM-DD")) {
                            valueRadioRealizadaNoDiaP = true;
                        } else {
                            valueRadioRealizadaNoDiaP = false;
                        }

                    } else {
                        dtRealiza = null;
                        idStatusTarefaP = ActionTypes.ID_STATUS_TAREFA_ATRASADA;
                    }

                    const objTarefa = {
                        idControleSangria: item.idControleSangria,
                        idUsuario: idUsuarioLogado,
                        idPessoa: idPessoaP,
                        idUa: idUaP,
                        idSafra: idSafraP,
                        dtProgramado: `${item.dtProgramado} 00:00:00`,
                        dtRealizado: dtRealiza,  // SE A TAREFA REALIZADA NO DIA EU PASSO A DATA DA PROGRAMACAO NO BACK
                        idStatusTarefa: idStatusTarefaP,
                        idMotivo: idMotivoP,
                        statusNaoRealizada: item.statusNaoRealizada,
                        tarefaFoiRealizadaNoDia: valueRadioRealizadaNoDiaP,
                        tarefaNaoRealizada: tarefaNaoRealizadaP,
                        dtInicioSafra: dtInicioSangria,
                        observacao: observacaoSemEspacosLaterais != '' ? observacaoSemEspacosLaterais : '',
                        idTarefa: item.idTarefa,
                        checkdTarefa: item.checkdTarefa,
                        deletarTarefa: item.deletarTarefa,
                    }
                    arrayLancamentos.push(objTarefa);
                }

                const objTarefaRequeste = {
                    idSafra: idSafraP,
                    idPessoa: idPessoaP,
                    observacao: observacaoSemEspacosLaterais != '' ? observacaoSemEspacosLaterais : '',
                    dataSelecionada: dataSelecionada,
                    listLancarTarefa: arrayLancamentos,
                    idControleSangria: idControleSangriaP,
                    idMotivo: motivoSelecionado,
                    statusNaoRealizada: checkNaoHouveSangria == true ? 4 : 0,
                }
                setLoading(true);
                const response = await Api.postRequestSemAccessToken(`api/lancarTarefa/novoMetodoLancamentoDeTarefas`, objTarefaRequeste);
                setLoading(false);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {

                    history.goBack();
                } else {
                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();
                }

            } else {
                setNumStatusResposta(ActionTypes.SEM_ALTERACAOES_NA_TELA);
                showAlert();
                return;
            }

        } catch (error) {
            setLoading(false);
            console.log('Error classe LancarTarefa metodo salvarTarefa', error);
        }
    }

    const changeOpenDialogModal = (item) => {
        setSelecionadaParaExclusao(item);
        setMessageDialog(`Tem certeza que deseja excluir o lançamento da tarefa ${letraCustomizada}`);
        setOpenModalDialog(true);
    }

    const excluirLancamento = async () => {
        try {
            setOpenModalDialog(false);
            const dtInicioSafraP = localStorage.getItem('@HE-dtInicioSafraAsync');
            const idSafraP = localStorage.getItem('@HE-idSafra');

            const arrayTarefa = [{
                idControleSangria: selecionadaParaExclusao.idControleSangria,
                idUsuario: idUsuarioLogado,
                idPessoa: idPessoaP,
                idUa: idUaP,
                idSafra: idSafraP,
                dtProgramado: selecionadaParaExclusao.dtProgramado,
                tarefaFoiRealizadaNoDia: false,
                tarefaNaoRealizada: true,
                dtInicioSafra: dtInicioSafraP,
                idTarefa: selecionadaParaExclusao.idTarefa,
                //dtRealizado: null,  // SE A TAREFA REALIZADA NO DIA EU PASSO A DATA DA PROGRAMACAO NO BACK
                //idMotivo: null,
                //idStatusTarefa: null,
                //descData: descDataP,
                //dsStatus: dsStatusP,
                //observacao: null,
            }]

            setLoading(true);
            const response = await Api.postRequest(`api/lancarTarefa/retornaControleSangriaDasTarefas`, arrayTarefa);
            setLoading(false);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {

                history.goBack();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            setLoading(false);
            console.log('Error classe LancarTarefa metodo excluirLancamento', error);
        }
    }

    const validarSeTarefaEstaComStatusDeNaoRealizada = async () => {        //  NAO APAGAR ESSE METODO
        let result = true;
        if (idStatusTarefaAtual == 4) {
            if (valueRadioRealizadaNoDia == 2 && valueRadioForaDoPrazo == 3) {
                result = true;
            } else {
                setNumStatusResposta(ActionTypes.INFORME_DATA_POSTERIOR);
                showAlert();
                result = false;
                return;
            }
        }
        return result;
    }

    const changeObservacao = (val) => {
        setObservacao(val);
        calcObservacao(val);
    }

    const limparObservacao = () => {
        setObservacao('');
        setMaxCharacterObservacao(1000);
    }

    const calcObservacao = (val) => {
        const xx = val.length;
        const num = 1000 - xx;
        setMaxCharacterObservacao(num);
    }

    const validarDiasUteisSeringueiro = async () => {
        let result = true;      // ESSE CARA E false MAS ESTA true POR QUE A REGRA DIZ QUE ELE PODE LANCAR TAREFA NO DIA NAO ITIL
        try {
            const dtRealiza = moment(dataRealizadoP).format('YYYY-MM-DD HH:mm:ss');
            const nomeDiaSemana = moment(dtRealiza).format('dddd').toUpperCase();
            await new Promise(resolve => {
                resolve(
                    listaDiasUteisSeringueiroP.map(item => {
                        if (nomeDiaSemana == item) {
                            result = true;
                        }
                    })
                )
            });
        } catch (error) {
            setLoading(false);
            console.log('Error classe LancarTarefa metodo validarDiasUteisSeringueiro', error);
        }
        return result;
    }

    const showAlert = () => {
        window.scrollTo(0, 0);

        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 15000);
    }

    const changeCheckNaoHouveSangria = () => {      // ======== NAO HOUVE SANGRIA
        const val = !checkNaoHouveSangria;
        setCheckNaoHouveSangria(val);
        if (val) {
            setShowMotivo(true);
            const obj = {
                idControleSangria: idControleSangriaP,
                statusNaoRealizada: 4,
            }
            changeAlterarStatusNaoRealizada(obj);
        } else {
            setShowMotivo(false);
            const obj = { idControleSangria: idControleSangriaP, statusNaoRealizada: 0 }
            changeAlterarStatusNaoRealizada(obj);

            changeComboMotivo('');
        }
    }

    const changeAlterarStatusNaoRealizada = (listObj) => {

        const statusNaoRealizadaP = listObj.statusNaoRealizada;
        const idControleSangria = listObj.idControleSangria;

        setListLetraTarefaItem((prevState) =>
            prevState.map((item) => ({
                ...item,
                listTarefa: item.listTarefa.map((tarefa) =>
                    tarefa.idControleSangria === idControleSangria
                        ? {
                            ...tarefa,
                            statusNaoRealizada: statusNaoRealizadaP,
                        }
                        : tarefa
                ),
            }))
        );
    };

    const changeComboMotivo = async (val2) => {    /// ======== MOTIVO 
        const val = val2;
        setMotivoSelecionado(val);

        if (val != '') {
            setIsValidMotivo(false);
            const obj = {
                idControleSangria: idControleSangriaP,
                idMotivo: val,
            }
            changeAlterarIdMotivo(obj);
        } else {

        }
    }
    const changeAlterarIdMotivo = (listObj) => {

        const idMotivoP = listObj.idMotivo;
        const idControleSangria = listObj.idControleSangria;

        setListLetraTarefaItem((prevState) =>
            prevState.map((item) => ({
                ...item,
                listTarefa: item.listTarefa.map((tarefa) =>
                    tarefa.idControleSangria === idControleSangria
                        ? {
                            ...tarefa,
                            idMotivo: idMotivoP,
                        }
                        : tarefa
                ),
            }))
        );
    };

    const changeCheckTarefa = async (listObj) => {    // ======== CHECK TAREFA

        const checkdTarefaP = !listObj.checkdTarefa;
        const idControleSangria = listObj.idControleSangria;
        const statusDeletarTarefa = await verificarSeOriginalEstaChecado(idControleSangria, checkdTarefaP);

        setListLetraTarefaItem((prevState) =>
            prevState.map((item) => ({
                ...item,
                listTarefa: item.listTarefa.map((tarefa) =>
                    tarefa.idControleSangria === idControleSangria
                        ? {
                            ...tarefa,
                            checkdTarefa: checkdTarefaP,
                            deletarTarefa: statusDeletarTarefa,
                        }
                        : tarefa
                ),
            }))
        );
    };
    async function verificarSeOriginalEstaChecado(idControle, statusTarefaSelecionada) {        // SIMPLES, SOMENTE SE A TAREFA CHEGAR MARCADA E O USUARIO DESMARCAR AI SIM DELETO
        let result = null;

        const idControleSangria = idControle != null ? idControle : 0;
        const tarefaSelecionada = statusTarefaSelecionada != null ? statusTarefaSelecionada : false;

        for (let i = 0; i < listLetraTarefaItemOriginal.length; i++) {
            const item = listLetraTarefaItemOriginal[i];

            for (let j = 0; j < item.listTarefa.length; j++) {
                const tarefa = item.listTarefa[j];

                if (tarefa.idControleSangria == idControleSangria) {
                    const statusOriginal = tarefa.checkdTarefa;

                    if (statusOriginal == true && tarefaSelecionada == false) {
                        result = true;
                    } else {
                        result = false;
                    }
                }
            }
        }
        return result;
    };

    async function retornaTarefasAlteradas(original, atualizado) {    // ======== RETORNA ALTERADAS
        let diferencas = [];

        for (let i = 0; i < atualizado.length; i++) {
            const novoItem = atualizado[i];

            for (let j = 0; j < novoItem.listTarefa.length; j++) {
                const novaTarefa = novoItem.listTarefa[j];

                const tarefaOriginal = original[i].listTarefa.find(t => t.idControleSangria === novaTarefa.idControleSangria);

                if (tarefaOriginal) {       // Verifica se houve alteracao em qualquer um dos campos
                    if (tarefaOriginal.checkdTarefa != novaTarefa.checkdTarefa
                        || tarefaOriginal.deletarTarefa != novaTarefa.deletarTarefa
                        || tarefaOriginal.statusNaoRealizada != novaTarefa.statusNaoRealizada
                        || tarefaOriginal.idMotivo != novaTarefa.idMotivo
                    ) {
                        diferencas.push(novaTarefa);
                    }
                }
            }
        }
        return diferencas;
    };

    function renderArrayTarefas() {
        const dataFormatada = moment(dataSelecionada).format('DD/MM/YYYY');
        return (
            <Box>
                <Text color={textColor} fontSize={'25px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Lançamentos de Tarefas'}</Text>
                <Text color={'orange.600'} fontSize={'20px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'} mt={'5px'}>{`Data selecionada: ${dataFormatada}`}</Text>
                <Flex px='25px' flexDirection={'column'} justify='start' align='start'>
                    <Flex justify='start' align='center'>
                        <Text color={textColor} fontSize='17px' fontWeight='700' lineHeight='100%'>Seringueiro:</Text>
                        <Text ml={'10px'} color='orange.700' fontSize='17px' fontWeight='700' lineHeight='100%'>{nomeSeringueiro}</Text>
                    </Flex>
                    <Flex mt={'5px'} justify='start' align='center'>
                        <Text color={textColor} fontSize='17px' fontWeight='700' lineHeight='100%'>{'Quais tarefas foram sangradas nessa data?'}</Text>
                    </Flex>
                </Flex>
                <Box
                    mt={'10px'}>
                    <Wrap spacing='20px' mx={'10px'}>
                        {listLetraTarefaItem.length > 0 &&
                            listLetraTarefaItem.map((item, index) => {
                                const borderWidthP = item.listTarefa.length > 1 ? '1px' : '0px';
                                return (
                                    <WrapItem key={index}>
                                        <Flex flexDirection={'column'} w={'100%'} justify={'start'} align={'center'} borderWidth={borderWidthP} borderColor={'black'} borderRadius={'10px'} paddingX={'10px'} paddingY={'10px'}> 
                                            {item.listTarefa.map((item2, index2) => {
                                                const dtRealizado = item2.dtRealizado != null ? moment(item2.dtRealizado).format('DD/MM/YY') : '';
                                                const dtProgramado = item2.dtProgramado != null ? moment(item2.dtProgramado).format('DD/MM/YY') : '';
                                                const idCon = item2.idControleSangria;
                                                let desabilitaCard = false;
                                                if (idCon == 1 || idCon == 2 || idCon == 3 || idCon == 4 || idCon == 5 || idCon == 6 || idCon == 7) {
                                                    desabilitaCard = true;
                                                }

                                                let dtRealizadoIgualDtSelecionado = false;
                                                let borderColorP = 'black';
                                                let corChandow = '5px 5px 10px black';        //  direita baixo expansao cor

                                                let widthCard = '120px';
                                                if (dtRealizado != '') {
                                                    corChandow = '5px 5px 10px green';
                                                    borderColorP = 'green';
                                                    widthCard = '200px';
                                                    dtRealizadoIgualDtSelecionado = dataSelecionada == item2.dtRealizado ? true : false;
                                                }

                                                let justifyP = 'center';
                                                let mostraDtProgramado = false;
                                                let tarefaDoDia = false;

                                                if (idUsuarioLogado == 15) {
                                                    justifyP = 'center';
                                                    mostraDtProgramado = !desabilitaCard ? true : false;
                                                    tarefaDoDia = idControleSangriaP == item2.idControleSangria ? true : false;
                                                }

                                                return (
                                                    <Box key={index2} w={'100%'}>
                                                        <Flex
                                                            pointerEvents={desabilitaCard ? 'none' : 'auto'}
                                                            opacity={desabilitaCard ? '0.5' : '1'}
                                                            flexDirection={'column'}
                                                            boxShadow={corChandow}
                                                            px={'15px'}
                                                            py={'10px'}
                                                            w={widthCard}
                                                            minH={'70px'}
                                                            borderRadius={'10px'}
                                                            borderColor={borderColorP}
                                                            borderWidth={'1px'}
                                                            justify={'center'}
                                                            position={'relative'}
                                                            align={'center'}>
                                                            <Flex w={'100%'} justify={'start'} align={'start'}>
                                                                <Box pt={'0px'} >
                                                                    <Flex justify={'center'} align={'end'}>
                                                                        <Text color={textColor} fontSize='30px' fontWeight='bold' lineHeight='100%' mr={'5px'}>{item2.letraCustomizada}</Text>
                                                                        <Flex pl={'10px'} justify={'center'} align={'center'}>
                                                                            <Checkbox
                                                                                size='lg'
                                                                                colorScheme='blue'
                                                                                isChecked={item2.checkdTarefa}
                                                                                value={'MONDAY'}
                                                                                onChange={(e) => { changeCheckTarefa(item2) }}
                                                                                mb={'5px'}
                                                                                borderWidth={'1px'}
                                                                                borderColor={'blackAlpha.500'} />
                                                                        </Flex>
                                                                    </Flex>
                                                                </Box>
                                                                {dtRealizado != '' &&
                                                                    <Flex w={'100%'} pl={'10px'} justify={'start'} align={'start'}>
                                                                        {dtRealizadoIgualDtSelecionado ?
                                                                            <Box>
                                                                                {/* <Text color={'green'} fontSize='13px' fontWeight='bold' lineHeight='100%' align={'center'}>{'Relizado'}</Text> */}
                                                                                <Text color={'white'} bg={'green'} borderRadius={'10px'} px={'10px'} py={'3px'} fontSize='13px' fontWeight='bold' lineHeight='100%' mt={'2px'} align={'center'}>{'Relizado'}</Text>
                                                                            </Box>
                                                                            :
                                                                            <Box>
                                                                                <Text color={item2.corStatus} fontSize='13px' fontWeight='bold' lineHeight='100%'>{'Relizado em'}</Text>
                                                                                <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%' mt={'5px'}>{dtRealizado}</Text>
                                                                            </Box>
                                                                        }
                                                                    </Flex>
                                                                }
                                                            </Flex>
                                                            {mostraDtProgramado &&
                                                                <Box position={'absolute'} right={'10px'} bottom={'2px'}>
                                                                    <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>{dtProgramado}</Text>
                                                                </Box>
                                                            }
                                                            {tarefaDoDia &&
                                                                <Box position={'absolute'} right={'10px'} top={'5px'}>
                                                                    <Box w={'14px'} h={'14px'} borderRadius={'7px'} bg={'blue'} />
                                                                </Box>
                                                            }
                                                        </Flex>
                                                    </Box>
                                                )
                                            })}
                                        </Flex>
                                    </WrapItem>
                                )
                            })}
                    </Wrap>
                </Box>
            </Box>
        )
    }

    function renderCheckNaoHolveSangria() {
        return (
            <Flex mt={'20px'} px={'20px'} py={'20px'} flexDirection={'column'} justify={'start'} align={'start'}>
                <Checkbox
                    size='lg'
                    colorScheme='blue'
                    isChecked={checkNaoHouveSangria}
                    onChange={() => { changeCheckNaoHouveSangria() }}
                    borderColor={'black'}
                >
                    <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Não houve sangria</Text>
                </Checkbox>
            </Flex>
        )
    }

    function renderRadioRealizadaNoDia() {
        return (
            <Flex mt={'20px'} px={'20px'} flexDirection={'column'} justify={'start'} align={'start'}>
                <Text py={'10px'} color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'start'}>{'Esta tarefa foi relizada no dia programado?'}</Text>
                <RadioGroup onChange={(e) => { changeRadioButton(e) }} value={valueRadioRealizadaNoDia}>
                    <Wrap spacing='100px' mx={'10px'}>
                        <WrapItem>
                            <Box>
                                <Radio value='1' size="lg" borderWidth={'1px'} borderColor={'blackAlpha.600'} >
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Sim</Text>
                                </Radio>
                            </Box>
                        </WrapItem>
                        <WrapItem>
                            <Box>
                                <Radio value='2' size="lg" borderWidth={'1px'} borderColor={'blackAlpha.600'}>
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Não</Text>
                                </Radio>
                            </Box>
                        </WrapItem>
                    </Wrap>
                </RadioGroup>
            </Flex>
        )
    }

    function renderRadioRealizadaForaDoPrazo() {
        return (
            <Flex mt={'20px'} px={'20px'} flexDirection={'column'} justify={'start'} align={'start'}>
                <Text py={'10px'} color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'start'}>{'Ela foi realizada?'}</Text>
                <RadioGroup onChange={(e) => { changeRadioForaDoPrazo(e) }} value={valueRadioForaDoPrazo}>
                    <Wrap spacing='100px' mx={'10px'}>
                        <WrapItem>
                            <Box>
                                <Radio value='3' size="lg" borderWidth={'1px'} borderColor={'blackAlpha.600'} >
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Sim</Text>
                                </Radio>
                            </Box>
                        </WrapItem>
                        <WrapItem>
                            <Box>
                                <Radio value='4' size="lg" borderWidth={'1px'} borderColor={'blackAlpha.600'}>
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Não</Text>
                                </Radio>
                            </Box>
                        </WrapItem>
                    </Wrap>
                </RadioGroup>
            </Flex>
        )
    }

    function renderCalender() {
        return (
            <Box w={'100%'} px={'20px'} mt={'30px'}>
                <Text ml={'10px'} mb={'5px'} color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>Em que dia ela foi realizada?</Text>
                <Input
                    value={dataRealizadoP}
                    variant='filled'
                    onChange={(e) => onChangeRealizado(e.target.value)}
                    errorBorderColor='crimson'
                    isInvalid={isValidData}
                    placeholder=""
                    bg={inputBg}
                    color={inputText}
                    borderColor={textColor}
                    borderRadius="10px"
                    size="md"
                    type="date"
                    min={minimumDate}
                    max={maximumDate}
                    onKeyDown={(e) => { e.preventDefault() }}
                    fontSize={'13px'}
                    fontWeight={'bold'}
                    h={'35px'}
                    w={'250px'}
                />
            </Box>
        )
    }

    function renderComboMotivo() {
        return (
            <Box px={'20px'} w='100%' mt={'20px'}>
                <Text ml={'10px'} mb={'5px'} color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>Motivo</Text>
                <Select
                    value={motivoSelecionado}
                    errorBorderColor='crimson'
                    isInvalid={isValidMotivo}
                    placeholder="Todos"
                    variant="filled"
                    borderRadius="10px"
                    borderColor={textColor}
                    textColor={textColor}
                    fontSize={'13px'}
                    fontWeight={'bold'}
                    onChange={(e) => changeComboMotivo(e.target.value)}
                    h={'35px'}
                    w={'250px'}
                >
                    {MOTIVO_TAREFA_NAO_RELIZADA.map((item, index) => {
                        return (
                            <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                        )
                    })}
                </Select>
                {isValidMotivo &&
                    <Text color={'red'} fontSize={'13px'} fontWeight={'bold'} lineHeight={'100%'} mt={'5px'} ml={'10px'}>Selecione um motivo</Text>
                }
            </Box>
        )
    }

    function remderObservacoes() {
        return (
            <Box minH={'170px'} p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex flexDirection={'column'} align='center' justify={'center'}>
                    <Text mb={'10px'} color={textColor} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Observação'}</Text>
                    <Textarea
                        value={observacao}
                        onChange={(e) => { changeObservacao(e.target.value) }}
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={'blackAlpha.700'}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'medium'}
                        maxLength={1000}
                    />
                </Flex>
                <Flex px={'10px'} pt={'10px'} align='center' justify={'space-between'}>
                    <Button variant="action" borderRadius={'10px'} h={'30px'} color={textColor} borderColor={'gray.700'} onClick={() => { limparObservacao() }}>Limpar</Button>
                    <Text color={textColor} fontSize={'14px'} fontWeight='bold' align={'end'}>{`Caracteres restantes ${maxCharacterObservacao}`}</Text>
                </Flex>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' px={'10px'} mt={'30px'} justify='space-between'>
                <Button variant="brand" onClick={salvarTarefa}>Lançar tarefa</Button>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Wrap>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'-5px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center'>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
            <Card px={'10px'} py={'10px'}>
                {isVisibleAlert && renderAlert()}
                {/* {renderTarefa()} */}
                {renderArrayTarefas()}
                <Box px={'10px'} mt={'20px'} bg={'white'} borderRadius={'20px'}>
                    <Grid templateColumns={{ base: "1fr", md: "1fr 2fr" }} gap={4} >
                        <GridItem>
                            <Box minH={'170px'} pb={'10px'} borderRadius={'10px'} boxShadow={cardShadowObs}>
                                {renderCheckNaoHolveSangria()}
                                {/* {renderRadioRealizadaNoDia()} */}
                                {/* {showRadioForaDoPrazo && renderRadioRealizadaForaDoPrazo()} */}
                                {/* {showInserirData && renderCalender()} */}
                                {showMotivo && renderComboMotivo()}
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box borderRadius={'10px'}>
                                {remderObservacoes()}
                            </Box>
                        </GridItem>
                    </Grid>
                    {renderButton()}
                </Box>
            </Card>
            {loading && <Loader isOpen={loading} />}
            {openModalDialog &&
                <ModalDialog
                    isOpen={openModalDialog}
                    onPressOk={() => { excluirLancamento() }}
                    onPressCancel={() => { setOpenModalDialog(false) }}
                    descricao={messageDialog}
                    descricao2={'Excluindo o lançamento a tarefa volta para o status original.'}
                    titulo={'Excluir Lançamento'} />}
        </Box>
    );
}
