import React, { useState, useEffect } from "react";
import {
    Box,
    Text,
    Flex,
    Select,
    useColorModeValue,
    WrapItem,
    Wrap,
} from "@chakra-ui/react";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import moment from 'moment';
import ReactApexChart from "react-apexcharts";
import { ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO } from '../../../../util/FuncoesUteis';

export default function ChuvasDiarias(props) {

    const { marginText } = props;
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listMesesCombo, setListMesesCombo] = useState([]);
    const [mesSelecionado, setMesSelecionado] = useState(null);
    const [valorMaximoGrafico, setValorMaximoGrafico] = useState(100);
    const [legendas, setLegendas] = useState([]);
    const [colunasGrafico, setColunasGrafico] = useState([]);
    const [somaChuvaEmMilimetrosMes, setSomaChuvaEmMilimetrosMes] = useState(0);
    const [showCarregando, setShowCarregando] = useState(false);
    const [colorBackgroud, setColorBackgroud] = useState('white');
    const [labelDataMes, setLabelDataMes] = useState('');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const heightG = 250;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const dataAtual = moment().startOf('month').format('YYYY-MM-DD');
            setMesSelecionado(dataAtual);
            await pesquisar(dataAtual);

            const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
            const fim = localStorage.getItem('@HE-dtFimSafraAsync');

            const arrayDatas = await ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO(ini, fim);

            const ss = arrayDatas.filter(x => x.value == dataAtual);
            const zz = ss[0].label;
            setLabelDataMes(zz);
    
            setListMesesCombo(arrayDatas);
            setTelaCarregada(true);
        }
        loaderScreen();
    }, []);

    const pesquisar = async (dataP) => {
        try {
            const dtSelecionada = moment(dataP).startOf('month').format('YYYY-MM-DD');
            const ua = localStorage.getItem('@HE-idUa');
            const idSa = localStorage.getItem('@HE-idSafra');
            const idUf = localStorage.getItem('@HE-idUfCadastradadoNaUa');

            const url = `climaTempo/pesquisarClimaTempoPorMes?idUa=${ua}&idSafra=${idSa}&idUf=${idUf}&dtSelecionada=${dtSelecionada}`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                const zz = res.mesDto.somaChuvaEmMilimetrosMes;
                setSomaChuvaEmMilimetrosMes(zz);

                await constroiArrasGrafico(res.mesDto);

            } else {
                //setTelaCarregada(true);
            }

        } catch (error) {
            setTelaCarregada(true);
            setShowCarregando(false);
            console.log('Erro na classe ChuvasDiarias metodo pesquisar', error);
        }
    }

    const changeMeses = async (e) => {
        const val = e.target.value;
        setShowCarregando(true);
        setColorBackgroud('blackAlpha.200');

        const ss = listMesesCombo.filter(x => x.value == val);
        const zz = ss[0].label;
        setLabelDataMes(zz);

        setMesSelecionado(val);
        await pesquisar(val);

        setColorBackgroud('white');
        setShowCarregando(false);
    }

    async function constroiArrasGrafico(arr) {
        try {
            const listSemana = arr.listSemana;
            const listSiglaAndDia = arr.listSiglaAndDia;

            let arrayChuvaEmMm = [];
            for (let index = 0; index < listSemana.length; index++) {
                const array2 = listSemana[index];

                const listClimaTempo = array2.listClimaTempo;
                for (let index = 0; index < listClimaTempo.length; index++) {
                    const item = listClimaTempo[index];

                    if (item.isDisabled == false) {
                        const val = item.chuvaMm != null ? Number(item.chuvaMm) : 0;
                        arrayChuvaEmMm.push(val);
                    }
                }
            }

            let pp1 = null;
            const aaX1 = Math.max.apply(null, arrayChuvaEmMm);     // PEGANDO O MAIOR VALOR DO ARRAY
            const valorFinal = aaX1 > 100 ? aaX1 : 100;
            const val1 = Math.ceil(valorFinal / 100) * 100;  // ARREDONDANDO O VALOR A CADA 100, EX: VALOR 55 ARREDONDA PARA 100, SE FOR 110 ARREDONDA PARA 200, E POR AI VAI
            const val3 = val1;

            setValorMaximoGrafico(val3);

            if (aaX1 == 0) {
                pp1 = arrayChuvaEmMm.map(x => 1);
            } else {
                pp1 = arrayChuvaEmMm;
            }
            setColunasGrafico(pp1);

            let arrY = [];
            for (let index = 0; index < listSiglaAndDia.length; index++) {
                const item = listSiglaAndDia[index];

                let dia = item.dia != '' ? item.dia : 'N';
                let sigla = item.sigla != '' ? item.sigla : 'N';
                const zz = [dia, sigla];
                arrY.push(zz);
            }
            setLegendas(arrY);
        } catch (error) {
            console.log('Erro na classe ChuvasDiarias metodo constroiArrasGrafico22', error);
        }
    }

    const series = [
        {
            name: 'milímetros',
            data: colunasGrafico
            //data: [5, 0, 0, 10, 25, 30, 25, 5, 1, 0, 0, 0, 5, 10, 5, 0, 0, 0, 0, 10, 15, 10, 5, 0, 5, 1, 2, 5, 20, 10, 30]
        }
    ]

    const options = {
        chart: {
            height: heightG,
            type: 'bar',
            toolbar: {                          // DESABILITA OS BOTOES DO GRAFICO
                show: false,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
        },
        dataLabels: {                             // VALORES DENTRO DA COLUNA
            enabled: true,
            formatter: function (val) {
                const xx = val == 1 ? 0 : val;
                return `${xx}`;
            },
            enabledOnSeries: [0, 1],       // SO O NUMERO DA PARTE DE CIMA
            position: 'bottom',
            style: {
                colors: ["#000000"],
                fontSize: "12px",
                fontWeight: "700",
            },
            offsetX: 0,
            dropShadow: {
                enabled: false
            },
        },
        stroke: {
            width: [0, 3],
            curve: 'smooth'
        },
        markers: {
            size: 6,
            hover: {
                size: 10
            }
        },
        plotOptions: {
            bar: {
                //borderRadius: 5,
                horizontal: false,
                columnWidth: '90%', // LARGURA DA COLUNA
                endingShape: 'rounded',
                distributed: false, // Configura se as colunas sao distribuidas individualmente
                dataLabels: {
                    enabled: false,
                    position: 'bottom', // top, center, bottom
                    total: {
                        enabled: false,
                    },
                }
            }
        },
        grid: {
            row: {
                colors: ['#FFFFFF', '#d9d9d9']
            },
            padding: {
                left: 30,
                right: 10,
                top: 10,
                bottom: 10
            }
        },
        xaxis: {
            categories: legendas,
            tickPlacement: 'between',
            labels: {
                show: true,
                rotate: -45,
                style: {
                    colors: "#333333",
                    fontSize: "11px",
                    fontWeight: 500,
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
            },
        },
        yaxis: {
            show: true,
            max: valorMaximoGrafico,
            min: 0,
            logBase: 10,
            tickAmount: 5,
            floating: true,
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: [],
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    //cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: 15,        // LARGURA DO ESPACO DOS TEXTO NA LATERAL ESQUERDA
                offsetY: 0,         // Ajuste vertical
                rotate: 0,
            },
        },
        tooltip: {
            shared: false,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100]
            },
        }
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'5px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box px={'5px'} position={'relative'}>
            <Flex h={'100%'} flexDirection={'column'} justify='space-between' align='center' bg={'white'}>
                <Flex px={'5px'} mt={'10px'} h={'60px'} w={'100%'} justify='space-between' align='flex-start'>
                    <Flex justify='start' align='start'>

                        {marginText == '0px' ?
                            <Select
                                value={mesSelecionado}
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                onChange={changeMeses}
                                h={'30px'}
                                w={'150px'}
                            >
                                {listMesesCombo.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                            :
                            <Text mb={marginText} ml={'10px'} color={'black'} fontSize={'13px'} fontWeight='bold' lineHeight={'100%'}>{`${labelDataMes}`}</Text>
                        }
                    </Flex>
                    <Flex justify='end' align='start'>
                        <Wrap justify={'flex-end'} align={'start'} h={'100%'}>
                            <WrapItem flexDirection={'column'} w='120px' h='50px' bg={'blue.900'} alignItems={'center'} borderRadius={'10px'}>

                                <Text color={'white'} fontSize={'13px'} fontWeight='100'>Total de chuvas</Text>
                                <Text mb={marginText} color={'white'} fontSize={'13px'} fontWeight='100'>{`${somaChuvaEmMilimetrosMes}`} mm</Text>
                            </WrapItem>
                        </Wrap>
                    </Flex>
                </Flex>
                <Box w={'100%'} bg={colorBackgroud}>
                    <ReactApexChart options={options} series={series} type="bar" height={300} width={'100%'} />
                </Box>
            </Flex>
            {showCarregando &&
                <Box position={'absolute'} left={'0px'} right={'0px'} top={'80px'}>
                    <Text as='cite' fontSize={'18px'} textShadow={border_white} fontWeight={'bold'} color={'black'} align={'center'}>{'Carregando...'}</Text>
                </Box>
            }
        </Box>
    );
}
