import React, { useEffect, useState } from "react";
import {
    Box,
    useColorModeValue,
    Text,
    Flex,
    Wrap,
    WrapItem,
    Button,
    Select,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { retornaNomeDoMes, retornaNomeDoMesAbreviado } from '../../../../util/FuncoesUteis';
import { ARRAY_ANOS } from '../../../../model/MockData';
import ModalChuvasAnuais from 'components/modal/ModalChuvasAnuais';
import MessageSreen from 'components/message/MessageSreen';

export default function HistoricoChuvas() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [dataSelecionada, setDataSelecionada] = useState('');
    const [openModalHistoricoChuvas, setOpenModalHistoricoChuvas] = useState(false);
    const [anoSelecionado, setAnoSelecionado] = useState('');
    const [listMesesTabela, setListMesesTabela] = useState([]);
    const [mediaUltimosAnos, setMediaUltimosAnos] = useState('');
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [objParaEdicao, setObjParaEdicao] = useState({});
    
    const [idUsuarioLogado, setIdUsuarioLogado] = useState('');
    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [sangriaAtiva, setSangriaAtiva] = useState(false);
    
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const chuvaShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.3)', 'unset');

    const cardShadow = useColorModeValue('3px 3px 10px rgba(0, 0, 0, 0.5)', 'unset');
    const gradientCard = 'linear-gradient(gray.300, gray.200, gray.100)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const idUsuarioLogadoP = localStorage.getItem('@HE-idUsuarioLogado');
            setIdUsuarioLogado(idUsuarioLogadoP);

            const sangriaAtivaP = localStorage.getItem('@HE-sangriaAtivaAsync');
            const isAtiva = sangriaAtivaP == 'false' ? true : false;
            setSangriaAtiva(isAtiva);

            const ano = moment().startOf('month').format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const descData = `${nmMes}/${ano}`;
            setNomeMesCorrente(descData);

            await pesquisar(ano);
            setAnoSelecionado(ano);
        }
        loaderScreen();
    }, []);

    const pesquisar = async (ano) => {
        try {
            const idUa = localStorage.getItem('@HE-idUa');
            const idSa = localStorage.getItem('@HE-idSafra');

            const url = `climaTempo/listaChuvasMes?idUa=${idUa}&ano=${ano}&idSafra=${idSa}`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                await carregarDadosDaTela(res);
                setTelaCarregada(true);
            } else {
                setTelaCarregada(true);
            }
        } catch (error) {
            setTelaCarregada(true);
            console.log('Erro na classe ChuvasAnuais metodo pesquisar', error);
        }
    }

    async function carregarDadosDaTela(res) {
        try {
            const arr = res.listChuvaMesItem;

            if (arr.length > 0) {
                const arrTabela = [];

                for (let i = 0; i < arr.length; i++) {
                    const item = arr[i];

                    const jsonTabela = {
                        row: item.row,
                        idChuvaMes: item.idChuvaMes,
                        descMes: item.descMes,
                        mediaUltimosAnos: item.mediaUltimosAnos,
                        somaPorMes: item.somaPorMes,
                        dtMes: item.dtMes,
                    }
                    arrTabela.push(jsonTabela);
                }
                setListMesesTabela(arrTabela);
            }
        } catch (error) {
            console.log('Erro na classe ChuvasAnuais metodo constroiArrasGrafico', error);
        }
    }

    const changeMeses = async (val) => {
        setAnoSelecionado(val);
        const ss = Number(val);
        console.log('---', ss);

        pesquisar(ss);
    }

    const functionOpenModalDialog = async (item) => {
        setObjParaEdicao(item);
        setMediaUltimosAnos(item.mediaUltimosAnos);

        const mesAbreviado = await retornaNomeDoMesAbreviado(item.dtMes);
        const anoP = moment(item.dtMes).format('YYYY');
        const descData = `${mesAbreviado} / ${anoP}`

        setDataSelecionada(descData);
        setOpenModalHistoricoChuvas(true);
    }

    const salvarHistorico = async (inputValue) => {
        setOpenModalHistoricoChuvas(false);
        //setShowModal(false);

        const idUa = localStorage.getItem('@HE-idUa');
        const idSa = localStorage.getItem('@HE-idSafra');

        const json = {
            idChuvaMes: objParaEdicao.idChuvaMes,
            idUa: idUa,
            idSafra: idSa,
            mediaUltimosAnos: inputValue,
            dtMes: objParaEdicao.dtMes,
        }

        const response = await Api.postRequest('climaTempo/insertAndUpdateChuvasMes', json);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            await pesquisar(anoSelecionado);

            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        } else {
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    function renderAnos() {
        return (
            <Flex px='15px' justify='start' mt={'10px'} align='center' >
                <Flex px='5px' mb='2px' flexDirection={'column'} justify='center' align='center'>
                    <Box  >
                        <Text ml={'10px'} color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Ano</Text>
                        <Select
                            value={anoSelecionado}
                            errorBorderColor='crimson'
                            placeholder="Selecione"
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={(e) => changeMeses(e.target.value)}
                            h={'35px'}
                            w={'300px'}
                        >
                            {ARRAY_ANOS.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </Flex>
            </Flex>
        )
    }

    function renderHistoricoChuvas() {
        return (
            <Box boxShadow={cardShadow} borderRadius={'10px'} mt={'30px'} px={'5px'} py={'15px'} borderWidth={'1px'} borderColor={'blackAlpha.300'}>
                <Flex justify='center' align='center' mt={'2px'}>
                    {listMesesTabela.length > 0 ? (
                        <Wrap spacing='30px' px={'10px'} justify='start'>
                            {listMesesTabela.map((item, index) => {
                                return (
                                    <WrapItem key={index}>
                                        <Flex
                                            w={'80px'}
                                            py={'5px'}
                                            flexDirection={'column'}
                                            justify={'start'}
                                            align={'center'}
                                            borderWidth={'1px'}
                                            borderRadius={'10px'}
                                            borderColor={'blackAlpha.700'}
                                            bgGradient={gradientCard}
                                            onClick={() => { functionOpenModalDialog(item) }}
                                            cursor={'pointer'}
                                            _hover={{ bg: 'blackAlpha.100' }}
                                            boxShadow={chuvaShadow}>
                                            <Text color={textColor} fontSize='20px' fontWeight={'bold'} lineHeight='100%' mb={'10px'} align={'center'}>{item.descMes}</Text>
                                            <Flex justify='center' align='center' >
                                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' mb={'3px'} align={'center'}>{item.mediaUltimosAnos}</Text>
                                                <Text color={textColor} fontSize='11px' fontWeight={'bold'} lineHeight='100%' ml={'3px'}>{'mm'}</Text>
                                            </Flex>
                                        </Flex>
                                    </WrapItem>
                                )
                            })}
                        </Wrap>
                    ) : (
                        <Box mb={'5px'} w={'100%'} >
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' mb={'3px'} align={'center'}>{'Sem Valores'}</Text>
                        </Box>
                    )}
                </Flex>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Flex px='15px' justify='start' mt={'50px'} align='center' >
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Flex>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'5px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card align='center' direction='column' w='100%'>
                {isVisibleAlert && renderAlert()}

                <Text color={textColor} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Chuvas Anuais'}</Text>
                <Text color={'gray.500'} fontSize='15px' fontWeight={'light'} align={'center'}>{'Selecione um ano para pesquisar ou clique no mês para editar'}</Text>
                {renderAnos()}
                {renderHistoricoChuvas()}
                {renderButton()}
            </Card>
            {openModalHistoricoChuvas && (
                <ModalChuvasAnuais
                    isOpen={openModalHistoricoChuvas}
                    onPressCancel={() => { setOpenModalHistoricoChuvas(false) }}
                    onConfirm={salvarHistorico}
                    titulo={'Histórico de Chuvas'}
                    valueInput={mediaUltimosAnos}
                    mesAndAno={dataSelecionada}
                />
            )}
        </Box>
    );
}
