import React from "react";
import { Tooltip, Text, Box } from "@chakra-ui/react";
import { WarningTwoIcon } from '@chakra-ui/icons';      // ESSE CARA NAO PRECISA DE span

const TooltipObservacao = ({ objObservacao, height = '15px', color = 'orange.600' }) => {

    return (
        <Tooltip
            label={
                <Box px={'5px'} py={'5px'} >
                    <Text align={'center'}>{'Observação'}</Text>
                    <Text align={'start'} mt={'8px'} mb={'5px'} lineHeight='100%'>{objObservacao.observacao}</Text>
                </Box>
            }
            borderRadius={'10px'}
            size="sm"
            bg={'black'}>
            <WarningTwoIcon w={height} h={height} color={color} />
        </Tooltip>
    );
};

export default TooltipObservacao;
