import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
    FormErrorMessage,
} from "@chakra-ui/react";
import { NavLink, useHistory } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import * as Api from '../../../store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { mascaraTelefone, retiraMascaraMaterNumeros, primeiroNomeUsuarioLogadoAsyncStorage } from '../../../util/Masck';
import DefaultAuth from "layouts/auth/Default";
import {
    gerenciamentoListaDeUaQueUsuarioLogadoTemAcesso,
    verificarExisteNotificacoes,
    REMOVE_CACHE_RELATORIO_ESPORADICO,
    REMOVE_CACHE_RELATORIO_PERSONALIZADO
} from "../../../util/ValidarAutenticacao";
import Ilustration from "assets/img/dashboards/auth.png";
import { UtilContext } from 'contexts/UtilContext';
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export default function SignIn() {

    const { isAtualisarNotificacao, isUsuarioAutenticado } = useContext(UtilContext);
    const history = useHistory();

    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    const [loading, setLoading] = useState(false);

    const [telefone, setTelefone] = useState('');
    const [senha, setSenha] = useState('');
    const [isValidTelefone, setIsValidTelefone] = useState(false);
    const [isValidSenha, setIsValidSenha] = useState(false);
    const [isManterConectado, setIsManterConectado] = useState(false);
    const [mensageTelefone, setMensageTelefone] = useState('');
    const [mensageSenha, setMensageSenha] = useState('');
    const [isAuthentication, setIsAuthentication] = useState(false);
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);

    const textColor = useColorModeValue("navy.700", "white");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const textColorSecondary = "gray.400";

    useEffect(() => {
        async function loadScreen() {

            //await validarVersao();
            //localStorage.clear();
            localStorage.removeItem('@HE-idUsuarioSelecionadoTelaListaPropridade');
            const tel = localStorage.getItem('@HE-telefoneUsuarioParaTelaDeLogin');
            const senha = localStorage.getItem('@HE-senhaUsuarioParaTelaDeLogin');
            const check = localStorage.getItem('@HE-checkBoxParaTelaDeLogin');

            setTelefone(tel !== null && tel !== '' ? mascaraTelefone(tel) : '');
            setSenha(senha !== null && senha !== '' ? senha : '');
            setIsManterConectado(check === 'true' ? true : false);
            REMOVE_CACHE_RELATORIO_ESPORADICO();
            REMOVE_CACHE_RELATORIO_PERSONALIZADO();
        }
        loadScreen();
    }, []);

    const logarSistema = async () => {
        try {
            let valid = false;

            if (telefone.length < 15) {
                setIsValidTelefone(true);
                setMensageTelefone('Telefone inválido');
                valid = true;
            } else {
                setIsValidTelefone(false);
                setMensageTelefone('');
            }

            if (senha.length < 6) {
                setIsValidSenha(true);
                setMensageSenha('Mínimo 6 caracteres');
                valid = true;
            } else {
                setIsValidSenha(false);
                setMensageSenha('');
            }

            if (valid) return;

            const telSemMascara = retiraMascaraMaterNumeros(telefone);
            const emailParaLogin = `sangrador${telSemMascara}@hevea.com`;
            const urlUsuarioAndSenha = `username=${emailParaLogin}&password=${senha}&grant_type=password`;
            localStorage.setItem('@HE-emailParaLogin', emailParaLogin);
            localStorage.setItem('@HE-senhalUsuariLogin', senha);

            setLoading(true);
            const response = await Api.postAutenticacaoApp(urlUsuarioAndSenha);
            setLoading(false);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                if(res.ativo != true){
                    isUsuarioAutenticado(false);
                    setNumStatusResposta(ActionTypes.FALHA_NA_AUTENTICACAO);
                    showAlert();
                    return;
                }

                isUsuarioAutenticado(true);

                primeiroNomeUsuarioLogadoAsyncStorage(res.nome);
                const result = await gerenciamentoListaDeUaQueUsuarioLogadoTemAcesso(res);

                if (result <= 0) {
                    setOpenModalConfimacao(true);
                    return;
                }

                const existeNotificacoes = await verificarExisteNotificacoes();
                if (existeNotificacoes) isAtualisarNotificacao(true);

                armazenarDados(telSemMascara, senha);
                localStorage.setItem('@HE-access_token', res.access_token);
                localStorage.setItem('@HE-idUsuarioLogado', res.idUsuario);
                localStorage.setItem('@HE-NomeUsuarioLogado', res.nomeUsuario);

                acessarDashboard();
            } else if (response.numeroStatusResposta === ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS) {
                setNumStatusResposta(ActionTypes.FALHA_NA_AUTENTICACAO);
                showAlert();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            setLoading(false);
            console.log('Erro na classe SignIn metodo logarSistema', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const acessarDashboard = () => {
        history.push("/admin");
    }

    const armazenarDados = async (tel, senha) => {
        if (isManterConectado) {
            localStorage.setItem('@HE-telefoneUsuarioParaTelaDeLogin', tel);
            localStorage.setItem('@HE-senhaUsuarioParaTelaDeLogin', senha);
            localStorage.setItem('@HE-checkBoxParaTelaDeLogin', 'true');
        } else {
            localStorage.removeItem('@HE-telefoneUsuarioParaTelaDeLogin');
            localStorage.removeItem('@HE-senhaUsuarioParaTelaDeLogin');
            localStorage.removeItem('@HE-checkBoxParaTelaDeLogin');
        }
    }

    const changeCelular = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setTelefone(telComMascara);
    }

    const handleSenha = (e) => {
        const pass = e.target.value
        setSenha(pass.trim());
    }

    const guardarSenha = (e) => {
        const check = e.target.checked;
        setIsManterConectado(check);
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'10px'} left={'0px'} >
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    return (
        <DefaultAuth illustrationBackground={Ilustration} image={Ilustration}>

            {renderAlert()}
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection='column'>
                <Box me='auto'>
                    <Heading color={textColor} fontSize='36px' mb='10px'>Logar</Heading>
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "420px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}>
                    <FormControl isInvalid={true} >
                        <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                            Telefone<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            value={telefone}
                            onChange={changeCelular}
                            isInvalid={isValidTelefone}
                            errorBorderColor='crimson'
                            mask="(99)9999-9999"
                            id="telefone"
                            variant='auth'
                            ms={{ base: "0px", md: "0px" }}
                            placeholder='(99) 9999-9999'
                            mb='24px'
                            size='lg'
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                            borderColor={'gray.400'}
                            borderRadius={'10px'}
                        />
                        {isValidTelefone && <FormErrorMessage mt={'-25px'} mb={'20px'}>{mensageTelefone}</FormErrorMessage>}
                        <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
                            Senha<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <InputGroup size='md'>
                            <Input
                                onChange={handleSenha}
                                value={senha}
                                isInvalid={isValidSenha}
                                errorBorderColor='crimson'
                                placeholder='Min. 6 characteres'
                                mb='24px'
                                size='lg'
                                type={show ? "text" : "password"}
                                variant='auth'
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                borderColor={'gray.400'}
                                borderRadius={'10px'}
                            />
                            <InputRightElement display='flex' alignItems='center'>
                                <Icon
                                    color={textColorSecondary}
                                    _hover={{ cursor: "pointer" }}
                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                    onClick={handleClick}
                                />
                            </InputRightElement>
                        </InputGroup>
                        {isValidSenha && <FormErrorMessage mt={'-25px'} mb={'20px'}>{mensageSenha}</FormErrorMessage>}
                        {isAuthentication &&
                            <Box me='auto'>
                                <Text mb='36px' ms='4px' color={'red.500'} fontWeight='bold' fontSize='md'>Ops! Usuário ou senha inválido.</Text>
                            </Box>
                        }
                        <Flex justifyContent='space-between' align='center' mb='24px'>
                            <FormControl display='flex' alignItems='center'>
                                <Checkbox
                                    isChecked={isManterConectado}
                                    onChange={guardarSenha}
                                    id='remember-login'
                                    colorScheme='brandScheme'
                                    me='10px'
                                />
                                <FormLabel
                                    htmlFor='remember-login'
                                    mb='0'
                                    fontWeight='normal'
                                    color={textColor}
                                    fontSize='sm'>
                                    Mantenha-me conectado
                                </FormLabel>
                            </FormControl>
                            <NavLink to='/auth/redefinir-senha'>
                                <Text color={textColorBrand} fontSize='sm' w='124px' fontWeight='500'>Esqueceu a senha?</Text>
                            </NavLink>
                        </Flex>
                        <Button
                            onClick={() => { logarSistema() }}
                            type="submit"
                            fontSize='15px'
                            variant='brand'
                            fontWeight='500'
                            w='100%'
                            h='50'
                            mb='24px'>
                            Logar
                        </Button>
                    </FormControl>
                </Flex>
            </Flex>
            <Loader isOpen={loading} />
            <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { setOpenModalConfimacao(false) }} descricao={'Usuário Sem acesso as propriedades'} />
        </DefaultAuth>
    );
}


