import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Text,
    Box,
    Flex,
    Input,
    useColorModeValue,
} from "@chakra-ui/react";
import moment from 'moment';

function ModalDialogDesativarSeringueiro({ isOpen, onPressCancel, onConfirm, titulo = 'OBS', valueInput = '', nomeSeringueiro = '' }) {

    const [inputValue, setInputValue] = useState(valueInput);
    const [isValidInput, setIsValidInput] = useState(false);
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const dataAtual = moment().format('YYYY-MM-DD');
    const dtInicioSangria = localStorage.getItem('@HE-dtInicioSANGRIAsync');

    const handleConfirm = async () => {
        const isValid = await validarData(inputValue);
        if (!isValid) {
            setIsValidInput(true);
        } else {
            setIsValidInput(false);
            if (onConfirm) {
                onConfirm(inputValue); // Envia o valor para o pai
            }
        }
    };

    const changeInput = async (val) => {
        setInputValue(val);
        await validarData(val);
    };

    const validarData = async (val) => {
        let result = false;
        const isValidDate = !isNaN(new Date(val).getTime()); // VERIFICA SE A DATA E VALIDA

        if (isValidDate) {
            const val2 = moment(val);
            const initSangria = moment(dtInicioSangria);
            const dtAtual = moment(dataAtual);

            const dentroDoPeriodo = val2.isBetween(initSangria, dtAtual, undefined, "[]"); // '[]' inclui os limites

            if (dentroDoPeriodo) {
                setIsValidInput(false);
                result = true;
            } else {
                setIsValidInput(true);
                result = false;
            }
        } else {
            setIsValidInput(true);
            console.log('data invalida');
            result = false;
        }
        return result;
    }

    const GRADIENTE = 'linear-gradient(to bottom, #d9d9d9, #ffffff, #d9d9d9)';

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent bgGradient={GRADIENTE}>
                <ModalHeader justifyContent={'center'} alignItems={'center'} py={'10px'}>
                    <Flex py={'10px'} justify={'center'} align={'center'}>
                        <Text color={textColor} fontSize={'20px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{titulo}</Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Flex flexDirection={'column'} h={'90px'} justify={'start'} align={'center'}>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Box mb={'5px'} w={'100%'} >
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' mb={'3px'} align={'center'}>{'Data da desativação'}</Text>
                            </Box>
                            <Input
                                value={inputValue}
                                variant='filled'
                                onChange={(e) => changeInput(e.target.value)}
                                isInvalid={isValidInput}
                                errorBorderColor='crimson'
                                placeholder=""
                                bg={'white'}
                                color={textColor}
                                borderColor={textColor}
                                borderRadius="10px"
                                size="md"
                                type="date"
                                min={dtInicioSangria}
                                max={dataAtual}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                w={'200px'}
                                h={'35px'}
                            />
                        </Flex>
                        {isValidInput && <Text color={'red'} fontSize={'14px'} fontWeight={'light'}>{'Data inválida'}</Text>}
                    </Flex>
                    <Flex justify='center' align='center'>
                        <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Seringueiro:'}</Text>
                        <Text color={'orange.700'} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} ml={'5px'}>{nomeSeringueiro}</Text>
                    </Flex>
                </ModalBody>
                <ModalFooter mt={'20px'} borderTopWidth={'1px'} borderColor={'blackAlpha.700'} justifyContent={'space-around'}>
                    <Button w={'100px'} variant="action" onClick={onPressCancel}>Cancelar</Button>
                    <Button w={'100px'} variant="miniBrand" onClick={handleConfirm}>Desativar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalDialogDesativarSeringueiro;