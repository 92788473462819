import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    SimpleGrid,
    useColorModeValue,
    Select,
    Wrap,
    WrapItem,
    Text,
    Button,
    Center,
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO, RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA } from '../../../../util/CalculoRelatorioSangriaAndProducao';
import { ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO } from '../../../../util/FuncoesUteis';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import MessageSreen from 'components/message/MessageSreen';

export default function IndicadorDeProducao() {

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listSeringueiro, setListSeringueiro] = useState([]);
    const [pessoaSelecionado, setPessoaSelecionado] = useState('');
    const [totalPrevisto, setTotalPrevisto] = useState(0);
    const [totalRealizado, setTotalRealizado] = useState(0);
    const [saldoAcumulado, setSaldoAcumulado] = useState(0);
    const [porcentagemRealizada, setPorcentagemRealizada] = useState(0);
    const [producaoCoagulosPorArvorePrevista, setProducaoCoagulosPorArvorePrevista] = useState(0);
    const [producaoCoagulosPorArvoreRealizada, setProducaoCoagulosPorArvoreRealizada] = useState(0);
    const [totalPrevistoPessoa, setTotalPrevistoPessoa] = useState(0);
    const [totalRealizadoPessoa, setTotalRealizadoPessoa] = useState(0);
    const [saldoAcumuladoPessoa, setSaldoAcumuladoPessoa] = useState(0);
    const [porcentagemRealizadaPessoa, setPorcentagemRealizadaPessoa] = useState(0);
    const [producaoCoagulosPorArvorePrevistaPessoa, setProducaoCoagulosPorArvorePrevistaPessoa] = useState(0);
    const [producaoCoagulosPorArvoreRealizadaPessoa, setProducaoCoagulosPorArvoreRealizadaPessoa] = useState(0);

    const [arrayMesInicio, setArrayMesInicio] = useState([]);
    const [arrayMesFim, setArrayMesFim] = useState([]);
    const [mesInicioSelecionado, setMesInicioSelecionado] = useState('');
    const [mesFimSelecionado, setMesFimSelecionado] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [corSaldoAcumulado, setCorSaldoAcumulado] = useState('green.500');
    const [corSaldoAcumuladoPessoa, setCorSaldoAcumuladoPessoa] = useState('green.500');

    const [arrayMesesAndData, setArrayMesesAndData] = useState([]);
    const [idUa, setIdUa] = useState(null);
    const [idSafra, setIdSafra] = useState(null);
    const [nomePessoa, setNomePessoa] = useState('Todos');
    const [showValorSeringuerio, setShowValorSeringuerio] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            try {
                const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
                const fim = localStorage.getItem('@HE-dtFimSafraAsync');
                const arrayDatas = await ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO(ini, fim);

                setArrayMesInicio(arrayDatas);
                setArrayMesFim(arrayDatas);

                const initMes = moment(ini).startOf('month').format('YYYY-MM-DD');
                const endDate = moment(fim).startOf('month').format('YYYY-MM-DD');      // NESSE CASO OS DOIS SAO startOf

                setMesInicioSelecionado(initMes);
                setMesFimSelecionado(endDate);

                const ARRAY_MES = await RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA(ini, fim);
                setArrayMesesAndData(ARRAY_MES);
                const dtInicioSafra = moment(ini).startOf('month').format('YYYY-MM-DD');
                const dtFimSafra = moment(fim).endOf('month').format('YYYY-MM-DD');

                await pesquisarFazenda(dtInicioSafra, dtFimSafra, ARRAY_MES);
                setTelaCarregada(true);

            } catch (error) {
                setTelaCarregada(true);
            }
        }
        loaderScreen();
    }, [])

    const pesquisarFazenda = async (dtInicioP, dtFimP, ARRAY_MES) => {
        try {
            setTelaCarregada(false);
            const dtI = moment(dtInicioP).format('YYYY-MM-DD');
            const dtF = moment(dtFimP).format('YYYY-MM-DD');
            const idUa = localStorage.getItem('@HE-idUa');
            const idSafraP = localStorage.getItem('@HE-idSafra');
            setIdUa(idUa);
            setIdSafra(idSafraP);
            const json = {
                idUa: idUa,
                idSafra: idSafraP,
                dtInicio: dtI,
                dtFim: dtF,
                idPessoa: 0,
                arrayMesDataFilter: ARRAY_MES
            }

            const response = await Api.postRequest('producao/acompanhamentoProducaoFazenda', json);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;
                const listSeringueiros = res.listSeringueiros;
                const listRelatorioFazenda = res.listRelatorioFazenda;

                setNomePessoa('Todos');
                await carregaDadosFazenda(listRelatorioFazenda[0], false, 'Todos');
                if (listSeringueiros.length > 0) {
                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            listSeringueiros.map(function (item) {
                                let dtDeletado = '';
                                let bgColor = 'white';
                                if (item.dtDeletado != null) {
                                    bgColor = '#ffe6e6';
                                    dtDeletado = ` - desativado ${moment(item.dtDeletado).format('MM/YY')}`
                                }
                                const obj = {
                                    label: `${item.nome} ${dtDeletado}`,
                                    value: `${item.idPessoa}`,
                                    color: bgColor
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListSeringueiro(arrayPess);
                }
                setTelaCarregada(true);
            } else {
                setTelaCarregada(true);
            }
        } catch (error) {
            setTelaCarregada(true);
            console.log('Erro na classe AcompanhamentoProducaoCustom metodo pesquisar', error);
        }
    }

    const pesquisarFazendaAndPessoa = async () => {
        try {
            const isValidarMesInicioAndFim = await validarDatas();
            if (!isValidarMesInicioAndFim) return;

            const arrayPess = await montarArrayComDataInicioAndFim();

            const primeiroDiaDoMes = moment(mesInicioSelecionado).startOf('month').format('YYYY-MM-DD');
            const UltimoDiaDoMes = moment(mesFimSelecionado).endOf('month').format('YYYY-MM-DD');

            let idPessoaP = 0;

            if (pessoaSelecionado != '') {
                idPessoaP = pessoaSelecionado;
                setShowValorSeringuerio(true);
            } else {
                setShowValorSeringuerio(false);
            }

            const json = {
                idUa: idUa,
                idSafra: idSafra,
                dtInicio: primeiroDiaDoMes,
                dtFim: UltimoDiaDoMes,
                idPessoa: idPessoaP,
                arrayMesDataFilter: arrayPess
            }
            const response = await Api.postRequest('producao/acompanhamentoProducaoFazendaAndPessoa', json);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;
                const listRelatorioFazenda = res.fazenda.listRelatorioFazenda[0];

                const prod4 = res.porcentagemRealizado
                const porcentagemRealizadoP = prod4 != 0 ? prod4.toFixed(0) : 0;

                const prod5 = res.producaoCoagulosPorArvorePrevista
                let producaoCoagulosPorArvorePrevistaP = prod5 != 0 ? prod5.toFixed(2) : '0';
                producaoCoagulosPorArvorePrevistaP = producaoCoagulosPorArvorePrevistaP.replace('.', ',');

                const prod6 = res.producaoCoagulosPorArvoreRealizada
                let producaoCoagulosPorArvoreRealizadaP = prod6 != 0 ? prod6.toFixed(2) : '0';
                producaoCoagulosPorArvoreRealizadaP = producaoCoagulosPorArvoreRealizadaP.replace('.', ',');

                setTotalPrevistoPessoa(res.totalPrevistoString);
                setTotalRealizadoPessoa(res.totalRealizadoString);
                setSaldoAcumuladoPessoa(res.saldoAcumulado);
                setPorcentagemRealizadaPessoa(porcentagemRealizadoP);
                setProducaoCoagulosPorArvorePrevistaPessoa(producaoCoagulosPorArvorePrevistaP);
                setProducaoCoagulosPorArvoreRealizadaPessoa(producaoCoagulosPorArvoreRealizadaP);

                await carregaDadosFazenda(listRelatorioFazenda, false, 'Todos');

                setTelaCarregada(true);
            } else {
                setTelaCarregada(true);
            }
        } catch (error) {
            setTelaCarregada(true);
            console.log('Erro na classe AcompanhamentoProducaoCustom metodo pesquisar', error);
        }
    }

    const validarDatas = async () => {
        let result = true;

        const primeiroDiaDoMes = moment(mesInicioSelecionado).startOf('month');
        const UltimoDiaDoMes = moment(mesFimSelecionado).endOf('month');

        const duration = moment.duration(UltimoDiaDoMes.diff(primeiroDiaDoMes));
        const resultado = duration.asMonths();
        const meses = parseFloat(resultado.toFixed(0));

        if (meses < 1) {
            setNumStatusResposta(ActionTypes.DATA_INICIO_MAIOR_QUE_DATA_FIM);
            showAlert();
            result = false;
        } else {
            setIsVisibleAlert(false);
        }
        return result;
    }

    const montarArrayComDataInicioAndFim = async () => {

        const primeiroDiaDoMes = moment(mesInicioSelecionado).startOf('month');
        const UltimoDiaDoMes = moment(mesFimSelecionado).endOf('month');

        let startDate = moment(primeiroDiaDoMes);
        let endDate = moment(UltimoDiaDoMes);
        let date = [];

        for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'month')) {
            date.push(m.format('YYYY-MM-DD'));
        }

        let arrayPess = [];
        await new Promise(resolve => {
            resolve(
                arrayMesesAndData.map((item, index) => {
                    let statusP = false;
                    if (index >= 0) {
                        const arrFilter = date.filter(x => x == item.dtMes);
                        statusP = arrFilter.length > 0 ? true : false;
                    }
                    const obj = {
                        dtMes: item.dtMes,
                        mes: item.mes,
                        status: statusP,
                    }
                    arrayPess.push(obj);
                })
            )
        });
        return arrayPess;
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const carregaDadosFazenda = async (arraySeringueirosComMeses, seringueiroSelecionadoP = false) => {

        const res = await CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO(arraySeringueirosComMeses, seringueiroSelecionadoP);

        setTotalRealizado(res.totalRealizado);
        setTotalPrevisto(res.totalPrevisto);
        setSaldoAcumulado(res.saldoAcumulado);
        setPorcentagemRealizada(res.porcentagemRealizado);
        setProducaoCoagulosPorArvorePrevista(res.producaoCoagulosPorArvorePrevista);
        setProducaoCoagulosPorArvoreRealizada(res.producaoCoagulosPorArvoreRealizada);

        const cor = await selecionarCorSaldoAcumulado(res);
        setCorSaldoAcumulado(cor);

        if (!seringueiroSelecionadoP) {

            const corGeal = await selecionarCorSaldoAcumulado(res);
            setCorSaldoAcumuladoPessoa(corGeal);
        }
    }

    const selecionarCorSaldoAcumulado = async (res) => {
        let result = 'green.500';

        const xx = `${res.totalRealizado}`;
        const realizado = xx.replace(/[^\d]+/g, '');
        const yy = `${res.totalPrevisto}`;
        const previsto = yy.replace(/[^\d]+/g, '');

        if (Number(realizado) > Number(previsto)) {
            result = 'green.500';
        } else if (Number(realizado) < Number(previsto)) {
            result = 'red.600';
        } else {
            result = 'orange.500';
        }
        return result;
    }

    function renderMeses() {
        return (
            <Wrap spacing='10px' mb={'15px'} justify='flex-start'>
                <WrapItem>
                    <Box w='100%'>
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Início</Text>
                        <Select
                            value={mesInicioSelecionado}
                            errorBorderColor='crimson'
                            placeholder=""
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={e => setMesInicioSelecionado(e.target.value)}
                            w={'200px'}
                            h={'35px'}
                        >
                            {arrayMesInicio.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' >
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Fim</Text>
                        <Select
                            value={mesFimSelecionado}
                            errorBorderColor='crimson'
                            placeholder=""
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={e => setMesFimSelecionado(e.target.value)}
                            w={'200px'}
                            h={'35px'}
                        >
                            {arrayMesFim.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box h='100%'>
                        <Center h={'100%'} p={'5px'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                            <TooltipIcon
                                label={
                                    <Box px={'0px'} py={'5px'} >
                                        <Text align={'center'}>{'Campos de Início e Fim'}</Text>
                                        <Text mt={'5px'} lineHeight='100%' align={'start'}>{'Pesquisa'}</Text>
                                        <Text mt={'0px'} lineHeight='100%' align={'start'} pl={'10px'}>{'Para pesquisar somente os dados da propriedade selecione início e fim e clique em pesquisar.'}</Text>
                                        <Text mt={'10px'} lineHeight='100%' align={'start'} pl={'10px'}>{'Selecione um seringueiro e clique em pesquisar para adicionar na pesquisa.'}</Text>
                                    </Box>
                                }
                                height={'23px'} />
                        </Center>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' h={'100%'}>
                        <Center alignItems={'flex-end'} h={'100%'}>
                            <Button variant="brand" onClick={pesquisarFazendaAndPessoa}>Pesquisar</Button>
                        </Center>
                    </Box>
                </WrapItem>
            </Wrap>
        )
    }

    function renderSelect() {
        const bgCombo = pessoaSelecionado != '' ? 'gray.300' : 'white';
        return (
            <Box w={'100%'} py={'10px'}>
                <Flex flexDirection={'column'} justify='center' align='start'>
                    <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Seringueiros</Text>
                    <Select
                        value={pessoaSelecionado}
                        errorBorderColor='crimson'
                        placeholder="Todos"
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        onChange={e => setPessoaSelecionado(e.target.value)}
                        bg={bgCombo}
                        _focus={{ background: bgCombo }}
                        w={'400px'}
                        h={'35px'}>
                        {listSeringueiro.map((item, index) => {
                            return (
                                <option key={index} style={{ background: item.color }} value={item.value} >{item.label}</option>
                            )
                        })}
                    </Select>
                </Flex>
            </Box>
        )
    }

    function renderCardsQualidade() {
        return (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }} gap='20px' mb='20px' mt={'15px'} px={'3'}>

                <CriarCard 
                    titulo={'Produção de Coágulos Prevista (kg)'} 
                    valorFazenda={totalPrevisto} 
                    valorSeringueiro={totalPrevistoPessoa} 
                    showValorSeringuerio={showValorSeringuerio} 
                    corValorFazenda={'orange.500'} 
                    corValorPessoa={'orange.500'} />

                <CriarCard 
                    titulo={'Produção de Coágulos Realizada (kg)'} 
                    valorFazenda={totalRealizado} 
                    valorSeringueiro={totalRealizadoPessoa} 
                    showValorSeringuerio={showValorSeringuerio} 
                    corValorFazenda={'green.500'} 
                    corValorPessoa={'green.500'} />

                <CriarCard 
                    titulo={'Saldo Acumulado (kg)'} 
                    valorFazenda={saldoAcumulado} 
                    valorSeringueiro={saldoAcumuladoPessoa} 
                    showValorSeringuerio={showValorSeringuerio} 
                    corValorFazenda={corSaldoAcumulado} 
                    corValorPessoa={corSaldoAcumuladoPessoa} />

                <CriarCard 
                    titulo={'Porcentagem Realizado'} 
                    valorFazenda={`${porcentagemRealizada}%`} 
                    valorSeringueiro={`${porcentagemRealizadaPessoa}%`} 
                    showValorSeringuerio={showValorSeringuerio} 
                    corValorFazenda={'green.500'} 
                    corValorPessoa={'green.500'} />

                <CriarCard 
                    titulo={'Produção de Coágulos Por Árvore Prevista (kg)'} 
                    valorFazenda={producaoCoagulosPorArvorePrevista} 
                    valorSeringueiro={producaoCoagulosPorArvorePrevistaPessoa} 
                    showValorSeringuerio={showValorSeringuerio} 
                    corValorFazenda={'orange.500'} 
                    corValorPessoa={'orange.500'} />

                <CriarCard 
                    titulo={'Produção de Coágulos Por Árvore Realizada (kg)'} 
                    valorFazenda={producaoCoagulosPorArvoreRealizada} 
                    valorSeringueiro={producaoCoagulosPorArvoreRealizadaPessoa} 
                    showValorSeringuerio={showValorSeringuerio} 
                    corValorFazenda={'green.500'} 
                    corValorPessoa={'green.500'} />

            </SimpleGrid>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'} zIndex={1}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' justify='center' align='center' py={'1'} >
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Card py='0px' px='10px' borderRadius={'10px'}>
            {renderAlert()}
            {renderMeses()}
            {renderSelect()}
            {renderCardsQualidade()}
        </Card>
    );
}

function CriarCard({ titulo, valorFazenda, valorSeringueiro, showValorSeringuerio, corValorFazenda, corValorPessoa }) {
    const fontS = '13px';
    const bgBackGroud = 'white';
    return (
        <Card bg={bgBackGroud} py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
            <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                <Flex h={'100%'} flexDirection={'column'} my='auto' >
                    <Text lineHeight='100%' color={'secondaryGray.900'} fontSize={{ base: fontS, }} fontWeight={'bold'}>{titulo}</Text>
                    <Text lineHeight='100%' color={corValorFazenda} fontSize={'18px'} fontWeight={'bold'} mt={'5px'}>{valorFazenda}</Text>
                </Flex>
            </Flex>
            {showValorSeringuerio &&
                <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }} borderWidth={'1px'} borderRadius={'8px'} borderColor={'blackAlpha.500'} bg={'gray.300'}>
                    <Flex h={'100%'} flexDirection={'column'} my='auto' justify={'center'} align={'center'}>
                        <Text lineHeight='100%' color={corValorPessoa} fontSize={'18px'} fontWeight={'bold'} mt={'5px'}>{valorSeringueiro}</Text>
                    </Flex>
                </Flex>
            }
        </Card>
    )
}