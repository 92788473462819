import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Text,
    useColorModeValue,
    Button,
    Box,
    Select,
    SimpleGrid,
    Center,
    TableContainer,
    Table,
    Tbody,
    Thead,
    Tr,
    Th,
    Td,
    Wrap,
    WrapItem,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
//import TooltipIcon from 'components/tooltip/TooltipIcon';
import moment from 'moment';
import { QRCodeSVG } from 'qrcode.react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Canvg } from 'canvg';
import { truncarAndFormatarNomeSeringueiro } from '../../../util/FuncoesUteis';

const IMG_LOGO = require('assets/img/layout/logo_21.png');

export default function QrCodeGenerateAdmin() {

    const svgRefs = useRef([]);
    const svgRefsParaTodos = useRef([]);
    const history = useHistory();
    let count = 0;

    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [idTipoUsuarioLogado, setIdTipoUsuarioLogado] = useState(null);
    const [listUa, setListUa] = useState([]);
    const [listUaCompleto, setListUaCompleto] = useState([]);
    const [uaSelecionado, setUaSelecionado] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [safraSelecionado, setSafraSelecionado] = useState('');
    const [listaSafraCombo, setListaSafraCombo] = useState([]);

    const [dtInicioSafra, setDtInicioSafra] = useState('');
    const [dtFimSafra, setDtFimSafra] = useState('');
    const [dtInicioSangria, setDtInicioSangria] = useState('');
    const [dtFimSangria, setDtFimSangria] = useState('');
    const [nomeSafraP, setnomeSafraP] = useState('');
    const [safraVigenteP, setSafraVigenteP] = useState(null);
    const [listaPessoaBasic, setListaPessoaBasic] = useState([]);
    const [listaQRCParaTodos, setListaQRCParaTodos] = useState([]);
    const [sangriaAtivaP, setSangriaAtivaP] = useState(null);
    const [objetoQrCode, setObjetoQrCode] = useState(null);
    const [programacaoEmAndamento, setProgramacaoEmAndamento] = useState(false);
    const [nomeUaSelecionada, setNomeUaSelecionada] = useState('');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const cardShadow = useColorModeValue('0px 0px 5px rgba(0, 0, 0, 0.5)', 'unset');
    const textColorHeader = useColorModeValue("white", "white");
    const colorFooterAndHeader = useColorModeValue("rgba(204, 90, 0, 0.9)", "black");
    const shadowBlack = ('0px 2px 5px 2px rgba(0, 0, 0, 0.30)');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            //const idUaAtivaNoSistemaX = localStorage.getItem('@HE-idUa');
            //setIdUaAtivaNoSistema(idUaAtivaNoSistemaX);
            await pesquisarPropriedades();
        }
        loaderScreen();
    }, [])

    const pesquisarPropriedades = async () => {

        setUaSelecionado('');
        const idTipoUserioLogado = localStorage.getItem('@HE-idTipoUsuarioLogado');
        setIdTipoUsuarioLogado(idTipoUserioLogado);

        const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');

        const response = await Api.getRequest(`api/ua/retornaPropriedadesAndSafrasQueUsuarioTemAcesso?idUsuario=${idUsuarioLogado}`);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = await response.respostaRequisicao.data;
            setListUaCompleto(res);

            if (res.length > 0) {
                let arrayCombo = null;
                await new Promise(resolve => {
                    resolve(
                        arrayCombo = res.map(function (item) {
                            return {
                                value: `${item.idUa}`,
                                label: item.nomeFazenda
                            }
                        })
                    )
                });
                setListUa(arrayCombo);
            }
            setTelaCarregada(true);
        } else {
            setTelaCarregada(true);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const changePropriedade = async (e) => {
        const idUa = e;

        await limpaCampos();
        setUaSelecionado(idUa);
        setListaSafraCombo([]);
        setObjetoQrCode(null);

        if (idUa != '') {
            const xx = listUa.filter(x => x.value == idUa);
            const nomeUa = xx.length > 0 ? xx[0].label : '';
            setNomeUaSelecionada(nomeUa);
            const arrUa = listUaCompleto.filter(x => x.idUa == idUa);
            const listaSafraItem = arrUa[0].listaSafraItem;

            if (listaSafraItem.length > 0) {
                let arrayCombo = null;
                await new Promise(resolve => {
                    resolve(
                        arrayCombo = listaSafraItem.map(function (item) {
                            return {
                                value: `${item.idSafra}`,
                                label: item.nomeSafra
                            }
                        })
                    )
                });
                setListaSafraCombo(arrayCombo);
            }
        }
    }

    const changeSafra = async (e) => {
        try {
            const idSafra = e;
            setObjetoQrCode(null);
            setSafraSelecionado(idSafra);

            if (idSafra != '') {
                const url = `safra/retornaSafraAndSeringueirosAndGerentes?idTipoUsuario=${idTipoUsuarioLogado}&idUa=${uaSelecionado}&idSafra=${idSafra}&deletado=false`;

                //console.log('---', url)


                const response = await Api.getRequest(url);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const objSafra = await response.respostaRequisicao.data;

                    const nomeSafr = objSafra.nomeSafra;
                    const vigenteX = objSafra.vigente != null ? objSafra.vigente : false;
                    const dtInicioSafraX = objSafra.dtInicio !== null && objSafra.dtInicio !== '' ? moment(objSafra.dtInicio).format('DD/MM/YYYY') : '';
                    const dtFimSafraX = objSafra.dtFim !== null && objSafra.dtFim !== '' ? moment(objSafra.dtFim).format('DD/MM/YYYY') : '';
                    const dtInicioSangriaX = objSafra.dtInicioSangria !== null && objSafra.dtInicioSangria !== '' ? moment(objSafra.dtInicioSangria).format('DD/MM/YYYY') : '';
                    const dtFimSangriaX = objSafra.dtFimSangria !== null && objSafra.dtFimSangria !== '' ? moment(objSafra.dtFimSangria).format('DD/MM/YYYY') : '';
                    const sangriaAtiv = objSafra.sangriaAtiva != null ? objSafra.sangriaAtiva : false;
                    const programacaoEmAndamentoX = objSafra.programacaoEmAndamento != null ? objSafra.programacaoEmAndamento : false;

                    setnomeSafraP(nomeSafr);
                    setSafraVigenteP(vigenteX);
                    setDtInicioSafra(dtInicioSafraX);
                    setDtFimSafra(dtFimSafraX);
                    setDtInicioSangria(dtInicioSangriaX);
                    setDtFimSangria(dtFimSangriaX);
                    setSangriaAtivaP(sangriaAtiv);
                    setListaPessoaBasic(objSafra.listaPessoaBasic);

                    setProgramacaoEmAndamento(programacaoEmAndamentoX);
                }
            } else {
                limpaCampos();
            }
        } catch (error) {
            console.log('Erro na classe QrCodeGenerateAdmin metodo changeSafra', error);
        }
    }

    const limpaCampos = async () => {
        setListaPessoaBasic([]);
        setListaSafraCombo([]);
        setListaQRCParaTodos([]);
        setObjetoQrCode(null);
        setUaSelecionado('');
        setNomeUaSelecionada('');
        setnomeSafraP('');
        setSafraVigenteP(null);
        setDtInicioSafra('');
        setDtFimSafra('');
        setDtInicioSangria('');
        setDtFimSangria('');
        setSangriaAtivaP(null);
        setSafraSelecionado('');
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const changeGerarQrCode = async (item) => {


        console.log('----', item);

        if (item != null) {
            setListaQRCParaTodos([]);
            setObjetoQrCode(item);
        } else {
            setObjetoQrCode(null);
        }
    }

    const changeGerarQrCodeParaTodos = async () => {
        if (listaPessoaBasic.length > 0) {
            setObjetoQrCode(null);
            setListaQRCParaTodos(listaPessoaBasic);
        } else {
            setListaQRCParaTodos([]);
        }
    }







    // const svgToPngWithCanvg = async (svgElement) => {
    //     try {
    //         // Cria o canvas
    //         const canvas = document.createElement('canvas');
    //         canvas.width = svgElement.clientWidth || 256; // Defina o tamanho conforme necessário
    //         canvas.height = svgElement.clientHeight || 256;

    //         // Inicializa o contexto 2D do canvas
    //         const context = canvas.getContext('2d');

    //         // Serializa o SVG para string
    //         const svgString = new XMLSerializer().serializeToString(svgElement);

    //         // Usa o canvg para renderizar o SVG no canvas
    //         const canvgInstance = await Canvg.fromString(context, svgString);
    //         await canvgInstance.render();

    //         // Converte o canvas para PNG base64
    //         const pngDataUrl = canvas.toDataURL('image/png');
    //         return pngDataUrl;
    //     } catch (error) {
    //         console.error("Erro ao converter SVG para PNG:", error);
    //         throw error;
    //     }
    // };


    // const svgToPngWithPosition = async (svgElement, position = 'center') => {
    //     try {
    //         const canvas = document.createElement('canvas');
    //         canvas.width = svgElement.clientWidth || 256;
    //         canvas.height = svgElement.clientHeight || 256;
    //         const context = canvas.getContext('2d');

    //         const svgString = new XMLSerializer().serializeToString(svgElement);
    //         const canvgInstance = await Canvg.fromString(context, svgString);

    //         // Define a posição com base no argumento
    //         let x = 0;
    //         let y = 0;

    //         if (position === 'center') {
    //             x = (canvas.width - svgElement.clientWidth) / 2;
    //             y = (canvas.height - svgElement.clientHeight) / 2;
    //         } else if (position === 'bottom-right') {
    //             x = canvas.width - svgElement.clientWidth;
    //             y = canvas.height - svgElement.clientHeight;
    //         }

    //         context.clearRect(0, 0, canvas.width, canvas.height); // Limpa o canvas
    //         context.translate(25, 25); // Posiciona a imagem no local desejado
    //         //context.translate(x, y); // Posiciona a imagem no local desejado
    //         await canvgInstance.render();

    //         return canvas.toDataURL('image/png');
    //     } catch (error) {
    //         console.error("Erro ao converter SVG para PNG:", error);
    //         throw error;
    //     }
    // };


    async function downloadTesteeeeeeeeeeeeeeeeeeee() {
        try {
            const zip = new JSZip();
            const array = [1];

            for (let i = 0; i < array.length; i++) {
                //const element = array[i];
                
                
                const pngData = await downloadQRCodeeeeeeeeeeeeeeee(i);
                zip.file(`ressss/QRCode_${i}.png`, pngData.split(',')[1], { base64: true });
                
            }

            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, `aa_QRCodes.zip`);
            });

        } catch (error) {
            console.log('errooooooooooo', error);
        }
    }
    const downloadQRCodeeeeeeeeeeeeeeee = async (index) => {

        const nomePessoaX = `Seringueiro: fulano`;
        const letraTarefa = `Tarefa: AA`;
        const nomeSafraX = `Safra: ${nomeSafraP}`;

        const svg = svgRefsParaTodos.current[index].querySelector('svg');
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        const qrSize = 500; // tamanho do QR Code
        const margin = 60;
        const textPadding = 30;
        const fontSize = 20; // Tamanho da fonte 16px
        const totalHeight = qrSize + 2 * margin + textPadding + fontSize * 2;

        canvas.width = qrSize + 2 * margin;
        canvas.height = totalHeight;

        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = '#000000';
        ctx.font = `${fontSize}px Arial`;
        ctx.textAlign = 'center';

        ctx.fillText(nomeSafraX, canvas.width / 2, margin);
        ctx.fillText(nomePessoaX, canvas.width / 2, margin + fontSize + 5);
        ctx.fillText(letraTarefa, canvas.width / 2, margin + fontSize * 2 + 10);

        const logoImg = new Image();
        logoImg.src = IMG_LOGO;
        const qrYPosition = canvas.height - qrSize - margin; // Move o QR Code para o final do canvas
        ctx.drawImage(img, margin, qrYPosition, qrSize, qrSize);

        //const svgString = new XMLSerializer().serializeToString(svgElement);
        const canvgInstance = await Canvg.fromString(ctx, svgData);

        await canvgInstance.render();
        return canvas.toDataURL('image/png');

        // img.onload = async function () {
        //     const logoImg = new Image();
        //     logoImg.src = IMG_LOGO;
        //     const qrYPosition = canvas.height - qrSize - margin; // Move o QR Code para o final do canvas
        //     ctx.drawImage(img, margin, qrYPosition, qrSize, qrSize);

        //     logoImg.onload = async function () {
        //         const logoSize = 100;
        //         const logoX = (canvas.width - logoSize) / 2;
        //         const logoY = qrYPosition + (qrSize - logoSize) / 2;
        //         ctx.drawImage(logoImg, logoX, logoY, logoSize, logoSize);
        //         const pngFile = canvas.toDataURL('image/png');
        //         // const downloadLink = document.createElement('a');
        //         // downloadLink.href = pngFile;
        //         // downloadLink.download = `qrcode_${index + 1}.png`;
        //         // document.body.appendChild(downloadLink);
        //         // downloadLink.click();
        //         // document.body.removeChild(downloadLink);
        //     };
        // };
        // img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    };






    async function downloadMultipleQRCodesParaTodos() {             // =================== TODOS ==================
        try {
            const zip = new JSZip();
            let xx = 0;

            for (let i = 0; i < listaQRCParaTodos.length; i++) {
                const pessoa = listaQRCParaTodos[i];

                const aa = pessoa.nomePessoa;
                const max = aa.length < 25 ? aa.length : 25;
                const bb = aa.substring(0, max);
                const nmPessoa = bb.replaceAll(" ", "_");

                for (let j = 0; j < pessoa.listaTarefa.length; j++) {
                    const item = pessoa.listaTarefa[j];
                    const svgElement = svgRefsParaTodos.current[xx];

                    if (svgElement) {

                        // Converte SVG para PNG
                        const pngData = await svgToPngWithCustomization(svgElement);

                        // Adiciona o PNG ao ZIP
                        zip.file(`${nmPessoa}_${pessoa.idPessoa}/QRCode_${item.letraTarefa}.png`, pngData.split(',')[1], { base64: true });
                        //zip.file(`${nmPessoa}_${pessoa.idPessoa}/QRCode_${item.letraTarefa}.jpg`, jpegData.split(',')[1], { base64: true });

                    } else {
                        console.log(`Elemento SVG para QR Code ${item.idTarefa} não encontrado.`);
                    }
                    xx++;
                }
            }
            const dd = nomeUaSelecionada != '' ? nomeUaSelecionada : 'Fazenda';
            const max1 = dd.length < 15 ? dd.length : 15;
            const ee = dd.substring(0, max1);
            const nmFazenda = ee.replaceAll(" ", "_");

            // Gera o arquivo ZIP e inicia o download
            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, `${nmFazenda}_QRCodes.zip`);
            });

        } catch (error) {
            console.log('Erro na classe QrCodeGenerateAdmin metodo downloadMultipleQRCodesParaTodos', error);
        }
    };

























    const downloadQRCodesParaTodosSVG = async () => {           
        const zip = new JSZip();
        let xx = 0;

        for (let i = 0; i < listaQRCParaTodos.length; i++) {
            const pessoa = listaQRCParaTodos[i];

            const aa = pessoa.nomePessoa;
            const max = aa.length < 25 ? aa.length : 25;
            const bb = aa.substring(0, max);
            const nmPessoa = bb.replaceAll(" ", "_");

            for (let j = 0; j < pessoa.listaTarefa.length; j++) {
                const item = pessoa.listaTarefa[j];
                const svgElement = svgRefsParaTodos.current[xx];

                // const svgClone = svgElement.cloneNode(true);
                // svgClone.setAttribute('width', 256);
                // svgClone.setAttribute('height', 256);
                // const backgroundRect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
                // backgroundRect.setAttribute('width', '100%');
                // backgroundRect.setAttribute('height', '100%');
                // backgroundRect.setAttribute('fill', '#ffffff');
                // svgClone.insertBefore(backgroundRect, svgClone.firstChild);
                // const svgData = new XMLSerializer().serializeToString(svgClone);
                // const base64Data2 = btoa(svgData);

                // Serializa o SVG para uma string
                const svgString = new XMLSerializer().serializeToString(svgElement);
                const base64Data = btoa(svgString);

                //const svgElement = svgRefs.current[index]; // Obtém a referência do SVG
                //const customizedSvgDataUrl = await svgToCustomizedSvg(svgElement, 256, 256);

                // Exemplo de uso para converter para PNG ou JPEG, usando a mesma lógica de conversão de antes:
                //const pngData = await svgToPng(customizedSvgDataUrl, 256, 256);
                //const pngData = await svgToPngWithCustomization(customizedSvgDataUrl);

                // Adiciona ao ZIP com uma estrutura de pastas, se necessário
                zip.file(`${nmPessoa}_${pessoa.idPessoa}/QRCode_${item.letraTarefa}.svg`, base64Data, { base64: true });
                //zip.file(`${nmPessoa}_${pessoa.idPessoa}/QRCode_${item.letraTarefa}.png`, pngData.split(',')[1], { base64: true });

                xx++; // Incrementa o contador para a próxima referência
            }
        }
        const dd = nomeUaSelecionada != '' ? nomeUaSelecionada : 'Fazenda';
        const max1 = dd.length < 15 ? dd.length : 15;
        const ee = dd.substring(0, max1);
        const nmFazenda = ee.replaceAll(" ", "_");

        // Gera o arquivo ZIP e inicia o download
        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, `${nmFazenda}_QRCodes.zip`);
        });
    };



    // async function svgToCustomizedSvg(svgElement, width, height) {
    //     const svgClone = svgElement.cloneNode(true);
    //     svgClone.setAttribute('width', width);
    //     svgClone.setAttribute('height', height);
    //     const backgroundRect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    //     backgroundRect.setAttribute('width', '100%');
    //     backgroundRect.setAttribute('height', '100%');
    //     backgroundRect.setAttribute('fill', '#ffffff');
    //     svgClone.insertBefore(backgroundRect, svgClone.firstChild);
    //     const svgData = new XMLSerializer().serializeToString(svgClone);
    //     return `data:image/svg+xml;base64,${btoa(svgData)}`;
    // }


















    const svgToPngWithCustomization = async (svgElement, options = {}) => {
        const {
            width = 500,           // Largura do canvas
            height = 500,          // Altura do canvas
            backgroundColor = '#ffffff', // Cor de fundo
            margin = 30,           // Margem em pixels
            position = 'center'    // Posição da imagem dentro do canvas
        } = options;

        const nomePessoaX = `Seringueiro: Fulano`;
        const letraTarefa = `Tarefa: A`;
        const nomeSafraX = `Safra: SSSS`;
        const textPadding = 30;
        const qrSize = 300; // tamanho do QR Code
        const fontSize = 20; // Tamanho da fonte 16px
        const totalHeight = qrSize + 2 * margin + textPadding + fontSize * 2;

        try {
            const canvas = document.createElement('canvas');
            canvas.width = qrSize + 2 * margin;
            canvas.height = totalHeight;
            const ctx = canvas.getContext('2d');

            // Configura a cor de fundo
            ctx.fillStyle = '#FFFFFF';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Adiciona o texto "nomeFazenda" e "nome" acima do QR Code
            ctx.fillStyle = '#000000';
            ctx.font = `${fontSize}px Arial`;
            ctx.textAlign = 'center';

            // Desenha o nome pessoa
            ctx.fillText(nomeSafraX, canvas.width / 2, margin);
            //ctx.fillText(nomePessoaX, canvas.width / 2, margin + fontSize);

            // Desenha a letra datarefa
            ctx.fillText(nomePessoaX, canvas.width / 2, margin + fontSize + 5);
            ctx.fillText(letraTarefa, canvas.width / 2, margin + fontSize * 2 + 10);

            // Converte o SVG para uma string e cria uma instância do Canvg
            const svgString = new XMLSerializer().serializeToString(svgElement);
            const canvgInstance = await Canvg.fromString(ctx, svgString);

            // Define a posição inicial com base na margem e na posição desejada
            let x = margin;
            let y = margin;

            if (position === 'center') {
                x = (canvas.width - svgElement.clientWidth) / 2;
                y = (canvas.height - svgElement.clientHeight) / 2;
            } else if (position === 'bottom-right') {
                x = canvas.width - svgElement.clientWidth - margin;
                y = canvas.height - svgElement.clientHeight - margin;
            }

            // Aplica a posição e renderiza o SVG no canvas
            ctx.translate(x, y);
            await canvgInstance.render();
            return canvas.toDataURL('image/png');

        } catch (error) {
            console.error("Erro ao converter SVG para PNG:", error);
            throw error;
        }
    };



    const downloadQRCode = async (nomePessoa = '', item, index) => {

        //const letraTarefa = `Tarefa: ${item.letraTarefa}`;
        const letraCustomizada = `Tarefa: ${item.letraCustomizada}`;
        const nomeSafraX = `Safra: ${nomeSafraP}`;

        const nomePessoaX = nomePessoa != null && nomePessoa != '' ? nomePessoa : 'seringueiro';
        const nomeFormatado = await truncarAndFormatarNomeSeringueiro(nomePessoaX, 30);
        const ano = moment().format('YYYY');
        const mes = moment().format('MM');
        const nomeArquivo = `QrCode_${letraCustomizada}_${nomeFormatado}_${mes}_${ano}.pdf`;


        const svg = svgRefs.current[index].querySelector('svg');
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        const qrSize = 500; // tamanho do QR Code
        const margin = 60;
        const textPadding = 60;
        const fontSize = 30; // Tamanho da fonte 16px
        const totalHeight = qrSize + 2 * margin + textPadding + fontSize * 2;

        // Configura o tamanho do canvas (inclui espaco para o texto acima)
        canvas.width = qrSize + 2 * margin;
        canvas.height = totalHeight;

        // Desenha o fundo branco
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Adiciona o texto "nomeFazenda" e "nome" acima do QR Code
        ctx.fillStyle = '#000000';
        ctx.font = `${fontSize}px Arial`;
        ctx.textAlign = 'center';

        // Desenha o nome pessoa
        //ctx.fillText(nomeSafraX, canvas.width / 2, margin);
        //ctx.fillText(nomePessoaX, canvas.width / 2, margin + fontSize);

        // Desenha a letra datarefa
        ctx.fillText(nomePessoaX, canvas.width / 2, margin + fontSize + 5);
        ctx.fillText(letraCustomizada, canvas.width / 2, margin + fontSize * 2 + 10);

        img.onload = function () {
            const logoImg = new Image();
            logoImg.src = IMG_LOGO;

            const qrYPosition = canvas.height - qrSize - margin; // Move o QR Code para o final do canvas

            // Desenha o QR Code na parte inferior
            ctx.drawImage(img, margin, qrYPosition, qrSize, qrSize);

            logoImg.onload = function () {
                const logoSize = 100;
                const logoX = (canvas.width - logoSize) / 2;
                const logoY = qrYPosition + (qrSize - logoSize) / 2;

                // Desenha a logo no centro do QR Code
                ctx.drawImage(logoImg, logoX, logoY, logoSize, logoSize);

                // Realiza o download da imagem como PNG
                const pngFile = canvas.toDataURL('image/png');
                const downloadLink = document.createElement('a');
                downloadLink.href = pngFile;
                downloadLink.download = `${nomeArquivo}.png`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            };
        };
        img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    };



    const svgToJpegWithFullBackground = async (svgElement, options = {}) => {
        const {
            width = 512,               // Largura total da imagem
            height = 512,              // Altura total da imagem
            backgroundColor = '#ffffff', // Cor de fundo
            margin = 10,               // Margem em pixels
            quality = 0.95             // Qualidade do JPEG
        } = options;

        try {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            // Configura a dimensão total do canvas
            canvas.width = width;
            canvas.height = height;

            // Preenche o fundo com a cor especificada
            context.fillStyle = backgroundColor;
            context.fillRect(0, 0, width, height);

            // Converte o SVG em string e cria a instância do Canvg
            const svgString = new XMLSerializer().serializeToString(svgElement);
            const canvgInstance = await Canvg.fromString(context, svgString);

            // Calcula o centro do canvas com a margem
            const x = (width - svgElement.clientWidth) / 2 + margin;
            const y = (height - svgElement.clientHeight) / 2 + margin;

            // Salva o estado do contexto para posicionar o QR Code centralizado
            context.save();
            context.translate(x, y);

            // Renderiza o SVG com Canvg para dentro do canvas
            await canvgInstance.render();

            // Restaura o contexto original
            context.restore();

            // Converte o canvas para JPEG com a qualidade especificada
            return canvas.toDataURL('image/jpeg', quality);
        } catch (error) {
            console.error("Erro ao converter SVG para JPEG com fundo completo:", error);
            throw error;
        }
    };













    function renderPropriedadeAndSangria() {
        const isDisabledSangria = listaSafraCombo.length > 0 ? false : true;
        const opacityText = isDisabledSangria ? '0.5' : '1';
        return (
            <Box
                px={'20px'}
                py={'10px'}
                borderRadius={'20px'}
                borderWidth={'2px'}
                borderColor={'blackAlpha.900'}
                bg={'white'}
                boxShadow={cardShadow}>
                <Text color={textColor} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Geração de QR Code</Text>
                <Text color={'gray.500'} fontSize='15px' fontWeight={'light'} lineHeight='100%' align={'center'}>{'Selecione uma propriedade e uma safra para carregar os dados'}</Text>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }} gap='10px' mt={'10px'}>
                    <Flex px='5px' mb='2px' flexDirection={'column'} justify='center' align='center'>
                        <Box  >
                            <Text ml={'10px'} color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Propriedades</Text>
                            <Select
                                value={uaSelecionado}
                                errorBorderColor='crimson'
                                placeholder="Selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                onChange={(e) => changePropriedade(e.target.value)}
                                h={'35px'}
                                w={'350px'}
                            >
                                {listUa.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex px='5px' mb='2px' flexDirection={'column'} justify='center' align='center'>
                        <Box>
                            <Text opacity={opacityText} ml={'10px'} color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Safras</Text>
                            <Select
                                value={safraSelecionado}
                                errorBorderColor='crimson'
                                placeholder="Selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                isDisabled={isDisabledSangria}
                                onChange={(e) => changeSafra(e.target.value)}
                                h={'35px'}
                                w={'350px'}
                            >
                                {listaSafraCombo.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                </SimpleGrid>
            </Box>
        )
    }

    function renderListaSafra() {
        const colorTextHeader = 'white';
        const espacoW = 2;
        const SHADOW_GREEN = '1px 1px #00b300';
        const SHADOW_RED = '1px 1px #ff0000';
        const SHADOW_YELLOW = '1px 1px #ffff00';

        let descStatus = '';
        let descProgramacao = '';
        let shadowColorProgramacao = '';
        let shadowStatus = '';

        if (sangriaAtivaP == true) {
            if (safraVigenteP == true) {
                descStatus = 'Vigente';
                shadowStatus = SHADOW_GREEN;
            } else {
                descStatus = 'Finalizado';
                shadowStatus = SHADOW_RED;
            }
            if (programacaoEmAndamento == true) {
                descProgramacao = 'Com Programação';
                shadowColorProgramacao = SHADOW_GREEN;
            } else {
                descProgramacao = 'Sem Programação';
                shadowColorProgramacao = SHADOW_YELLOW;
            }
        } else {
            descStatus = 'Finalizado';
            shadowStatus = SHADOW_RED;
            descProgramacao = 'Sem Programação';
            shadowColorProgramacao = SHADOW_YELLOW;
        }
        return (
            <Box borderWidth={'2px'} borderColor={'blackAlpha.600'} borderRadius={'20px'} boxShadow={shadowBlack} mt={'20px'}>
                <Card px='10px' py='10px'>
                    <Flex justify={'center'} align={'center'}>
                        <Text color={'black'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Safra</Text>
                    </Flex>
                    <Flex direction="column" align="center" mt={'10px'}>
                        <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.700'}>
                            <Table variant="striped" colorScheme='blackAlpha' size="sm" >
                                <Thead>
                                    <Tr h={'35px'} bgGradient={gradientHeader}>
                                        <Th w={'16%'} pl={'20px'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='start' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{'Nome'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Datas Safra'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Datas Sangria'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Status'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Programação'}</Text>
                                            </Flex>
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {safraSelecionado != '' &&
                                        <Tr>
                                            <Td pl={'20px'} sx={{ paddingX: espacoW }}>
                                                <Flex justify='start' align='center'>
                                                    <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{nomeSafraP}</Text>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex flexDirection={'column'} justify='center' align='center'>
                                                    <Flex justify='start' align='center'>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Início:`}</Text>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtInicioSafra}</Text>
                                                    </Flex>
                                                    <Flex mt={'5px'} justify='center' align='center'>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Fim:`}</Text>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtFimSafra}</Text>
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex flexDirection={'column'} justify='center' align='center'>
                                                    <Flex justify='start' align='center'>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Início:`}</Text>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtInicioSangria}</Text>
                                                    </Flex>
                                                    <Flex mt={'5px'} justify='start' align='center'>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Fim:`}</Text>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtFimSangria}</Text>
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex justify='center' align='center'>
                                                    <Text textShadow={shadowStatus} color={'blackAlpha.600'} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{descStatus}</Text>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex justify='center' align='center'>
                                                    <Text textShadow={shadowColorProgramacao} color={'blackAlpha.600'} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{descProgramacao}</Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>
                </Card>
            </Box>
        )
    }

    function renderTableSeringueiros() {
        const sizeF = '15px';
        let corLinha = false;
        return (
            <Box
                mt={'20px'}
                borderWidth={'2px'}
                borderColor={'blackAlpha.600'}
                borderRadius={'20px'}
                boxShadow={shadowBlack}>
                <Card px='10px' py='10px'>
                    <Flex justify={'center'} align={'center'}>
                        <Text color={'black'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Seringueiros</Text>
                    </Flex>
                    <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.700'} mt={'10px'}>
                        <Table size='sm' variant='unstyled'>
                            <Thead bg={colorFooterAndHeader}>
                                <Tr bgGradient={gradientHeader} h={'35px'}>
                                    <Th w={'80%'}>
                                        <Flex justify={'start'} align={'center'}>
                                            <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} align={'center'} textTransform={'none'}>{'Nome'}</Text>
                                        </Flex>
                                    </Th>
                                    <Th w={'20%'}>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} textTransform={'none'}>{''}</Text>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {listaPessoaBasic.map((item, key) => {
                                    corLinha = !corLinha;
                                    const deletado = item.deletado == true ? true : false;
                                    let isDesabilita = true;
                                    let colorIndicator = 'transparent';
                                    if (deletado) {
                                        colorIndicator = 'red';
                                        isDesabilita = true;
                                    } else {
                                        colorIndicator = item.isExisteProgramacao == true ? 'green' : 'yellow';
                                        isDesabilita = item.isExisteProgramacao == true ? false : true;
                                    }
                                    return (
                                        <Tr key={key + 1} alignItems={'center'} bg={corLinha ? 'rgba(217, 217, 217, 0.5)' : 'white'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} >
                                            <Td>
                                                <Flex justify='start' align='center'>
                                                    <Box w={'14px'} h={'14px'} borderRadius={'6px'} bg={colorIndicator} />
                                                    <Text ml={'5px'} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'start'} lineHeight={'100%'}>{item.nomePessoa}</Text>
                                                </Flex>
                                            </Td>
                                            <Td justifyContent={'center'} alignItems={'center'}>
                                                <Flex justify='end' align='center'>
                                                    <Button isDisabled={isDesabilita} variant="action" onClick={() => { changeGerarQrCode(item) }}>Gerar QR Code</Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )
                                })}

                                {/* <Tr alignItems={'center'} bg={corLinha ? 'rgba(217, 217, 217, 0.5)' : 'white'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} >
                                    <Td>
                                        <Flex justify='start' align='center'>
                                            <Text ml={'5px'} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'start'} lineHeight={'100%'}>{''}</Text>
                                        </Flex>
                                    </Td>
                                    <Td justifyContent={'center'} alignItems={'center'}>
                                        <Flex justify='end' align='center'>
                                            <Button isDisabled={false} variant="brand" onClick={() => { changeGerarQrCodeParaTodos() }}>Gerar para todos</Button>
                                        </Flex>
                                    </Td>
                                </Tr> */}

                            </Tbody>
                        </Table>
                    </TableContainer>
                </Card>
            </Box>
        )
    }

    function renderQrCodes() {
        return (
            <Box>
                {objetoQrCode != null && (
                    <Box
                        mt={'20px'}
                        bg={'white'}
                        borderWidth={'2px'}
                        borderColor={'blackAlpha.600'}
                        borderRadius={'20px'}
                        boxShadow={shadowBlack}>
                        <Flex px='25px' my='20px' justify='center' align='center' >
                            <Text color={'black'} fontSize={'20px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{objetoQrCode.nomePessoa}</Text>
                        </Flex>
                        <Wrap spacing={'20px'} mx={'10px'} mb={'15px'} justify='start'>
                            {objetoQrCode.listaTarefa.map((item, index) => {
                                const jsonQr = {
                                    idPessoa: objetoQrCode.idPessoa,
                                    idSafra: objetoQrCode.idSafra,
                                    idUa: objetoQrCode.idUa,
                                    idTarefa: item.idTarefa,
                                    letraTarefa: item.letraTarefa,
                                }
                                return (
                                    <WrapItem key={index} ref={(el) => (svgRefs.current[index] = el)} >
                                        <Box bg={'blackAlpha.50'} borderRadius={'20px'} borderWidth={'1px'} borderColor={'blackAlpha.500'}>
                                            <Text mt={'10px'} color={'black'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'} fontFamily={'body'}>{item.letraCustomizada}</Text>
                                            <Box px={'10px'} py={'10px'}>
                                                <QRCodeSVG
                                                    value={JSON.stringify(jsonQr)}
                                                    size={128}
                                                    bgColor={"#ffffff"}
                                                    fgColor={"#000000"}
                                                    level={"L"}
                                                    marginSize={0}
                                                    imageSettings={{
                                                        src: IMG_LOGO,
                                                        height: 25,
                                                        width: 25,
                                                        opacity: 1,
                                                        excavate: true,
                                                    }}
                                                />
                                            </Box>
                                            <Flex px='5px' mb={'10px'} justify='center' align='center' >
                                                <Button variant="action" mt={'10px'} onClick={() => downloadQRCode(objetoQrCode.nomePessoa, item, index)}>Baixar</Button>
                                            </Flex>
                                        </Box>
                                    </WrapItem>
                                )
                            })}
                        </Wrap>
                    </Box>
                )}
            </Box>
        )
    }



    function renderQrCodesParaTodos() {
        count = 0;
        return (
            <Box
                mt={'20px'}
                bg={'white'}
                borderWidth={'2px'}
                borderColor={'blackAlpha.600'}
                borderRadius={'20px'}
                boxShadow={shadowBlack}>
                {listaQRCParaTodos.map((ps, indexP) => {
                    return (
                        <Box key={indexP}>
                            <Box>
                                <Flex px='25px' my='20px' justify='center' align='center' >
                                    <Text color={'black'} fontSize={'20px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{ps.nomePessoa}</Text>
                                </Flex>
                                <Wrap spacing={'20px'} mx={'10px'} mb={'15px'} justify='start'>
                                    {ps.listaTarefa.map((item, index) => {
                                        const jsonQr = {
                                            idPessoa: ps.idPessoa,
                                            idSafra: ps.idSafra,
                                            idUa: ps.idUa,
                                            idTarefa: item.idTarefa,
                                        }
                                        return (
                                            <WrapItem key={index} ref={(el) => (svgRefsParaTodos.current[count++] = el)} >
                                                <Box bg={'white'} borderRadius={'20px'} borderWidth={'1px'} borderColor={'blackAlpha.500'}>
                                                    <Text mt={'10px'} color={'black'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'} fontFamily={'body'}>{item.letraCustomizada}</Text>
                                                    <Box px={'10px'} py={'10px'}>
                                                        <QRCodeSVG
                                                            value={JSON.stringify(jsonQr)}
                                                            size={256}
                                                            bgColor={"#ffffff"}
                                                            fgColor={"#000000"}
                                                            level={"L"}
                                                            marginSize={3}
                                                            imageSettings={{
                                                                src: IMG_LOGO,
                                                                height: 40,
                                                                width: 40,
                                                                opacity: 1,
                                                                excavate: true,
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </WrapItem>
                                        )
                                    })}
                                </Wrap>
                            </Box>
                        </Box>
                    )
                })}

                <Flex px='5px' mb={'10px'} justify='center' align='center' >
                    <Button variant="brand" onClick={downloadTesteeeeeeeeeeeeeeeeeeee}>Baixar todos</Button>
                    {/* <Button variant="brand" onClick={downloadQRCodesParaTodosSVG}>Baixar todos</Button> */}
                </Flex>

            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Flex px='25px' justify='start' mt={'20px'} align='center' >
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Flex>
        )
    }

    // function renderTeste() {
    //     count = 0;
    //     return (
    //         <Flex flexDirection={'column'} px='25px' justify='start' mt={'20px'} align='center' >
    //             {nestedDataArray.map((fazenda) =>
    //                 fazenda.codes.map((item) => (
    //                     <Box key={item.id} mt={'20px'}>
    //                         <QRCodeSVG
    //                             value={JSON.stringify(item)}
    //                             size={256}
    //                             ref={(el) => (qrRefs.current[count++] = el)}
    //                         />
    //                     </Box>
    //                 ))
    //             )}
    //             <Button variant="brand" onClick={downloadNestedQRCodes}>zip</Button>
    //         </Flex>
    //     )
    // }

    if (!telaCarregada) {
        return (
            <Box mt={'20px'}>
                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Carregando...'}</Text>
            </Box>
        )
    }



    return (
        <Card px='0px' py='0px' bg={'transparent'}>
            <Box pt={{ base: "80px", md: "80px", xl: "60px" }}>
                {isVisibleAlert && renderAlert()}


                {renderPropriedadeAndSangria()}
                <Box >
                    {renderListaSafra()}
                    {renderTableSeringueiros()}
                    {objetoQrCode != null && renderQrCodes()}
                    {/* {listaQRCParaTodos.length > 0 && renderQrCodesParaTodos()} */}

                    {/* {renderTeste()} */}
                </Box>
                {renderButton()}
            </Box>
            {loading && <Loader isOpen={loading} />}
        </Card>
    );
};
