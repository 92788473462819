import React, { useEffect, useState } from "react";
import {
    Box,
    SimpleGrid,
    useColorModeValue,
    Text,
    Flex,
    Image,
    Button,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Center,
    Checkbox,
    Wrap,
    Input,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalCloseButton,
    useDisclosure,
    Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import * as ActionTypes from '../../../constants/ActionTypes';
import { retornaNomeDoMes, redimencionarImagem } from '../../../util/FuncoesUteis';
import * as Api from 'store/ApiSpring';
import { LIST_UFS } from 'model/MockData'
import { retiraMascaraMaterNumeros, mascaraCep } from '../../../util/Masck';
import { REMOVE_CACHE_RELATORIO_ESPORADICO, REMOVE_CACHE_RELATORIO_PERSONALIZADO } from '../../../util/ValidarAutenticacao';
import SeuLogo from 'assets/img/layout/seu_logo.png';
import { RiCloseCircleFill, RiAddCircleFill } from "react-icons/ri";
import ModalDialog from 'components/modal/ModalDialog';
import RelatorioEmitido from 'assets/img/layout/relatorio_emitido.png';
import ArrowRight from 'assets/img/layout/arrow_right.png';


export default function EscolherTipoRelatorio() {

    const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [PERS_1, setPERS_1] = useState(true);
    const [PERS_2, setPERS_2] = useState(true);
    const [PERS_31, setPERS_31] = useState(true);
    const [PERS_32, setPERS_32] = useState(true);
    const [PERS_33, setPERS_33] = useState(true);
    const [isCheckOpcoes, setIsCheckOpcoes] = useState(false);

    const [EXPO_1, setEXPO_1] = useState(true);
    const [EXPO_2, setEXPO_2] = useState(true);
    const [EXPO_3, setEXPO_3] = useState(true);
    const [isCheckOpcoesEsporadico, setIsCheckOpcoesEsporadico] = useState(false);

    const [idUf, setIdUf] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [nomeMunicipioEsporadico, setNomeMunicipioEsporadico] = useState('');
    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [nomeSafra, setNomeSafra] = useState('');

    const [isValidUf, setIsValidUf] = useState(false);
    const [ufSelecionado, setUfSelecionado] = useState('');
    const [isValidMunicipio, setIsValidMunicipio] = useState(false);
    const [listMunicipio, setListMunicipio] = useState([]);
    const [municipioSelecionado, setMunicipioSelecionado] = useState('');
    const [isDisabledMunicipio, setIsDisabledMunicipio] = useState(true);
    const [idUsuarioP, setIdUsuarioP] = useState(null);
    const [arrayFiles, setArrayFiles] = useState([]);
    const [isValidMaxSizeFile, setIsValidMaxSizeFile] = useState(false);
    const [messageArryUpload, setMessageArryUpload] = useState('');
    const [openModalDialog, setOpenModalDialog] = useState(false);

    const [inpuNomeFazenda, setInpuNomeFazenda] = useState('');
    const [isValidNomeFazenda, setIsValidNomeFazenda] = useState(false);
    const [imputAreaTotal, setImputAreaTotal] = useState('');
    const [isValidAreaTotal, setIsValidAreaTotal] = useState(false);
    const [inputLocalizacao, setInputLocalizacao] = useState('');
    const [isLocalizacao, setIsLocalizacao] = useState(false);
    const [imputCep, setImputCep] = useState('');
    const [isValidCep, setIsValidCep] = useState(false);
    const [isCamposObrigatorios, setIsCamposObrigatorios] = useState(false);
    const [opcaoExclucao, setOpcaoExclucao] = useState('1');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.3)', 'unset');
    const mtCkeck = '15px';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const imgStr = localStorage.getItem('@HE-imagemDaLogoDoUsuarioTecnico');
            const imgFile = imgStr != null ? JSON.parse(imgStr) : [];
            setArrayFiles(imgFile);

            const idUser = localStorage.getItem('@HE-idUsuarioLogado');
            setIdUsuarioP(idUser);
            const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
            const nomeMunicipioP = localStorage.getItem('@HE-idNomeUfCadastradadoNaUa');

            setIdUf(idUfP);
            setNomeMunicipio(nomeMunicipioP);

            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const descData = `${nmMes}/${ano}`;
            setNomeMesCorrente(descData);

            if (!ActionTypes.PRODUCAO) {
                const jsonDadosFazenda = {
                    descricao: 'Fazenda Teste 123',
                    idUsuario: idUser,
                    idUf: 'DF',
                    nomeFazenda: 'Fazenda Teste 123',
                    cep: '72460250',
                    localizacao: 'Localização Gama leste',
                    areaTotal: '123',
                    nomeMunicipio: 'Brasília',
                    idMunicipio: '804',
                }
                localStorage.setItem('@HE-esporadico-dados-fazenda', JSON.stringify(jsonDadosFazenda));
            }

            // DADOS FAZENDA
            const ddFaz = localStorage.getItem('@HE-esporadico-dados-fazenda');
            if (ddFaz != null) {
                const ddd = JSON.parse(ddFaz);
                setInputLocalizacao(ddd.localizacao);
                setInpuNomeFazenda(ddd.nomeFazenda);
                setImputAreaTotal(ddd.areaTotal);
                setImputCep(ddd.cep);
                changeUf(ddd.idUf);
                setMunicipioSelecionado(ddd.idMunicipio);
                setNomeMunicipioEsporadico(ddd.nomeMunicipio);
            }

            const nomeUa = localStorage.getItem('@HE-nomeUa');
            setNomeFazenda(nomeUa);
            const nmSafra = localStorage.getItem('@HE-nomeSafra');
            setNomeSafra(nmSafra);
        }
        loaderScreen();
    }, []);

    const changeUf = async (e) => {
        const val = e;
        carregarUf(val);
        setIsValidUf(val == '' ? true : false);
    }

    const selecionarOpcaoExclusao = (opcao) => {
        setOpcaoExclucao(opcao);
        onOpen();
    }

    const confirmacaoExclusao = () => {
        onClose()
        if (opcaoExclucao == '1') {
            limparCashPersonalizado();
        } else {
            limparCashEsporadico();
        }
    }

    const limparCashPersonalizado = async () => {
        REMOVE_CACHE_RELATORIO_PERSONALIZADO();
    }
    const limparCashEsporadico = async () => {
        REMOVE_CACHE_RELATORIO_ESPORADICO();
        setInpuNomeFazenda('');
        setImputAreaTotal('');
        setInputLocalizacao('');
        setImputCep('');
        setUfSelecionado('');
        setListMunicipio([]);
        setMunicipioSelecionado('');
        setIsDisabledMunicipio(true);
    }

    const carregarUf = async (e) => {
        const uf = e;
        if (uf != '') {
            setUfSelecionado(`${uf}`)
            const response = await Api.getRequest(`api/ua/listaMunicipios?idUf=${uf}`);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                if (res.length > 0) {
                    let arr = res.map((item, index) => {
                        return {
                            value: `${item.idMunicipio}`,
                            label: item.nomeMunicipio,
                        }
                    })
                    setListMunicipio(arr);
                    setIsDisabledMunicipio(false);
                }
            }
        } else {
            setUfSelecionado('');
            setMunicipioSelecionado('');
            setIsDisabledMunicipio(true);
            setListMunicipio([]);
        }
    }

    const changeMunicipio = async (e) => {
        const val = e.target.value
        setMunicipioSelecionado(val);
        setIsValidMunicipio(val == '' ? true : false);

        const arr = listMunicipio.filter(x => x.value == val);
        if (arr.length > 0) {
            const nm = arr[0].label;
            setNomeMunicipioEsporadico(nm);
        }
    }

    const changeCEP = (e) => {
        const cep = e.target.value;
        const cepComMascara = mascaraCep(cep);
        setImputCep(cepComMascara);
    }

    const changeAreaTotal = (e) => {
        const val = e.target.value;
        const soNumeros = retiraMascaraMaterNumeros(val);
        setImputAreaTotal(soNumeros);
    }

    const selecaoItensRelatorioEsporadico = () => {
        setIsCheckOpcoesEsporadico(false);
        setIsCamposObrigatorios(false);
        let valid = false;

        if (inpuNomeFazenda.trim().length < 3) {
            setIsValidNomeFazenda(true);
            valid = true;
        } else {
            setIsValidNomeFazenda(false);
        }
        if (inputLocalizacao.trim().length < 2) {
            setIsLocalizacao(true);
            valid = true;
        } else {
            setIsLocalizacao(false);
        }
        if (ufSelecionado === null || ufSelecionado == '') {
            setIsValidUf(true);
            valid = true;
        } else {
            setIsValidUf(false);
        }
        if (municipioSelecionado === null || municipioSelecionado == '') {
            setIsValidMunicipio(true);
            valid = true;
        } else {
            setIsValidMunicipio(false);
        }
        // if (imputAreaTotal.length < 1) {
        //     setIsValidAreaTotal(true);
        //     valid = true;
        // } else {
        //     setIsValidAreaTotal(false);
        // }

        if (!EXPO_1 && !EXPO_2 && !EXPO_3) {
            setIsCheckOpcoesEsporadico(true);
            return;
        }

        if (valid) {
            setIsCamposObrigatorios(true);
            return;
        }

        const cepSemMascara = imputCep == '' ? null : retiraMascaraMaterNumeros(imputCep);
        const jsonDadosFazenda = {
            descricao: inpuNomeFazenda,    // POR ENQUANTO A DESCRICAO RECEBE O NOME DA FAZENDA MESMO
            idUsuario: idUsuarioP,
            idUf: ufSelecionado,
            nomeFazenda: inpuNomeFazenda,
            cep: cepSemMascara,
            localizacao: inputLocalizacao,
            areaTotal: imputAreaTotal,
            nomeMunicipio: nomeMunicipioEsporadico,
            idMunicipio: municipioSelecionado,
        }

        const jsonEspo = {
            opcaoEsporadico1: EXPO_1,
            opcaoEsporadico2: EXPO_2,
            opcaoEsporadico3: EXPO_3,
        }
        localStorage.setItem('@HE-opcoes-relatorio-esporadico', JSON.stringify(jsonEspo));
        localStorage.setItem('@HE-esporadico-dados-fazenda', JSON.stringify(jsonDadosFazenda));
        history.push("/admin/relatorios-esporadico");
    }

    const selecaoItensRelatorioPersonalizado = () => {
        setIsCheckOpcoes(false);

        if (!PERS_1 && !PERS_2 && !PERS_31 && !PERS_32 && !PERS_33) {
            setIsCheckOpcoes(true);
            return;
        } else {
            const jsonPers = {
                opcaoPendenciasAcoesDeCampo: PERS_1,
                opcaoObservacoesDiversasComentario: PERS_2,
                opcaoSangrias: PERS_31,
                opcaoQualidade: PERS_32,
                opcaoProducao: PERS_33,
            }
            localStorage.setItem('@HE-opcoes-relatorio-personalizado', JSON.stringify(jsonPers));
            history.push("/admin/relatorios-personalizado");
        }
    }

    // FUNCAO ASSINCRONA PARA CARREGAR A IMAGEM E OBTER DIMENSOES
    const loadImage = (src) => {
        return new Promise((resolve) => {
            const img = new window.Image();
            img.src = src;

            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            };
        });
    };

    // ISSO E PRECISO POR CAUSA DO html2canvas NA GERACAO DO PDF, SE NAO FOR DO TAMANHO CERTO DESTORCE A IMAGEM, ENTAO VOU REDIMENCIONAR A IMAGEM COM MAXIMO DE width 200 E height 200
    const redimencionarImagem22 = async (srcWidth, srcHeight, maxWidth, maxHeight) => {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        const alt = srcHeight * ratio;
        const lar = srcWidth * ratio;

        const altura = parseFloat(alt.toFixed(0));
        const largura = parseFloat(lar.toFixed(0));

        return { width: largura, height: altura };
    };


    const anexarImageLogo = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const nomeFile = file.name;
            const verifyExist = arrayFiles.filter(x => x.name == file.name);            // VERIFICAR SE O ARQUIVO JA EXISTE
            if (verifyExist.length > 0) {
                setMessageArryUpload('O arquivo já foi adicionado');
                setIsValidMaxSizeFile(true);
                return;
            }
            const readerProduca = new FileReader();
            readerProduca.onloadend = async (e) => {

                const { width, height } = await loadImage(e.target.result);             // PEGAR ALTURA E LARGURA DA IMAGEM
                const newSize = await redimencionarImagem(width, height, 200, 200);     // REDIMENCIONAR A IMAGEM MAXIMO DE width 200 E height 200, ISSO E PRECISO POR CAUSA DO html2canvas NA GERACAO DO PDF

                const base64String = readerProduca.result;
                const objFile = {
                    nomeFile: nomeFile,
                    fileBase64: base64String,
                    width: newSize.width,
                    height: newSize.height
                }

                const newArray = [objFile];
                setArrayFiles(newArray);
                localStorage.setItem('@HE-imagemDaLogoDoUsuarioTecnico', JSON.stringify(newArray));
            };
            readerProduca.readAsDataURL(file);
        }
    };

    const deleteImagemLodo = () => {
        setOpenModalDialog(false);
        setArrayFiles([]);
        localStorage.removeItem('@HE-imagemDaLogoDoUsuarioTecnico');
    }

    function renderTituloPersonalizado() {
        return (
            <Box position={'relative'} mb={'5'}>
                <Box>
                    <Text color={textColor} fontSize='20px' fontWeight='bold' align={'center'}>Relatório do Seringal</Text>
                    <Text color={textColor} fontSize='15px' fontWeight='normal' align={'center'}>{nomeFazenda}</Text>
                    <Text color={textColor} fontSize='15px' fontWeight='normal' align={'center'}>{nomeMunicipio}/{idUf}</Text>
                    <Text color={textColor} fontSize='15px' fontWeight='normal' align={'center'}>{nomeMesCorrente}</Text>
                </Box>
                <Box position={'absolute'} top={'0'} right={'1'} >
                    <Button h="70px" bg="transparent" onClick={() => { history.push("/admin/escolherUa") }}>
                        <Flex flexDirection={'column'} align={'center'} justify={'center'}>
                            <Image h={'30px'} w={'30px'} src={ArrowRight} alt="seta" />
                            <Text color={textColor} fontSize='13px' fontWeight='normal'>{'Trocar'}</Text>
                            <Text color={textColor} fontSize='13px' fontWeight='normal'>{'Propriedade'}</Text>
                        </Flex>
                    </Button>
                </Box>
            </Box>
        )
    }

    function renderOpcoesPersonalizado() {
        return (
            <Box
                borderRadius='10px'
                px={'20px'}
                py={'20px'}
                bg={'white'}
                boxShadow={cardShadow}>
                <Box w={'100%'}>
                    <Center alignItems={'center'} justifyContent={'start'}>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={PERS_1}
                            onChange={() => setPERS_1(!PERS_1)}
                        >
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'}>1 - Pendências e Ações de Campo</Text>
                        </Checkbox>
                    </Center>
                </Box>
                <Box w={'100%'} mt={mtCkeck}>
                    <Center alignItems={'center'} justifyContent={'start'}>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={PERS_2}
                            onChange={() => setPERS_2(!PERS_2)}
                        >
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'}>2 - Observações diversas e Comentários</Text>
                        </Checkbox>
                    </Center>
                </Box>
                <Box w={'100%'} px={'20px'} mt={'20px'}>
                    <Text color={textColor} fontSize='18px' fontWeight='bold' align={'start'}>3 - Indicadores Mensais</Text>
                    <Box w={'100%'} mt={'10px'}>
                        <Center alignItems={'center'} justifyContent={'start'}>
                            <Checkbox
                                size='lg'
                                colorScheme='blue'
                                isChecked={PERS_31}
                                onChange={() => setPERS_31(!PERS_31)}
                            >
                                <Text color={textColor} fontSize='15px' fontWeight={'bold'}>3.1 - Acompanhamento das Sangrias Diárias</Text>
                            </Checkbox>
                        </Center>
                    </Box>
                    <Box w={'100%'} mt={mtCkeck}>
                        <Center alignItems={'center'} justifyContent={'start'}>
                            <Checkbox
                                size='lg'
                                colorScheme='blue'
                                isChecked={PERS_32}
                                onChange={() => setPERS_32(!PERS_32)}
                            >
                                <Text color={textColor} fontSize='15px' fontWeight={'bold'}>3.2 - Qualidade Sangria</Text>
                            </Checkbox>
                        </Center>
                    </Box>
                    <Box w={'100%'} mt={mtCkeck}>
                        <Center alignItems={'center'} justifyContent={'start'}>
                            <Checkbox
                                size='lg'
                                colorScheme='blue'
                                isChecked={PERS_33}
                                onChange={() => setPERS_33(!PERS_33)}
                            >
                                <Text color={textColor} fontSize='15px' fontWeight={'bold'}>3.3 - Acompanhamento da Produção 2023_24</Text>
                            </Checkbox>
                        </Center>
                    </Box>
                </Box>
                {isCheckOpcoes && <Text color={'red.500'} fontSize='18px' fontWeight='bold' align={'center'}>Selecione pelo menos 1 item</Text>}
            </Box>
        )
    }

    function renderPropriedade() {
        return (
            <Card direction='column' w='99%' py='0px' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }} mb={'5'} >
                {isCamposObrigatorios && <Text mb={'3'} color={'red.500'} fontSize='18px' fontWeight='bold' align={'center'}>Verifique os campos obrigatórios</Text>}
                <Box w={'98%'}>
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'}>
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'} align={'start'} ml={'2'}>Nome Fazenda *</Text>
                            <Input
                                value={inpuNomeFazenda}
                                onChange={(e) => setInpuNomeFazenda(e.target.value)}
                                isInvalid={isValidNomeFazenda}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'} align={'start'} ml={'2'}>Localização *</Text>
                            <Input
                                value={inputLocalizacao}
                                onChange={(e) => setInputLocalizacao(e.target.value)}
                                isInvalid={isLocalizacao}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                    </SimpleGrid>
                </Box>

                <Box w={'98%'}>
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'} align={'start'} ml={'2'}>UF *</Text>
                            <Select
                                value={ufSelecionado}
                                isInvalid={isValidUf}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={(e) => changeUf(e.target.value)}>
                                {LIST_UFS.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'} align={'start'} ml={'2'}>Municipio *</Text>
                            <Select
                                value={municipioSelecionado}
                                isInvalid={isValidMunicipio}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                h={'35px'}
                                onChange={changeMunicipio}
                                disabled={isDisabledMunicipio}>
                                {listMunicipio.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </SimpleGrid>
                </Box>

                {/* <Box w={'98%'}>
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='5px'>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'} align={'start'} ml={'2'}>Area Total *</Text>
                                <Input
                                    value={imputAreaTotal}
                                    onChange={changeAreaTotal}
                                    isInvalid={isValidAreaTotal}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    maxLength={10}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'} align={'start'} ml={'2'}>CEP</Text>
                                <Input
                                    value={imputCep}
                                    onChange={changeCEP}
                                    isInvalid={isValidCep}
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                        </SimpleGrid>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'} align={'start'} ml={'2'}>UF *</Text>
                                <Select
                                    value={ufSelecionado}
                                    isInvalid={isValidUf}
                                    errorBorderColor='crimson'
                                    placeholder="selecione"
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    onChange={(e) => changeUf(e.target.value)}>
                                    {LIST_UFS.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }} w={'100%'} >
                                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'} align={'start'} ml={'2'}>Municipio *</Text>
                                <Select
                                    value={municipioSelecionado}
                                    isInvalid={isValidMunicipio}
                                    errorBorderColor='crimson'
                                    placeholder="selecione"
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    h={'35px'}
                                    onChange={changeMunicipio}
                                    disabled={isDisabledMunicipio}>
                                    {listMunicipio.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                        </SimpleGrid>
                    </SimpleGrid>
                </Box> */}




            </Card>
        )
    }

    function renderOpcoesEsporadico() {
        return (
            <Box
                borderRadius='10px'
                px={'20px'}
                py={'20px'}
                bg={'white'}
                boxShadow={cardShadow}>
                <Box w={'100%'}>
                    <Center alignItems={'center'} justifyContent={'start'}>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={EXPO_1}
                            onChange={() => setEXPO_1(!EXPO_1)}
                        >
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'}>1 - Pendências e Ações de Campo</Text>
                        </Checkbox>
                    </Center>
                </Box>
                <Box w={'100%'} mt={mtCkeck}>
                    <Center alignItems={'center'} justifyContent={'start'}>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={EXPO_2}
                            onChange={() => setEXPO_2(!EXPO_2)}
                        >
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'}>2 - Observações diversas e Comentários</Text>
                        </Checkbox>
                    </Center>
                </Box>
                <Box w={'100%'} mt={mtCkeck}>
                    <Center alignItems={'center'} justifyContent={'start'}>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={EXPO_3}
                            onChange={() => setEXPO_3(!EXPO_3)}
                        >
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'}>3 - Indicadores Mensais</Text>
                        </Checkbox>
                    </Center>
                </Box>
                {isCheckOpcoesEsporadico && <Text color={'red.500'} fontSize='18px' fontWeight='bold' align={'center'}>Selecione pelo menos 1 item</Text>}
            </Box>
        )
    }

    function renderButtonPersonalizado() {
        return (
            <Wrap spacing='30px' mx={'30px'} my={'30px'} justify='space-between'>
                <Button variant="brand" onClick={() => { selecionarOpcaoExclusao('1') }}>Limpar Dados</Button>
                <Button variant="brand" onClick={selecaoItensRelatorioPersonalizado}>Próximo</Button>
            </Wrap>
        )
    }

    function renderButtonEsporadico() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'10'} justify='space-between'>
                <Button variant="brand" onClick={() => { selecionarOpcaoExclusao('2') }}>Limpar Dados</Button>
                <Button variant="brand" onClick={selecaoItensRelatorioEsporadico}>Próximo</Button>
            </Wrap>
        )
    }

    function renderModal() {
        return (
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                isCentered={true}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Limpar Dados</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text color={textColor} fontSize={'15px'} fontWeight='700' >{'Esta ação exclui os dados selecionados para geração do relatório. Por exemplo as imagens selecionadas, comentários, etc. Deseja realmente excluir?'}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='brand' onClick={onClose} mr={'50px'}>Não</Button>
                        <Button variant='brand' onClick={confirmacaoExclusao}>Sim</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card align='center' direction='column' w='100%' px='5px' py='0px'>
                <Flex px={'10px'} align={'center'} justify={'space-between'}>
                    <Box w={'20%'}>
                        <Flex flexDirection={'column'} align={'start'} justify={'start'}>
                            <Box w={'100px'}>
                                <Image
                                    h={'50px'}
                                    w={'50px'}
                                    objectFit={'contain'}
                                    src={RelatorioEmitido}
                                    cursor={'pointer'}
                                    onClick={() => { history.push("/admin/relatorio-emitido-personalizado-esporadido") }}
                                    alt="relat" />
                                <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%' align={'center'} >Relatórios Emitidos</Text>
                            </Box>
                        </Flex>
                    </Box>
                    <Box w={'60%'}>
                        <Text color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%' align={'center'} >Opções de Relatórioo</Text>
                    </Box>
                    <Box position={'relative'} w={'20%'}>
                        <Flex align={'center'} justify={'space-between'}>
                            <Box p={'1px'} w={'100%'} h={'100%'}>
                                <Center w={'100%'} h={'100%'} justifyContent={'end'}>
                                    {arrayFiles.length > 0 ?
                                        <Image
                                            maxH={'70px'}
                                            maxW={'120px'}
                                            objectFit={'contain'}
                                            src={arrayFiles[0].fileBase64}
                                            alt="logo" />
                                        :
                                        <Image
                                            maxH={'70px'}
                                            maxW={'120px'}
                                            objectFit={'contain'}
                                            src={SeuLogo}
                                            alt="logo" />
                                    }
                                </Center>
                            </Box>
                            <label htmlFor="fileUp" style={{ height: '70px', width: '50px' }}>
                                <Box
                                    cursor={'pointer'}
                                    w={'100%'}
                                    h={'100%'}
                                    variant="outline">
                                    <Center w={'100%'} h={'100%'} justifyContent={'end'} alignItems={'start'}>
                                        <Icon as={RiAddCircleFill} width='30px' height='30px' color='green.600' marginRight={'0px'} />
                                    </Center>
                                </Box>
                            </label>
                        </Flex>
                        <Input
                            id="fileUp"
                            type="file"
                            onChange={anexarImageLogo}
                            display={'none'}
                            accept="image/png, image/jpeg, image/jpg"
                            placeholder='custom'
                            _placeholder={{ opacity: 1, color: 'gray.500' }}
                        />
                        <Box
                            position={'absolute'}
                            bottom={'0px'}
                            right={'0px'}
                            cursor={'pointer'}>
                            <Center h={'100%'}>
                                <Icon as={RiCloseCircleFill} width='30px' height='30px' color='red.600' marginLeft={'0px'} onClick={() => { setOpenModalDialog(true) }} />
                            </Center>
                        </Box>
                    </Box>
                </Flex>
                <Box w={'100%'} mt={'10px'}>
                    <Tabs isFitted variant='enclosed' borderRadius={'15px'} borderWidth={'2px'} borderColor={'blackAlpha.700'}>
                        <TabList mb='0px'>
                            <Tab _selected={{ color: 'white', bg: 'blue.600' }} borderTopLeftRadius={'15px'} fontSize={'15px'} fontWeight={'bold'}>Personalizado</Tab>
                            <Tab _selected={{ color: 'white', bg: 'blue.600' }} borderTopRightRadius={'15px'} fontSize={'15px'} fontWeight={'bold'}>Esporádico</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                {renderTituloPersonalizado()}
                                {renderOpcoesPersonalizado()}
                                {renderButtonPersonalizado()}
                            </TabPanel>
                            <TabPanel>
                                {renderPropriedade()}
                                {renderOpcoesEsporadico()}
                                {renderButtonEsporadico()}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Card>
            {renderModal()}
            {openModalDialog && <ModalDialog isOpen={openModalDialog} onPressOk={() => { deleteImagemLodo() }} onPressCancel={() => { setOpenModalDialog(false) }} descricao={'Deseja Realmente excluir a imagem?'} />}
        </Box>
    );
}
