import React from 'react';
import {
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Flex,
    Button,
    Text,
    Box,
} from "@chakra-ui/react";
import moment from 'moment';

function ModalDetalhesTarefa({ isOpen, onPress, response = [] }) {


    const obj = response.tarefaDetailItem;
    const frequencia = `D${obj.idQuantidadeTarefas}`;
    const idMotivoObj = obj.idMotivo != null ? obj.idMotivo : 0;
    const descMotivoObj = idMotivoObj != 0 ? obj.descMotivo : '';
    const dtProgramadoObj = moment(obj.dtProgramado).format('DD/MM/YYYY');
    const dtRealizadoObj = obj.dtRealizado != null ? moment(obj.dtRealizado).format('DD/MM/YYYY') : '-';

    const idStatusTarefaObj = obj.idStatusTarefa;
    let colorStatusObj = 'black';

    if (idStatusTarefaObj == 1) {
        colorStatusObj = 'green';
    } else if (idStatusTarefaObj == 2) {
        colorStatusObj = 'orange';
    } else if (idStatusTarefaObj == 3) {
        colorStatusObj = 'red';
    } else if (idStatusTarefaObj == 4) {
        colorStatusObj = 'red';
    }


    const GRADIENTE = 'linear-gradient(to bottom, #d9d9d9, #ffffff, #d9d9d9)';
    const textColor = useColorModeValue("secondaryGray.900", "white");

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='lg'
            scrollBehavior='inside'
            colorScheme='purple'
        >
            <ModalOverlay />
            <ModalContent bgGradient={GRADIENTE}>
                <ModalBody px={'20px'}>
                    <Box px={'10px'} py={'10px'}>
                        <Flex mt={'5px'} justify='space-between' align='center'>
                            <Flex w={'25%'} justify='start' align='start'>
                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{'Frequência'}</Text>
                                <Text color={'orange.600'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} pl={'5px'}>{frequencia}</Text>
                            </Flex>
                            <Flex w={'50%'} h={'30px'} flexDirection={'column'} justify='start' align='center'>
                                <Text color={'black'} fontSize={'20px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Detalhes'}</Text>
                            </Flex>
                            <Flex w={'25%'} flexDirection={'column'} justify='center' align='end'>
                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{''}</Text>
                                <Text color={'orange.600'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} >{''}</Text>
                            </Flex>
                        </Flex>
                        <Box w={'100%'} px={'10px'} py={'10px'} mt={'10px'} borderWidth={'1px'} borderRadius={'10px'} borderColor={'blackAlpha.800'} bg={'white'}>
                            <Text color={'black'} fontSize={'16px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Tarefa Selecionada'}</Text>
                            <Flex w={'100%'} justify='space-between' align='start'>
                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{'Tarefa'}</Text>
                                <Text color={'blue.800'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} bg={'blackAlpha.200'} px={'5px'} py={'2px'} borderRadius={'10px'}>{obj.letraCustomizada}</Text>
                            </Flex>
                            <Flex w={'100%'} justify='space-between' align='start'>
                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{'Status'}</Text>
                                <Text color={colorStatusObj} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{obj.dsStatus}</Text>
                            </Flex>
                            {idMotivoObj != 0 &&
                                <Flex w={'100%'} justify='space-between' align='start'>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{'Motivo'}</Text>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{descMotivoObj}</Text>
                                </Flex>
                            }
                            <Flex w={'100%'} justify='space-between' align='start'>
                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{'Dt Programado'}</Text>
                                <Text color={textColor} fontSize='14px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{dtProgramadoObj}</Text>
                            </Flex>
                            <Flex w={'100%'} justify='space-between' align='start'>
                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{'Dt Realizado'}</Text>
                                <Text color={textColor} fontSize='14px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{dtRealizadoObj}</Text>
                            </Flex>
                        </Box>
                        <Box mt={'20px'} px={'15px'} py={'5px'} bg={'white'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.800'} >
                            <Flex w={'100%'} justify='center' align='start'>
                                <Text color={textColor} fontSize={'16px'} fontWeight={'bold'} lineHeight={'100%'}>{`Tarefas realizadas nessa data`}</Text>
                                <Text color={textColor} fontSize='14px' fontWeight={'bold'} lineHeight={'100%'} ml={'10px'}>{`${dtProgramadoObj}`}</Text>
                            </Flex>
                            {response.listTarefaDetailItem.length > 0 ? (
                                response.listTarefaDetailItem.map((item, index) => {



                                    const idMotivo = item.idMotivo != null ? item.idMotivo : 0;
                                    const descMotivo = idMotivo != 0 ? item.descMotivo : '';

                                    const dtProgramado = moment(item.dtProgramado).format('DD/MM/YYYY');
                                    const dtRealizado = item.dtRealizado != null ? moment(item.dtRealizado).format('DD/MM/YYYY') : '-';
                                    const dtTarefaAnteriorRealizado = item.dtTarefaAnteriorRealizado != null ? moment(item.dtTarefaAnteriorRealizado).format('DD/MM/YYYY') : '-';

                                    const idStatusTarefa = item.idStatusTarefa;
                                    let colorStatus = textColor;

                                    if (idStatusTarefa == 1) {
                                        colorStatus = 'green';
                                    } else if (idStatusTarefa == 2) {
                                        colorStatus = 'orange';
                                    } else if (idStatusTarefa == 3) {
                                        colorStatus = 'red';
                                    } else if (idStatusTarefa == 4) {
                                        colorStatus = 'red';
                                    }

                                    const size = response.listTarefaDetailItem.length;
                                    let Width = '1px';
                                    if(index >= size - 1){
                                        Width = '0px';
                                    }

                                    return (
                                        <Box key={index} w={'100%'} py={'10px'} borderBottomWidth={Width} borderColor={'black'}>
                                            <Flex w={'100%'} justify='space-between' align='start'>
                                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{'Tarefa'}</Text>
                                                <Text color={'blue.800'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} bg={'blackAlpha.200'} px={'5px'} py={'2px'} borderRadius={'10px'}>{item.letraCustomizada}</Text>
                                            </Flex>
                                            <Flex w={'100%'} justify='space-between' align='start'>
                                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{'Status'}</Text>
                                                <Text color={colorStatus} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{item.dsStatus}</Text>
                                            </Flex>
                                            {idMotivo != 0 &&
                                                <Flex w={'100%'} justify='space-between' align='start'>
                                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{'Motivo'}</Text>
                                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{descMotivo}</Text>
                                                </Flex>
                                            }
                                            <Flex w={'100%'} justify='space-between' align='start'>
                                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{'Dt Programado'}</Text>
                                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'3px'}>{dtProgramado}</Text>
                                            </Flex>
                                            <Flex mt={'15px'} h={'50px'} w={'100%'} justify='space-between' align='center'>
                                                <Flex w={'33%'} h={'50px'} py={'0px'} flexDirection={'column'} justify='space-between' align='center'>
                                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Data Realizado'}</Text>
                                                    <Text color={'orange.700'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'5px'}>{`${dtRealizado}`}</Text>
                                                </Flex>

                                                <Flex w={'33%'} h={'50px'} py={'0px'} px={'5px'} flexDirection={'column'} justify='space-between' align='center' borderLeftWidth={'1px'} borderRightWidth={'1px'} borderColor={'black'}>
                                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Realização Anterior'}</Text>
                                                    <Text color={'orange.700'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'5px'}>{`${dtTarefaAnteriorRealizado}`}</Text>
                                                </Flex>

                                                <Flex w={'33%'} h={'50px'} py={'0px'} flexDirection={'column'} justify='space-between' align='center'>
                                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Diferença e dias'}</Text>
                                                    <Text color={'orange.700'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} mt={'5px'}>{`${item.qtdDiasEntreTarefas}`}</Text>
                                                </Flex>
                                            </Flex>
                                        </Box>
                                    )
                                })
                            ) : (
                                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Sem Resultados'}</Text>
                            )}
                        </Box>
                    </Box>
                </ModalBody>
                <ModalFooter mt={'2px'} justifyContent={'center'}>
                    <Button w={'80px'} variant="miniBrand" onClick={onPress}>Ok</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalDetalhesTarefa;
