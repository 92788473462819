import React, { useEffect, useState } from "react";
import {
    Flex,
    Image,
    Text,
    TableContainer,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Box,
    Wrap,
    Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { retornaNomeDoMes } from '../../../util/FuncoesUteis';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import LoaderBlack from 'components/loading/LoaderBlack';
import { MdBrightness1 } from "react-icons/md";
import { retornaHtmlLancamentoProducao } from 'components/geracaoHtml/GerarHtmlLancamentoProducao';

export default function GerarPdfLancamentoPorTarefa() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [nomeUa, setNomeUa] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');
    const [totalGeralPrevisto, setTotalGeralPrevisto] = useState('0');
    const [totalGeralRealizado, setTotalGeralRealizado] = useState('0');
    const [totalGeralPercentual, setTotalGeralPercentual] = useState('0');
    const [totalPrevParcial, setTotalPrevParcial] = useState('0');
    const [totalRealParcial, setTotalRealParcial] = useState('0');
    const [totalPercentParcial, setTotalPercentParcial] = useState('0');
    const [nomeRelatorio, setNomeRelatorio] = useState('Lancamento-producao');
    const [formInputsMeses, setFormInputsMeses] = useState([]);
    const [qtdSeringueiros, setQtdSeringueiros] = useState(0);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientOrange = 'linear-gradient(orange.300, orange.200, orange.100)';
    const gradientSoma = 'linear-gradient(orange.700, orange.800, orange.900)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            setLoading(false);

            const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
            const fim = localStorage.getItem('@HE-dtFimSafraAsync');
            const idUa = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');
            const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
            const nomeUaP = localStorage.getItem('@HE-nomeUa');
            const nomeMunicipio = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');

            setNomeUa(nomeUaP);
            setNomeMunicipio(nomeMunicipio);
            setIdUf(idUfP);

            const url = `safraPessoa/listaSafraPessoaComProducaoPessoa?idUa=${idUa}&idSafra=${idSafra}&dtInicio=${ini}&dtFim=${fim}&incluirAlternanciaNaPesquisa=true`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta == ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                setTotalGeralPrevisto(res.totalGeralPrevisto);
                setTotalGeralRealizado(res.totalGeralRealizado);
                setTotalGeralPercentual(res.totalGeralPercentual);
                setTotalPrevParcial(res.totalPrevParcial);
                setTotalRealParcial(res.totalRealParcial);
                setTotalPercentParcial(res.totalPercentParcial);

                await carregaDadosMeses(res)
            }

            const dia = moment().format('DD');
            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const nmRelatorio = `Lancamento-producao-${dia}-${nmMes}-${ano}`;
            setNomeRelatorio(nmRelatorio);
            const descData = `${nmMes}/${ano}`;
            setNomeMesCorrente(descData);

            setTelaCarregada(true);
        }
        loaderScreen();
    }, [])

    const carregaDadosMeses = async (res) => {
        const listObjProducaoPessoa = res.listObjProducaoPessoa;
        setQtdSeringueiros(listObjProducaoPessoa.length);
        let ss = null;
        await new Promise(resolve => {
            resolve(
                ss = listObjProducaoPessoa.map(val => {
                    return {
                        key: `${val.idPessoa}`,
                        listMeses: val.listMeses,
                        idPessoa: `${val.idPessoa}`,
                        nome: val.nome,
                        deletado: val.deletado,
                        idSafraPessoa: val.idSafraPessoa,
                        totalPrevisto: val.totalPrevisto != 0 && val.totalPrevisto != null ? `${val.totalPrevisto}` : '0',
                        totalRealizado: val.totalRealizado != 0 && val.totalRealizado != null ? `${val.totalRealizado}` : '0',
                        totalPercentual: val.totalPercentual != 0 && val.totalPercentual != null ? `${val.totalPercentual}` : '0',
                        totalParcialPrevisto: val.totalParcialPrevisto != 0 && val.totalParcialPrevisto != null ? `${val.totalParcialPrevisto}` : '0',
                        totalParcialRealizado: val.totalParcialRealizado != 0 && val.totalParcialRealizado != null ? `${val.totalParcialRealizado}` : '0',
                        totalParcialPercentual: val.totalParcialPercentual != 0 && val.totalParcialPercentual != null ? `${val.totalParcialPercentual}` : '0',
                    }
                })
            )
        });
        setFormInputsMeses(ss);
    }

    async function downloadPdf() {
        setTelaCarregada(false);

        const objValoresTotais = {
            totalPrevParcial: totalPrevParcial,
            totalRealParcial: totalRealParcial,
            totalPercentParcial: totalPercentParcial,
            totalGeralPrevisto: totalGeralPrevisto,
            totalGeralRealizado: totalGeralRealizado,
            totalGeralPercentual: totalGeralPercentual,
        }
        const objItens = {
            tituloRelatorio: 'Lançamento Produção',
            formInputsMeses: formInputsMeses,
            nomeMesCorrente: nomeMesCorrente,
            nomeUa: nomeUa,
            nomeMunicipio: nomeMunicipio,
            idUf: idUf,
            objValoresTotais: objValoresTotais,
        }

        const html = await retornaHtmlLancamentoProducao(objItens);
        const json = {
            htmlContent: html,
        }

        const url = `pdfRelatorio/converterHtmlParaPdf`;
        await Api.postRequestDownloadPdf(url, json, nomeRelatorio);
        setTelaCarregada(true);
    }

    function renderCardsLancamentoProducao(array = [], possicaoInit = 0, possicaoFim = 5) {
        const ftSize = '11px';
        const largTr = 1;
        const altura = 0;
        return (
            <Box overflowY="hidden" overflowX={'auto'} maxWidth={'100%'}>
                <Flex justify='space-between' align='center'>
                    {array.listMeses.map((item, ww) => {
                        const valorParcial = item.valorParcial != null ? true : false;
                        const valorAnual = item.valorAnual != null ? true : false;
                        const bgColum = valorParcial || valorAnual ? 'blackAlpha.300' : 'blackAlpha.50';
                        const bgBory = valorParcial || valorAnual ? 'blackAlpha.200' : 'white';
                        const listP = item.listProducaoPessoaAlternanciaItem != null && item.listProducaoPessoaAlternanciaItem.length > 0 ? item.listProducaoPessoaAlternanciaItem : [];
                        if (ww >= possicaoInit && ww <= possicaoFim) {
                            return (
                                <Box key={ww}>
                                    <Flex justify='space-between' align='center'>
                                        <Box>
                                            <Text mt={'4px'} color={'orange.700'} fontSize='12px' fontWeight='bold' lineHeight={'100%'} align={'center'}>{item.descMesAno}</Text>
                                            <Table size="sm" variant="unstyled">
                                                <Thead>
                                                    <Tr bgGradient={gradientHeader} h={'18px'}>
                                                        <Th sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text color={textColorHeader} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Alt</Text>
                                                        </Th>
                                                        <Th sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text color={textColorHeader} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Prev</Text>
                                                        </Th>
                                                        <Th sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text color={textColorHeader} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Real</Text>
                                                        </Th>
                                                        <Th sx={{ paddingX: largTr, paddingY: altura }} w={'40px'} bg={bgColum}>
                                                            <Text color={textColorHeader} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>%</Text>
                                                        </Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {listP.map((x, y) => {
                                                        return (
                                                            <Tr key={y} bgGradient={gradientOrange} h={'14px'}>
                                                                <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgBory}>
                                                                    <Text color={textColor} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>{x.letraCustomizada}</Text>
                                                                </Td>
                                                                <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgBory}>
                                                                    <Text color={textColor} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>{x.valorPrevTarefa}</Text>
                                                                </Td>
                                                                <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgBory}>
                                                                    <Text color={textColor} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>{x.valorTarefa}</Text>
                                                                </Td>
                                                                <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgBory}>
                                                                    <Text color={textColor} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>{`${x.percent}%`}</Text>
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    })}
                                                    <Tr bgGradient={gradientSoma} h={'18px'}>
                                                        <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text color={textColorHeader} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Total</Text>
                                                        </Td>
                                                        <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text color={textColorHeader} fontSize={ftSize} fontWeight='light' align={'center'} lineHeight={'100%'}>{item.valorPrevisto}</Text>
                                                        </Td>
                                                        <Td sx={{ paddingX: largTr, paddingY: altura }} bg={item.idTipoLancamento == 1 ? 'lime' : 'yellow'}>
                                                            <Text color={'black'} fontSize={ftSize} fontWeight='light' align={'center'} lineHeight={'100%'}>{item.valorRealizado}</Text>
                                                        </Td>
                                                        <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text color={textColorHeader} fontSize={ftSize} fontWeight='light' align={'center'} lineHeight={'100%'}>{`${item.percent}%`}</Text>
                                                        </Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </Box>
                                    </Flex>
                                </Box>
                            )
                        }
                    })}
                    {possicaoFim > 12 &&
                        <Box>
                            <Flex justify='space-between' align='center'>
                                <Table size="sm" variant="unstyled">
                                    <Thead>
                                        <Tr h={'18px'}>
                                            <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                                <Text color={'transparent'} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Alt</Text>
                                            </Th>
                                            <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                                <Text color={'transparent'} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Prev</Text>
                                            </Th>
                                            <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                                <Text color={'transparent'} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Real</Text>
                                            </Th>
                                            <Th sx={{ paddingX: largTr, paddingY: altura }} w={'40px'}>
                                                <Text color={'transparent'} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>%</Text>
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }}></Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }}></Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }}></Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }}></Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Flex>
                        </Box>
                    }
                </Flex>
            </Box>
        )
    }

    function renderTotalGeral() {
        const bgParcial = 'rgba(204, 102, 0, 0.4)';
        const largTr = qtdSeringueiros < 35 ? 1 : 0;
        return (
            <Flex justify='center' align='center' mt={'20px'}>
                <TableContainer borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.500'}>
                    <Table size="sm" variant="unstyled" maxW={'500px'}>
                        <Thead>
                            <Tr h={'50px'} bgGradient={gradientHeader}>
                                <Th textTransform={'none'} sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' lineHeight={'100%'}>{'Seringueiro'}</Text>
                                </Th>
                                <Th textTransform={'none'} bg={bgParcial} sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Parcial'}</Text>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Previsto'}</Text>
                                </Th>
                                <Th textTransform={'none'} bg={bgParcial} sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Parcial'}</Text>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                </Th>
                                <Th textTransform={'none'} bg={bgParcial} sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Parcial'}</Text>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Percentual'}</Text>
                                </Th>
                                <Th textTransform={'none'} sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Previsto'}</Text>
                                </Th>
                                <Th textTransform={'none'} sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Realizado'}</Text>
                                </Th>
                                <Th textTransform={'none'} sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Percentual'}</Text>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {formInputsMeses.map((input, key) => {
                                return (
                                    <Tr key={key} bgGradient={gradientOrange}>
                                        <Td sx={{ paddingY: largTr }}>
                                            <Text color={'orange.700'} fontSize='13px' fontWeight='bold' lineHeight={'100%'}>{input.nome}</Text>
                                        </Td>
                                        <Td bg={bgParcial} sx={{ paddingY: largTr }}>
                                            <Text color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{input.totalParcialPrevisto}</Text>
                                        </Td>
                                        <Td bg={bgParcial} sx={{ paddingY: largTr }}>
                                            <Text color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{input.totalParcialRealizado}</Text>
                                        </Td>
                                        <Td bg={bgParcial} sx={{ paddingY: largTr }}>
                                            <Text color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{`${input.totalParcialPercentual}%`}</Text>
                                        </Td>
                                        <Td sx={{ paddingY: largTr }}>
                                            <Text color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{input.totalPrevisto}</Text>
                                        </Td>
                                        <Td sx={{ paddingY: largTr }}>
                                            <Text color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{input.totalRealizado}</Text>
                                        </Td>
                                        <Td sx={{ paddingY: largTr }}>
                                            <Text color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{`${input.totalPercentual}%`}</Text>
                                        </Td>
                                    </Tr>
                                )
                            })
                            }
                            <Tr bgGradient={gradientSoma}>
                                <Td sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'TOTAL'}</Text>
                                </Td>
                                <Td bg={bgParcial} sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{totalPrevParcial}</Text>
                                </Td>
                                <Td bg={bgParcial} sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{totalRealParcial}</Text>
                                </Td>
                                <Td bg={bgParcial} sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{`${totalPercentParcial}%`}</Text>
                                </Td>
                                <Td sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{totalGeralPrevisto}</Text>
                                </Td>
                                <Td sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{totalGeralRealizado}</Text>
                                </Td>
                                <Td sx={{ paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{`${totalGeralPercentual}%`}</Text>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
        )
    }

    function renderArrayGeracaoTarefas() {
        return (
            <Box>
                {formInputsMeses.length > 0 ?
                    formInputsMeses.map((input, key) => {
                        return (
                            <Box key={key} mt={'15px'} pb={'10px'} px={'10px'} borderWidth={'3px'} borderColor={'blackAlpha.600'} borderRadius={'10px'} bg={'gray.100'}>
                                <Box mb={'2px'}>
                                    <Text mt={'5px'} color={'orange.700'} fontSize='18px' fontWeight='bold' lineHeight={'100%'}>{input.nome}</Text>
                                </Box>
                                {renderCardsLancamentoProducao(input, 0, 4)}
                                {renderCardsLancamentoProducao(input, 5, 9)}
                                {renderCardsLancamentoProducao(input, 10, 14)}
                            </Box>
                        )
                    })
                    :
                    <Text color={'transparent'} fontSize={'10px'}>para nao dar erro</Text>
                }
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex flexDirection={'column'} px='1px' mt={'30px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Criando relatório, Aguarde...'}</Text>
                <Text as='cite' fontSize={'15px'} textShadow={border_white} fontWeight={'bold'} color={'black'} lineHeight={'100%'}>{'Esse processo pode levar alguns segundos'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' px='0px' py='0px' >
                <Box w={'100%'} mb={'20px'}>
                    <Flex px='25px' justify='space-between' mt='20px' align='center'>
                        <Button h={'30px'} variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                        <Image cursor={'pointer'} boxSize='35px' objectFit='contain' src={`${ActionTypes.LINK_GOOGLE_STORAGE}/pdf_icon_download.png`} alt='Dan Abramov' onClick={downloadPdf} />
                    </Flex>
                </Box>
                <Box w={'100%'}>
                    <Box position={'relative'}>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'}>Lançamento de Produção</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{nomeMesCorrente}</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeUa}</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMunicipio}/${idUf}`}</Text>
                        </Flex>
                        <Box position={'absolute'} top={'1px'} left={'10px'}>
                            <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                        </Box>
                    </Box>
                    <Box>
                        <Flex mt={'1px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='20px' height='20px' color='yellow' />
                            <Text color={textColor} fontSize='14px' fontWeight='bold' ml={'10px'} lineHeight={'100%'}>{'Lançamentos por frequência'}</Text>
                        </Flex>
                        <Flex mt={'1px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='20px' height='20px' color='lime' />
                            <Text color={textColor} fontSize='14px' fontWeight='bold' ml={'10px'} lineHeight={'100%'}>{'Lançamentos pelo valor total'}</Text>
                        </Flex>
                    </Box>
                    {renderArrayGeracaoTarefas()}
                </Box>
                <Box w={'100%'} mt={'10px'}>
                    {renderTotalGeral()}
                </Box>
                <Wrap spacing='30px' mx={'30px'} my={'30px'} justify='space-between'>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                </Wrap>
            </Card>
            <LoaderBlack isOpen={loading} subTitulo={'Dependendo da quantidade de registros, esse processo pode levar mais de um minuto'} />
        </Box>
    );
}
