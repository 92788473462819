import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Text,
    Box,
    Flex,
    Input,
    useColorModeValue,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import { retiraMascaraMaterNumeros } from '../../util/Masck';

function ModalChuvasAnuais({ isOpen, onPressCancel, onConfirm, titulo = 'OBS', valueInput = '', mesAndAno = '' }) {

    const [inputValue, setInputValue] = useState(valueInput);
    const [isValidInput, setIsValidInput] = useState(false);
    const textColor = useColorModeValue("secondaryGray.900", "white");

    const handleConfirm = () => {
        if (inputValue.trim().length < 1) {
            setIsValidInput(true);

        } else if (onConfirm) {
            onConfirm(inputValue); // Envia o valor para o pai
        }
    };

    const changeInput = async (val) => {
        const num = await retiraMascaraMaterNumeros(val);
        setInputValue(num);
    };

    const GRADIENTE = 'linear-gradient(to bottom, #d9d9d9, #ffffff, #d9d9d9)';

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent bgGradient={GRADIENTE}>
                <ModalHeader justifyContent={'center'} alignItems={'center'} py={'10px'}>
                    <Flex py={'10px'} justify={'center'} align={'center'}>
                        <Text color={textColor} fontSize={'20px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{titulo}</Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Flex flexDirection={'column'} h={'90px'} justify={'start'} align={'center'}>

                        <Flex justify='center' align='center'>
                            <Text color={'orange.700'} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} ml={'5px'}>{mesAndAno}</Text>
                        </Flex>

                        <Flex flexDirection={'column'} mt={'20px'} justify='center' align='center'>
                            <Box mb={'5px'} w={'100%'} >
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Valor em mililitros'}</Text>
                            </Box>
                            <InputGroup size='md'>
                                <Input
                                    value={inputValue}
                                    variant='filled'
                                    onChange={(e) => changeInput(e.target.value)}
                                    isInvalid={isValidInput}
                                    errorBorderColor='crimson'
                                    placeholder=""
                                    bg={'white'}
                                    color={textColor}
                                    borderColor={textColor}
                                    borderRadius="10px"
                                    size="md"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    w={'200px'}
                                    h={'35px'}
                                />
                                <InputRightElement display='flex' alignItems='center'>
                                    <Text color={'gray'} fontSize={'13px'} fontWeight={'bold'} mr={'10px'}>{'(mm)'}</Text>
                                </InputRightElement>
                            </InputGroup>
                        </Flex>
                        {isValidInput && <Text color={'red'} fontSize={'14px'} fontWeight={'light'}>{'Informe um valor'}</Text>}
                    </Flex>
                </ModalBody>
                <ModalFooter mt={'20px'} borderTopWidth={'1px'} borderColor={'blackAlpha.700'} justifyContent={'space-around'}>
                    <Button w={'100px'} variant="action" onClick={onPressCancel}>Cancelar</Button>
                    <Button w={'100px'} variant="miniBrand" onClick={handleConfirm}>Salvar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalChuvasAnuais;