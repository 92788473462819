import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    useColorModeValue,
    Select,
    SimpleGrid,
    Box,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import { calculoGeralRelatorio, carregarArvoresParaUmaSangria } from '../../../../util/CalculosRelatorioQualidadeSangria';
import { calculaTotalDePontosArvoreSemDivisao, ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO } from '../../../../util/FuncoesUteis';
import * as ActionTypes from '../../../../constants/ActionTypes';

const QualidadeSangriaPersonalizado = () => {

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [arrayQu, setArrayQu] = useState([]);
    const [mediaGeralDePontos, setMediaGeralDePontos] = useState(0);
    const [notaGeral, setNotaGeral] = useState(0);
    const [avaliacaoGeral, setAvaliacaoGeral] = useState('');
    const [corNotaGeral, setCorNotaGeral] = useState('');
    const [mesSelecionado, setMesSelecionado] = useState('');
    const [arrayMeses, setArrayMeses] = useState([]);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.3)', 'unset');
    const textColorInfo = useColorModeValue("gray.400", "white");

    useEffect(() => {
        async function loaderScreen() {
            localStorage.removeItem('@HE-propriedadeParaEdicao');

            const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
            const fim = localStorage.getItem('@HE-dtFimSafraAsync');

            const arrayDatas = await ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO(ini, fim);
            setArrayMeses(arrayDatas);

            const primeiroDiaDoMesCorrente = moment().startOf('month').format('YYYY-MM-DD');
            setMesSelecionado(primeiroDiaDoMesCorrente);

            const dtAtual = moment().format('YYYY-MM-DD');
            await pesquisaQualidadeSan(dtAtual);
            setTelaCarregada(true);
        }
        loaderScreen();
    }, [])

    const pesquisaQualidadeSan = async (dataPesquisa) => {
        try {
            const dt = moment(dataPesquisa).format('YYYY-MM-DD');
            const primeiroDiaDoMesCorrente = moment(dt).startOf('month').format('YYYY-MM-DD');
            const UltimoDiaDoMesCorrente = moment(dt).endOf('month').format("YYYY-MM-DD");
            let pontosGeral = 0;
            let notaGeral = 0;
            let qtdDivisao = 0;
            let ultimoId = 0;

            setArrayQu([]);
            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const url = `api/qualidadeSangria/relatorioQualidadeSangria?idUa=${ua}&idSafra=${idSafra}&dtInicio=${primeiroDiaDoMesCorrente}&dtFim=${UltimoDiaDoMesCorrente}`;

            const response = await Api.getAllSemAccessToken(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;

                let arrTemp = [];
                let mediaGeralDePontosX = 0;
                let notaGeralX = 0;
                let avaliacaoGeralX = 'N/A';
                let corNotaGeralX = "black";

                if (res.length > 0) {
                    /*
                    ╔══════════════════════════════════════════════════════════════════╗
                        PRECISEI ORDENAR DO MENOR PARA O MAIOR PARA TRABALHAR NA LOGINA
                        MAIS ABAIXO
                    ╚══════════════════════════════════════════════════════════════════╝ */
                    const arrayOrdenado = await res.sort(function (a, b) { return a.idQualidadeSangria - b.idQualidadeSangria });
                    ultimoId = arrayOrdenado[arrayOrdenado.length - 1].idQualidadeSangria;

                    await arrayOrdenado.forEach(async (res2) => {
                        const jsonDadosSeringueiro = {
                            idPessoa: res2.idPessoa,
                            nome: res2.nome,
                        }
                        /*
                        ╔══════════════════════════════════════════════════════════════════╗
                            CARREGAR AS ARVORES                        
                        ╚══════════════════════════════════════════════════════════════════╝ */
                        const arrayArvoresParaTela = await carregarArvoresParaUmaSangria(res2, jsonDadosSeringueiro);
                        let urlImagEvidencia = null;
                        if (res2.urlArquivo != null) {
                            const urlArq = res2.urlArquivo;
                            const imgP = urlArq.urlStorage;
                            urlImagEvidencia = imgP;
                        }

                        let totalDePontosP = 0;
                        const arr = res2.listArvore;

                        if (arr.length > 0) {
                            totalDePontosP = await calculaTotalDePontosArvoreSemDivisao(arr);
                        }
                        /*
                        ╔══════════════════════════════════════════════════════════════════╗
                            CALCULANDO VALORES E CORES DOS CARDS
                        ╚══════════════════════════════════════════════════════════════════╝ */
                        totalDePontosP = totalDePontosP.toFixed(2);
                        totalDePontosP = Number(totalDePontosP);

                        const pontosSangria = Number(res2.pontos);
                        let notaSeringueiroP = pontosSangria - totalDePontosP;
                        notaSeringueiroP = notaSeringueiroP.toFixed(2);
                        notaSeringueiroP = Number(notaSeringueiroP);

                        const idAvaliacao = res2.idAvaliacao != null && res2.idAvaliacao != undefined ? res2.idAvaliacao : 1;
                        const constSoParaCalculo = parseFloat(notaSeringueiroP.toFixed(0));

                        let avalia = '';
                        let corN = 'black';     // NAO COLOCAR NUMEROS NAS CORES TIPO green.500, POR CAUSA DO HTML
                        if (idAvaliacao == 1) {
                            corN = 'gray';
                            avalia = 'Branco';
                        } else if (idAvaliacao == 2) {
                            corN = 'green';
                            avalia = 'Boa';
                        } else if (idAvaliacao == 3) {
                            corN = 'orange';
                            avalia = 'Média';
                        } else if (idAvaliacao == 4) {
                            corN = 'red';
                            avalia = 'Ruim';
                        }

                        // let avalia = '';
                        // let corN = 'black';
                        // if (constSoParaCalculo >= 95) {
                        //     corN = 'green.500';
                        //     avalia = 'Boa';
                        // } else if (constSoParaCalculo <= 94 && constSoParaCalculo >= 90) {
                        //     corN = 'orange.600';
                        //     avalia = 'Média';
                        // } else {
                        //     corN = 'red';
                        //     avalia = 'Ruim';
                        // }

                        if (totalDePontosP > 0) {
                            pontosGeral = pontosGeral + totalDePontosP;
                            notaGeral = notaGeral + notaSeringueiroP;
                            qtdDivisao = qtdDivisao + 1;
                        }

                        const ss = parseFloat(notaSeringueiroP.toFixed(2));
                        const xx = parseFloat(totalDePontosP.toFixed(2));

                        const jsonF = {
                            idQualidadeSangria: res2.idQualidadeSangria,
                            idPessoa: res2.idPessoa,
                            nome: res2.nome,
                            observacao: res2.observacao != null ? res2.observacao : '',
                            descMes: res2.descMes,
                            avaliacaoFinalizada: res2.avaliacaoFinalizada,
                            dtPeriodoDisponivel: res2.dtPeriodoDisponivel,
                            listArvore: arrayArvoresParaTela,
                            notaSeringueiro: ss,
                            totalPontos: xx,
                            avaliacaoSangria: avalia,
                            corNota: corN,
                            urlImage: urlImagEvidencia,
                        }
                        arrTemp.push(jsonF);

                        if (ultimoId == res2.idQualidadeSangria) {
                            const res = await calculoGeralRelatorio(pontosGeral, notaGeral, qtdDivisao);
                            mediaGeralDePontosX = res.mediaGeralDePontos;
                            notaGeralX = res.notaGeral;
                            avaliacaoGeralX = res.avaliacaoGeral;
                            corNotaGeralX = res.corNotaGeral;

                            setMediaGeralDePontos(mediaGeralDePontosX);
                            setNotaGeral(notaGeralX);
                            setAvaliacaoGeral(avaliacaoGeralX);
                            setCorNotaGeral(corNotaGeralX);
                            setArrayQu(arrTemp);
                        }
                        const jsonQualidade = {
                            mediaGeralDePontos: mediaGeralDePontosX,
                            notaGeral: notaGeralX,
                            avaliacaoGeral: avaliacaoGeralX,
                            corNotaGeral: corNotaGeralX,
                            arrayQu: arrTemp,
                        }
                        localStorage.setItem('@HE-qualidadeSangria-personalizado', JSON.stringify(jsonQualidade));
                    })
                } else {
                    setMediaGeralDePontos(0);
                    setNotaGeral(0);
                    setAvaliacaoGeral('N/A');
                    setCorNotaGeral('black');

                    const jsonQualidade = {
                        mediaGeralDePontos: mediaGeralDePontosX,
                        notaGeral: notaGeralX,
                        avaliacaoGeral: avaliacaoGeralX,
                        corNotaGeral: corNotaGeralX,
                        arrayQu: arrTemp,
                    }
                    localStorage.setItem('@HE-qualidadeSangria-personalizado', JSON.stringify(jsonQualidade));
                }
            } else {
                console.log('a');
                return;
            }
        } catch (error) {
            console.log('Erro na classe RelatorioListaSerin metodo pesquisaQualidadeSan', error);
        }
    }

    const realizarPesquisaDoMesSelecionado = async (e) => {

        const dataSelecionada = e.target.value;
        const startTime = moment(dataSelecionada);
        const end = moment();
        const duration = moment.duration(startTime.diff(end));
        const dias = duration.asMonths();
        const no2 = parseFloat(dias.toFixed(0));

        if (no2 <= 0) {
            setMesSelecionado(dataSelecionada);
            const dtc = moment(dataSelecionada).format('YYYY-MM-DD');

            await pesquisaQualidadeSan(dtc);
        } else {

            return;
        }
    }

    function renderSelect() {
        return (
            <Box mt={'-10px'}>
                <Text color={textColor} fontSize='17px' lineHeight='100%' p={'5px'} ml={'25px'}>Meses</Text>
                <Select
                    value={mesSelecionado}
                    errorBorderColor='crimson'
                    placeholder="selecione"
                    variant="filled"
                    borderRadius="10px"
                    borderColor={textColor}
                    textColor={textColor}
                    fontSize={'13px'}
                    fontWeight={'bold'}
                    onChange={realizarPesquisaDoMesSelecionado}
                    w={'400px'}
                    h={'35px'}
                    ml={'20px'}
                >
                    {arrayMeses.map((item, index) => {
                        return (
                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                        )
                    })}
                </Select>
            </Box>
        )
    }

    function renderCards() {
        let mediaG = `${mediaGeralDePontos}`;
        mediaG = mediaG.length == 1 ? `${mediaG}.0` : mediaG;
        mediaG = mediaG.replace('.', ',');

        let notaG = `${notaGeral}`;
        notaG = notaG.length == 1 ? `${notaG}.0` : notaG;
        notaG = notaG.replace('.', ',');
        return (
            <SimpleGrid px={'20px'} columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'20px'}>
                <MiniStatistics name='Total de pontos' value={mediaG} />
                <MiniStatistics textColorValue={corNotaGeral} name='Nota dos seringueiro' value={`${notaG}%`} />
                <MiniStatistics textColorValue={corNotaGeral} name='Avaliação da sangria' value={avaliacaoGeral} />
            </SimpleGrid>
        )
    }

    function renderSeringueiros() {
        return (
            <Card direction='column' w='100%' px='10px' py='5px' mb='10px' bg={'rgba(242, 242, 242, 0.9)'} borderWidth={'3px'} borderRadius={'20px'} borderColor={'blackAlpha.300'}>
                {arrayQu.map((item, index) => {
                    let pontosS = `${item.totalPontos}`;
                    pontosS = pontosS.length == 1 ? `${pontosS}.0` : pontosS;
                    pontosS = pontosS.replace('.', ',');

                    let notaS = `${item.notaSeringueiro}`;
                    notaS = notaS.length == 1 ? `${notaS}.0` : notaS;
                    notaS = notaS.replace('.', ',');

                    return (
                        <Box
                            key={index}
                            borderRadius='20px'
                            p={'1px'}
                            bg={'white'}
                            mt={'5px'}
                            mb={'5px'}
                            boxShadow={cardShadow}
                            borderColor={'orange.600'}>
                            <Flex px='0px' justify='space-between' py={'10px'} align='center'>
                                <Box w={'100%'} >
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Nome</Text>
                                    <Text color={textColorInfo} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.nome}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Total de pontos</Text>
                                    <Text color={textColorInfo} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{pontosS}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Nota do seringueiro</Text>
                                    <Text color={item.corNota} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{`${notaS}%`}</Text>
                                </Box>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} mb={'5px'}>Avaliação</Text>
                                    <Text color={item.corNota} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.avaliacaoSangria}</Text>
                                </Box>
                            </Flex>
                        </Box>
                    )
                })}
            </Card>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Carregando...</Text>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='5px' py='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
            {renderSelect()}
            {renderCards()}
            {renderSeringueiros()}
        </Card>
    );
};

export default QualidadeSangriaPersonalizado;
