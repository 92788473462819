import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    useColorModeValue,
    Flex,
    Radio,
    RadioGroup,
    Wrap,
    WrapItem,
    Button,
    Select,
    SimpleGrid,
} from '@chakra-ui/react';
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { mascaraTelefone } from '../../../util/Masck';
import { ARRAY_PERMISSOES_MOCK } from 'model/MockData';
import MessageSreen from 'components/message/MessageSreen';

export default function FormPermitirAcessoPropriedade() {

    const [loading, setLoading] = useState(false);
    const [listUsuarios, setListUsuarios] = useState([]);
    const [listCombo, setListCombo] = useState([]);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState('');

    const [nomeUsuario, setNomeUsuario] = useState('');
    const [descTipoUsuario, setDescTipoUsuario] = useState('');
    const [numeroCelular, setNumeroCelular] = useState('');
    const [idTipoUsuarioP, setIdTipoUsuarioP] = useState(false);
    const [listPropriedadesUsuarioTemAcesso, setListPropriedadesUsuarioTemAcesso] = useState([]);

    const [listPropriedadesCombo, setListPropriedadesCombo] = useState([]);
    const [propriedadeSelecionado, setPropriedadeSelecionado] = useState('');
    const [PRESQUISAR, setPESQUISAR] = useState(true);
    const [SERINGUEIRO, setSERINGUEIRO] = useState(true);
    const [GERENTE, setGERENTE] = useState(false);
    const [PRODUTOR, setPRODUTOR] = useState(false);
    const [ADMIN, setADMIN] = useState(false);
    const [TECNICO, setTECNICO] = useState(false);
    const [DIRETOR, setDIRETOR] = useState(false);

    const [valuePermissao, setValuePermissao] = useState('2');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardShadow = useColorModeValue('0px 2px 5px rgba(0, 0, 0, 0.8)', 'unset');
    const gradienGray = 'linear-gradient(gray.300, gray.100, gray.300)';

    useEffect(() => {
        async function loaderScreen() {
            await carregarDadosTela();
        }
        loaderScreen();
    }, [])

    const carregarDadosTela = async () => {
        try {
            setLoading(true);
            const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');

            const url = `api/usuario/listaTodosOsUsuariosAndPermissoesAndPropriedadesAssociadas?idUsuario=${idUsuarioLogado}`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                const listaUsuarios = await res.listUsuarios;
                const dadosUsuario = await res.dadosUsuario;

                const listTodasUasComSafraAtiva = await res.listTodasUasComSafraAtiva;
                if (listTodasUasComSafraAtiva.length > 0) {

                    let arrUas = listTodasUasComSafraAtiva.map((item) => {
                        return {
                            value: `${item.idUa}`,
                            label: item.descricao,
                        }
                    });
                    setListPropriedadesCombo(arrUas);
                }
                if (dadosUsuario.length > 0) {
                    setListUsuarios(dadosUsuario);
                }

                if (listaUsuarios.length > 0) {
                    let arr = listaUsuarios.map((item) => {
                        return {
                            value: `${item.idUsuario}`,
                            label: item.nome,
                        }
                    })
                    setListCombo(arr);
                }
            } else {
                setLoading(false);

            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe FormPermitirAcessoPropriedade metodo carregarDadosTela', error);
        }
    }

    /*
    ╔══════════════════════════════════════════════════════════════════╗
        SALVAR PERMISSOES
    ╚══════════════════════════════════════════════════════════════════╝ */
    const salvarPermissoes = async () => {
        try {
            const arrayPermissoesP = [
                { idPermissao: 1, descricao: "ROLE_PESQUISAR", status: PRESQUISAR },
                { idPermissao: 2, descricao: "ROLE_GERENTE", status: SERINGUEIRO },
                { idPermissao: 3, descricao: "ROLE_SERINGUEIRO", status: GERENTE },
                { idPermissao: 4, descricao: "ROLE_PRODUTOR", status: PRODUTOR },
                { idPermissao: 5, descricao: "ROLE_ADMIN", status: ADMIN },
                { idPermissao: 6, descricao: "ROLE_TECNICO", status: TECNICO },
                { idPermissao: 7, descricao: "ROLE_DIRETOR", status: DIRETOR },
            ]

            const jsonPermissoes = {
                idUsuario: usuarioSelecionado,
                arrayPermissoes: arrayPermissoesP,
                idUa: propriedadeSelecionado,
                concederAcesso: valuePermissao == '1' ? true : false,
                idTipoUsuario: idTipoUsuarioP
            }

            setLoading(true);
            const response = await Api.putRequest(`api/usuario/concederOuRevogarAcessoPropriedade`, jsonPermissoes);
            setLoading(false);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                await carregarDadosTela();

                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            } else {
                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe FormPermitirAcessoPropriedade metodo salvarPermissoes', error);
        }
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const changePropriedade = async (e) => {
        const val = e.target.value;
        setPropriedadeSelecionado(val);

        setListPropriedadesUsuarioTemAcesso([]);
        setDescTipoUsuario('');
        setNomeUsuario('');
        setNumeroCelular('');
        setValuePermissao('2');
        setUsuarioSelecionado('');
    }

    const changeUsuario = async (e) => {
        const val = e.target.value;
        setUsuarioSelecionado(val);

        if (val != '') {
            const arr = listUsuarios.filter(x => x.idUsuario == Number(val));

            if (arr.length > 0) {
                const user = arr[0];

                const telefoneComMascara = mascaraTelefone(user.numeroCelular);
                setDescTipoUsuario(user.descTipoUsuario);
                setNomeUsuario(user.nome);
                setNumeroCelular(telefoneComMascara);
                setIdTipoUsuarioP(user.idTipoUsuario);

                let pesquisar = false;
                let gerente = false;
                let seringueiro = false;
                let produtor = false;
                let admin = false;
                let tecnico = false;
                let diretor = false;

                if (user.listUsuarioPermissao.length > 0) {
                    const arrPerm = user.listUsuarioPermissao;

                    ARRAY_PERMISSOES_MOCK.map((item, index) => {

                        if (arrPerm[index] != undefined) {
                            const xx = arrPerm[index].descricao;
                            if (xx === 'ROLE_PESQUISAR') pesquisar = true;
                            if (xx === 'ROLE_GERENTE') gerente = true;
                            if (xx === 'ROLE_SERINGUEIRO') seringueiro = true;
                            if (xx === 'ROLE_PRODUTOR') produtor = true;
                            if (xx === 'ROLE_ADMIN') admin = true;
                            if (xx === 'ROLE_TECNICO') tecnico = true;
                            if (xx === 'ROLE_DIRETOR') diretor = true;
                        }
                    })
                    setPESQUISAR(pesquisar);
                    setSERINGUEIRO(gerente);
                    setGERENTE(seringueiro);
                    setPRODUTOR(produtor);
                    setADMIN(admin);
                    setTECNICO(tecnico);
                    setDIRETOR(diretor);
                }

                if (user.listUaOndeUsuarioTemAcesso.length > 0) {

                    const listP = user.listUaOndeUsuarioTemAcesso;
                    setListPropriedadesUsuarioTemAcesso(listP);

                    let permicao = '2';
                    for (let i = 0; i < listP.length; i++) {
                        const element = listP[i];
                        if (propriedadeSelecionado == element.idUa) {
                            permicao = '1';
                        }
                    }
                    setValuePermissao(permicao);
                } else {
                    setListPropriedadesUsuarioTemAcesso([]);
                    setValuePermissao('2');
                }
            }
        } else {
            setPESQUISAR(false);
            setSERINGUEIRO(false);
            setGERENTE(false);
            setPRODUTOR(false);
            setADMIN(false);
            setDIRETOR(false);

            setDescTipoUsuario('');
            setNomeUsuario('');
            setNumeroCelular('');
        }
    }

    function renderPropriedadesAndUsuarios() {
        return (
            <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap='15px' mb='20px' mt={'20px'}>
                <Flex flexDirection={'column'} justify="center" align={'center'}>
                    <Box>
                        <Text ml={'15px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Propriedades</Text>
                        <Select
                            value={propriedadeSelecionado}
                            errorBorderColor='crimson'
                            placeholder="Selecione"
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={changePropriedade}
                            w={'400px'}
                            h={'35px'}
                            ml={'5px'}
                        >
                            {listPropriedadesCombo.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </Flex>
                <Flex flexDirection={'column'} justify="center" align={'center'}>
                    <Box>
                        <Text opacity={propriedadeSelecionado != '' ? '1' : '0.5'} ml={'15px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Usuários</Text>
                        <Select
                            value={usuarioSelecionado}
                            errorBorderColor='crimson'
                            placeholder="Selecione"
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={changeUsuario}
                            w={'400px'}
                            h={'35px'}
                            ml={'5px'}
                            disabled={propriedadeSelecionado != '' ? false : true}
                        >
                            {listCombo.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </Flex>
            </SimpleGrid>
        )
    }

    function renderDadosUsuario() {
        return (
            <Box mx={'10px'} my={'20px'} borderRadius={'10px'} bgGradient={gradienGray}>
                <Wrap spacing='2px' py={'5px'} px={'15px'} justify={'space-around'} align={'center'}>
                    <WrapItem>
                        <Flex h={'45px'} flexDirection={'column'} justify={'space-between'} align={'center'}>
                            <Text color={'gray.900'} fontSize='18px' fontWeight='bold' lineHeight={'100%'}>Usuário</Text>
                            <Text color={'gray.600'} fontSize='15px' fontWeight='medium' lineHeight={'100%'}>{nomeUsuario}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem>
                        <Flex h={'45px'} flexDirection={'column'} justify={'space-between'} align={'center'}>
                            <Text color={'gray.900'} fontSize='18px' fontWeight='bold' lineHeight={'100%'}>Celular</Text>
                            <Text color={'gray.600'} fontSize='15px' fontWeight='medium' lineHeight={'100%'}>{numeroCelular}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem>
                        <Flex h={'45px'} flexDirection={'column'} justify={'space-between'} align={'center'}>
                            <Text color={'gray.900'} fontSize='18px' fontWeight='bold' lineHeight={'100%'}>Perfil</Text>
                            <Text color={'gray.600'} fontSize='15px' fontWeight='medium' lineHeight={'100%'}>{descTipoUsuario}</Text>
                        </Flex>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderRarioGrupo() {
        const disabledX = propriedadeSelecionado != '' && usuarioSelecionado != '' ? false : true;
        return (
            <Box w={'400px'} mx={'20px'} borderWidth={'0px'} borderColor={'blackAlpha.400'} borderRadius={'10px'}>
                <RadioGroup onChange={(e) => { setValuePermissao(e) }} value={valuePermissao}>
                    <Wrap spacing='2px' py={'10px'} px={'15px'} justify={'start'} align={'center'}>
                        <WrapItem>
                            <Flex w='180px' justify={'start'} align={'center'}>
                                <Radio value='1' size="lg" borderWidth={'1px'} borderColor={'black'} isDisabled={disabledX}>
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Permitir acesso</Text>
                                </Radio>
                            </Flex>
                        </WrapItem>
                        <WrapItem>
                            <Flex w='180px' justify={'start'} align={'center'}>
                                <Radio value='2' size="lg" borderWidth={'1px'} borderColor={'black'} isDisabled={disabledX}>
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Não permitir acesso</Text>
                                </Radio>
                            </Flex>
                        </WrapItem>
                    </Wrap>
                </RadioGroup>
            </Box>
        )
    }

    function renderPropriedadesUsuarioTemAcesso() {
        return (
            <Box px={'10px'} py={'10px'} mx={'20px'} mt={'20px'} boxShadow={cardShadow} borderRadius={'10px'} >
                <Text mb={'10px'} color={textColor} fontSize='18px' fontWeight='700' lineHeight='100%' align={'center'}>Propriedades onde o usuário já tem acesso</Text>
                <Wrap spacing='5px' py={'5px'} justify={'start'} align={'center'}>
                    {listPropriedadesUsuarioTemAcesso.length > 0 ?
                        listPropriedadesUsuarioTemAcesso.map((item, index) => {
                            return (
                                <WrapItem key={index}>
                                    <Flex h={'35px'} w={'280px'} mt={'5px'} justify={'center'} align={'center'} borderWidth={'1px'} borderRadius={'10px'} borderColor={'blackAlpha.400'} bg={'blackAlpha.50'}>
                                        <Text color={'gray.500'} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.nomeFazenda}</Text>
                                    </Flex>
                                </WrapItem>
                            )
                        })
                        :
                        <Box>
                            <Text ml={'15px'} color={'gray.500'} fontSize='15px' fontWeight='100' lineHeight='100%' align={'center'}>{'N/A'}</Text>
                        </Box>
                    }
                </Wrap>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'1px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='space-between'>
                <Button isDisabled={propriedadeSelecionado != '' && usuarioSelecionado != '' ? false : true} variant="brand" onClick={salvarPermissoes}>Salvar</Button>
            </Wrap>
        )
    }

    return (
        <Box pt={{ base: '100px', md: '80px', xl: '60px' }}>
            <Card px='0px' py={'10px'}>
                {isVisibleAlert && renderAlert()}
                <Text mb={'20px'} color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%' align={'center'} >Permissão de Acesso à Propriedade</Text>
                {renderPropriedadesAndUsuarios()}
                {renderDadosUsuario()}
                {renderRarioGrupo()}
                {renderPropriedadesUsuarioTemAcesso()}
                {renderButton()}
            </Card>
        </Box>
    );
}
