import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Text,
    Box,
    Icon,
    Flex,
    Input,
    useColorModeValue,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function ModalExcluirUsuario({ isOpen, onPressCancel, onConfirm, valueInput = '', isValidSenha = true }) {

    const [inputValue, setInputValue] = useState(valueInput);
    const [isValidInput, setIsValidInput] = useState(false);
    const [show, setShow] = useState(false);
    const textColor = useColorModeValue("secondaryGray.900", "white");

    const handleClick = () => setShow(!show);

    const handleConfirm = () => {
        if (inputValue.trim().length < 1) {
            setIsValidInput(true);

        } else if (onConfirm) {
            onConfirm(inputValue); // Envia o valor para o pai
        }
    };

    const changeInput = (e) => {
        const pass = e;
        setInputValue(pass.trim());
    }

    const GRADIENTE = 'linear-gradient(to bottom, #d9d9d9, #ffffff, #d9d9d9)';

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='md'
        >
            <ModalOverlay />
            <ModalContent bgGradient={GRADIENTE}>
                <ModalHeader justifyContent={'center'} alignItems={'center'} py={'10px'}>
                    <Flex py={'10px'} justify={'center'} align={'center'}>
                        <Text color={textColor} fontSize={'20px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Exclusão de Conta'}</Text>
                    </Flex>
                </ModalHeader>
                <ModalBody px={'10px'}>
                    <Flex flexDirection={'column'} h={'150px'} justify={'start'} align={'center'}>
                        <Flex px={'10px'} justify='center' align='center'>
                            <Text color={'orange.700'} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} >{'Ao excluir sua conta, todos os seus dados serão permanentemente removidos, incluindo suas propriedades, safras e seringueiros cadastrados.'}</Text>
                        </Flex>
                        <Flex justify='center' align='center' mt={'30px'}>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'} >{'Informre suja senha para confirmar.'}</Text>
                        </Flex>
                        <Flex flexDirection={'column'} mt={'20px'} justify='center' align='center'>
                            <Box mb={'2px'} w={'100%'} >
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' align={'start'} ml={'10px'}>{'Senha'}</Text>
                            </Box>
                            <InputGroup size='md'>
                                <Input
                                    value={inputValue}
                                    variant='filled'
                                    onChange={(e) => changeInput(e.target.value)}
                                    isInvalid={!isValidSenha}
                                    errorBorderColor='crimson'
                                    placeholder=""
                                    type={show ? "text" : "password"}
                                    bg={'white'}
                                    color={textColor}
                                    borderColor={textColor}
                                    borderRadius="10px"
                                    size="md"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    w={'300px'}
                                    h={'35px'}
                                />
                                <InputRightElement display='flex' alignItems='center'>
                                    <Icon
                                        color={'gray.400'}
                                        _hover={{ cursor: "pointer" }}
                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                        onClick={handleClick}
                                    />
                                </InputRightElement>
                            </InputGroup>
                        </Flex>
                        {!isValidSenha && <Text color={'red'} fontSize={'15px'} fontWeight={'bold'}>{'Senha inválida!'}</Text>}
                    </Flex>
                </ModalBody>
                <ModalFooter mt={'50px'} borderTopWidth={'1px'} borderColor={'blackAlpha.700'} justifyContent={'space-around'}>
                    <Button w={'100px'} variant="action" onClick={onPressCancel}>Cancelar</Button>
                    <Button isDisabled={inputValue.trim().length > 5 ? false : true} w={'100px'} variant="miniBrand" onClick={handleConfirm}>Confirmar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalExcluirUsuario;