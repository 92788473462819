import React, { useEffect, useState } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    TableContainer,
    useColorModeValue,
    Button,
    Box,
    Image,
    Select,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { RiEdit2Fill, RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import LoaderBlack from 'components/loading/LoaderBlack';
import MessageSreen from 'components/message/MessageSreen';
import iconExcel from "assets/img/layout/excel_icon_download.png";

const qtdPage = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
];

const ListaProdutores = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [arrayProdutores, setArrayProdutores] = useState([]);

    const [listaProdutos, setListaProdutos] = useState([]);
    const [impuNomeSeringueiro, setImpuNomeSeringueiro] = useState('');
    const [checkEmProgramacao, setCheckEmProgramacao] = useState(false);
    const [checkDisponivel, setCheckDisponivel] = useState(false);
    const [checkListaTodos, setCheckListaTodos] = useState(true);
    const [checkListaTodosAdmin, setCheckListaTodosAdmin] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [objPermissoes, setObjPermissoes] = useState({});
    const [nomeRelatorio, setNomeRelatorio] = useState('Produtores-and-tecnicos');

    const textColorHeader = useColorModeValue("white", "white");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const val = localStorage.getItem('@HE-obj-com-permissoes-acesso');
            const obj = JSON.parse(val);
            setObjPermissoes(obj);

            const dia = moment().format('DD');
            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const nmRelatorio = `Producao-sangria-${dia}-${nmMes}-${ano}`;
            setNomeRelatorio(nmRelatorio);

            localStorage.removeItem('@HE-dadosProdutorParaEdicao');

            const url = `api/usuario/retornaUsuariosTipoProdutor?idTipoUsuario=1`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setArrayProdutores(res);
                setListaProdutos(res);
            }
        }
        loaderScreen();
    }, [])

    const totalPages = Math.ceil(listaProdutos.length / itemsPerPage);
    const paginatedData = listaProdutos.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const changePage = (e) => {
        const val = e.target.value;
        setItemsPerPage(val);
    }

    const gerarRelatorioExcel = async () => {
        setLoading(true);

        setTimeout(async () => {
            await gerarExcel();
            setLoading(false);
        }, 1000);
    }

    const gerarExcel = async () => {
        const idSafra = localStorage.getItem('@HE-idSafra');
        const json = {
            nome: impuNomeSeringueiro,
            emProgramacao: checkEmProgramacao,
            disponivel: checkDisponivel,
            listaTodos: checkListaTodos,
            listaTodosAdmin: checkListaTodosAdmin,
            idSafra: idSafra,
        }
        const url = `api/usuario/downloadRelatorioExcelProdutoresAndTecnicos`;
        await Api.postRequestDownloadExcel(url, json, nomeRelatorio);
    }

    const novorProdutor = () => {
        const json = {
            insertOuUpdate: ActionTypes.OPERACAO_INSERT,
            idTipoUsuario: ActionTypes.TIPO_USUARIO_PRODUTOR,
        }
        localStorage.setItem('@HE-dadosProdutorParaEdicao', JSON.stringify(json));
        history.push("/admin/produtores-form");
    }

    const editarProdutor = (item) => {
        const json = {
            idUsuario: item.idUsuario,
            insertOuUpdate: ActionTypes.OPERACAO_UPDATE,
            idTipoUsuario: ActionTypes.TIPO_USUARIO_PRODUTOR,
        }
        localStorage.setItem('@HE-dadosProdutorParaEdicao', JSON.stringify(json));
        history.push("/admin/produtores-form");
    }

    function renderSeringueiros() {
        let qtdFim = currentPage * itemsPerPage;
        let qtdIni = (qtdFim - itemsPerPage) + 1;
        return (
            <Flex px={'10px'} direction="column" align="center">
                <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'}>
                    <Table variant="striped" colorScheme='blackAlpha' size="sm">
                        <Thead>
                            <Tr h={'35px'} bgGradient={gradientHeader}>
                                <Th w={'90%'} color={textColorHeader}>Nome</Th>
                                <Th w={'10%'} color={textColorHeader}>{''}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {paginatedData.map((item, index) => {
                                let colorIndicator = item.associadoEmSafraVigente == 'YES' ? 'green.500' : 'orange.500';
                                return (
                                    <Tr key={index}>
                                        <Td>
                                            <Flex justify='start' mb='5px' align='center'>
                                                <Text color={textColor} fontSize='15px' fontWeight={'normal'} lineHeight='100%'>{item.nome}</Text>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Flex align='center'>
                                                <Button
                                                    rightIcon={<RiEdit2Fill />}
                                                    variant="action"
                                                    key={index}
                                                    onClick={() => { editarProdutor(item) }}>Editar</Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                    <Flex px={'10px'} w={'100%'} mt={5} justify='space-between' align='center'>
                        <Flex justify='center' align='center'>
                            <Text color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>Qtd Linhas</Text>
                            <Box ml={'10px'}>
                                <Select
                                    value={itemsPerPage}
                                    placeholder=""
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={'gray.200'}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    textColor={textColor}
                                    size="sm"
                                    h={'35px'}
                                    onChange={changePage}>
                                    {qtdPage.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                        </Flex>
                        <Flex justify='center' align='center'>
                            <Text mr={'20px'} color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>{`${qtdIni}-${qtdFim} de ${listaProdutos.length}`}</Text>
                            <Button variant="outline" leftIcon={<RiArrowLeftSLine />} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            <Button variant="outline" rightIcon={<RiArrowRightSLine />} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} ml={2} />
                        </Flex>
                    </Flex>
                </TableContainer>
            </Flex>
        )
    }

    function renderButton() {
        return (
            <Flex px='15px' justify='end' my='10px' align='center'>
                <Button variant="miniBrand" onClick={() => { novorProdutor() }}>Novo</Button>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' px='0px' py='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
                <Flex px={'25px'} py={'10px'} justify={'space-between'} align='center'>
                    <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%'>{''}</Text>
                    <Text color={'orange.600'} fontSize='20px' fontWeight='700' lineHeight='100%'>Lista de Produtores</Text>
                    <Image cursor={'pointer'} boxSize='35px' objectFit='contain' src={iconExcel} alt='Dan Abramov' onClick={() => { gerarRelatorioExcel() }} />
                </Flex>
                {renderButton()}
                {renderSeringueiros()}
                <LoaderBlack isOpen={loading} />
            </Card>
        </Box>
    );
};

export default ListaProdutores;
