import moment from 'moment';
import { retornaNomeDoMesMaiusculo } from './FuncoesUteis';


export async function CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO(arraySeringueirosComMeses, seringueiroSelecionado = false) {
    let result = null;
    try {
        const objProducao = arraySeringueirosComMeses;
        const totalRealizado = objProducao.totalRealizadoString != null && objProducao.totalRealizadoString != '' ? objProducao.totalRealizadoString : '0';
        const totalPrevisto = objProducao.totalPrevistoString != null && objProducao.totalPrevistoString != '' ? objProducao.totalPrevistoString : '0';
        const coaguloRealizado = objProducao.coaguloRealizado != null && objProducao.coaguloRealizado != '' ? objProducao.coaguloRealizado : '0';
        const coaguloPrevisto = objProducao.coaguloPrevisto != null && objProducao.coaguloPrevisto != '' ? objProducao.coaguloPrevisto : '0';
        const objetivoAlcancado = objProducao.objetivoAlcancado != null && objProducao.objetivoAlcancado != '' ? objProducao.objetivoAlcancado : '0';
        const saldoAcumulado = objProducao.saldoAcumulado != null && objProducao.saldoAcumulado != '' ? objProducao.saldoAcumulado : '0';
        const totalArvoresSangradas = objProducao.totalArvoresSangradasString != null && objProducao.totalArvoresSangradasString != '' ? objProducao.totalArvoresSangradasString : '0';
        const totalDistribuicao = objProducao.totalDistribuicaoString != null && objProducao.totalDistribuicaoString != '' ? objProducao.totalDistribuicaoString : '0';
        const totalPercentual = objProducao.totalPercentualString != null && objProducao.totalPercentualString != '' ? objProducao.totalPercentualString : '0';

        const totalKilogramaDeCortePropriedade = objProducao.totalKilogramaDeCortePropriedade != null && objProducao.totalKilogramaDeCortePropriedade != '' ? objProducao.totalKilogramaDeCortePropriedade : '0';   // CAMPO SO PARA PROPRIEDADE
        const totalSangriasRealizada = objProducao.totalSangriasRealizada != null && objProducao.totalSangriasRealizada != '' ? objProducao.totalSangriasRealizada : '0';
        const totalKilogramaDeCorte = objProducao.totalKilogramaDeCorte != null && objProducao.totalKilogramaDeCorte != '' ? objProducao.totalKilogramaDeCorte : '0';
        const totalPrevisaoKg = objProducao.totalPrevisaoKg != null && objProducao.totalPrevisaoKg != '' ? objProducao.totalPrevisaoKg : '0';
        const totalPercentualPrevisaoKg = objProducao.totalPercentualPrevisaoKg != null && objProducao.totalPercentualPrevisaoKg != '' ? objProducao.totalPercentualPrevisaoKg : '0';
        
        let porcentagemRealizado = objProducao.porcentagemRealizado != null ? Number(objProducao.porcentagemRealizado).toFixed(0)  : '0';
        let producaoCoagulosPorArvorePrevista = objProducao.producaoCoagulosPorArvorePrevista != null ? Number(objProducao.producaoCoagulosPorArvorePrevista).toFixed(2) : '0';
        let producaoCoagulosPorArvoreRealizada = objProducao.producaoCoagulosPorArvoreRealizada != null ? Number(objProducao.producaoCoagulosPorArvoreRealizada).toFixed(2) : '0';

        porcentagemRealizado = porcentagemRealizado.replace('.', ',');
        producaoCoagulosPorArvorePrevista = producaoCoagulosPorArvorePrevista.replace('.', ',');
        producaoCoagulosPorArvoreRealizada = producaoCoagulosPorArvoreRealizada.replace('.', ',');

        var array = null;
        const listMeses = objProducao.listMeses;
        if (listMeses.length > 0) {

            await new Promise(resolve => {
                resolve(
                    array = listMeses.map(function (item) {

                        let previstoGraficoP = 0;
                        let realizadoGraficoP = 0;
                        let percentualGraficoP = 0;

                        if (item.previstoString != null && item.previstoString != 0 && item.previstoString != '') {
                            const numFinal2 = item.previstoString.replace('.', '');
                            previstoGraficoP = Number(numFinal2);
                        } else {
                            previstoGraficoP = 0;
                        }

                        if (item.realizadoString != null && item.realizadoString != 0 && item.realizadoString != '') {
                            const numFinal2 = item.realizadoString.replace('.', '');
                            realizadoGraficoP = Number(numFinal2);
                        } else {
                            realizadoGraficoP = 0;
                        }

                        if (item.percentualString != null && item.percentualString != 0 && item.percentualString != '') {
                            const num = Number(item.percentualString);
                            const p1 = Math.round(num / 2);
                            percentualGraficoP = p1;
                        } else {
                            percentualGraficoP = 0;
                        }                        

                        return {
                            idUa: objProducao.idUa,
                            idSafra: objProducao.idSafra,
                            idPessoa: item.idPessoa,
                            mes: item.mes,
                            arvoresSangradas: item.arvoresSangradasString,
                            distribuicao: item.distribuicao,
                            previsto: item.previstoString,
                            realizado: item.realizadoString,
                            percentual: item.percentualString,
                            previstoGrafico: previstoGraficoP,
                            realizadoGrafico: realizadoGraficoP,
                            percentualGrafico: percentualGraficoP,
                            data: item.data != null && item.data != '' ? moment(item.data).format('YYYY/MM/DD') : '',
                            totalAteMesAnterior: item.totalAteMesAnterior,
                            listSeringueiroDeletado: item.listSeringueiroDeletado,
                            listSeringueiroIncluido: item.listSeringueiroIncluido,
                            sangriasRealizadas: item.sangriasRealizadas,
                            kilogramaDeCorte: item.kilogramaDeCorte,
                            kilogramaDeCortePropriedade: item.kilogramaDeCortePropriedade,
                            previsaoKg: item.previsaoKg,
                            percentualPrevisaoKg: item.percentualPrevisaoKg,
                        };
                    })
                )
            });
        }
        result = {
            totalRealizado: totalRealizado,
            totalPrevisto: totalPrevisto,
            coaguloRealizado: coaguloRealizado,
            coaguloPrevisto: coaguloPrevisto,
            objetivoAlcancado: objetivoAlcancado,
            saldoAcumulado: saldoAcumulado,
            totalArvoresSangradas: totalArvoresSangradas,
            totalDistribuicao: totalDistribuicao,
            totalPercentual: totalPercentual,
            porcentagemRealizado: porcentagemRealizado,
            totalSangriasRealizada: totalSangriasRealizada,
            totalKilogramaDeCorte: totalKilogramaDeCorte,
            totalKilogramaDeCortePropriedade: totalKilogramaDeCortePropriedade,
            producaoCoagulosPorArvorePrevista: producaoCoagulosPorArvorePrevista,
            producaoCoagulosPorArvoreRealizada: producaoCoagulosPorArvoreRealizada,
            totalPrevisaoKg: totalPrevisaoKg,
            totalPercentualPrevisaoKg: totalPercentualPrevisaoKg,
            arrayProducaoAndGrafico: array,
        }

    } catch (error) {
        console.log('Erro na classe CalculoRelatorioSangriaAndProducao metodo CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO', error);
    }
    return result;
}

export async function CALCULAR_VALORES_GRAFICO_SANGRIA(array) {
    let result = [];
    try {

        for (let i = 0; i < array.length; i++) {
            const item = array[i];

            let qtdTarefasSangraveisGraficoP = 0;
            let qtdTarefasSangradasGraficoP = 0;
            let eficienciaDeSangriaGraficoP = 0;
            if (item.qtdTarefasSangraveis != null && item.qtdTarefasSangraveis != 0 && item.qtdTarefasSangraveis != '') {
                const num = Number(item.qtdTarefasSangraveis);

                let numFinal = num;
                if (num > 250) {
                    numFinal = 250;
                }
                if (num < 5) {
                    numFinal = 5;
                }
                numFinal = numFinal.toFixed(0);
                qtdTarefasSangraveisGraficoP = Number(numFinal);
            } else {
                qtdTarefasSangraveisGraficoP = 5;
            }

            if (item.qtdTarefasSangradas != null && item.qtdTarefasSangradas != 0 && item.qtdTarefasSangradas != '') {
                const num = Number(item.qtdTarefasSangradas);
                let numFinal = num;
                if (num > 250) {
                    numFinal = 250;
                }
                if (num < 5) {
                    numFinal = 5;
                }
                numFinal = numFinal.toFixed(0);
                qtdTarefasSangradasGraficoP = Number(numFinal);

            } else {
                qtdTarefasSangradasGraficoP = 5;
            }

            if (item.eficienciaDeSangriaGrafico != null && item.eficienciaDeSangriaGrafico != 0 && item.eficienciaDeSangriaGrafico != '') {
                const num = Number(item.eficienciaDeSangriaGrafico);

                let numFinal = num;
                if (num > 100) {
                    numFinal = 200;
                }
                if (num < 5) {
                    numFinal = 5;
                }

                if (num > 4 && num < 101) {
                    numFinal = num * 2;
                }
                numFinal = numFinal.toFixed(0);
                eficienciaDeSangriaGraficoP = Number(numFinal);

            } else {
                eficienciaDeSangriaGraficoP = 5;
            }
            const json = {
                mes: item.mes,
                eficienciaDeSangria: item.eficienciaDeSangria,
                qtdTarefasSangraveis: item.qtdTarefasSangraveis,
                qtdTarefasSangradas: item.qtdTarefasSangradas,
                qtdTarefasSangraveisGrafico: qtdTarefasSangraveisGraficoP,
                qtdTarefasSangradasGrafico: qtdTarefasSangradasGraficoP,
                eficienciaDeSangriaGrafico: eficienciaDeSangriaGraficoP,
            }
            result.push(json);
        }
    } catch (error) {
        console.log('Erro na classe CalculoRelatorioSangriaAndProducao metodo CALCULAR_VALORES_GRAFICO_SANGRIA', error);
    }
    return result;
}

export async function RETORNA_ARRAY_COM_MESES_DA_SAFRA(dtInicioSafra, dtFimSafra) {
    let result = [];
    try {
        const obj = await retornaDataInicioAndFimParaPesquisa(dtFimSafra);
        const arrayDatas = await calcularIntervaloDeDatas(obj.dtInicio, obj.dtFim);

        let arr = [];
        for (let i = 0; i < arrayDatas.length; i++) {
            const item = arrayDatas[i];

            const nm = moment(item.inicio).format('MMMM');
            const nomeMesMaiusculo = await retornaNomeDoMesMaiusculo(nm);
            const numMes = moment(item.inicio).format('M');

            const x = {
                numeroMes: Number(numMes),
                status: true,
                dataInicio: item.inicio,
                dataFim: item.fim,
                mes: nomeMesMaiusculo,
            }
            arr.push(x);
        }
        result = arr;

    } catch (error) {
        console.log('Erro na classe CalculoRelatorioSangriaAndProducao metodo RETORNA_ARRAY_COM_MESES_DA_SAFRA', error);
    }
    return result;
}

async function calcularIntervaloDeDatas(dataInicio, dataFim) {
    const intervalo = [];
    let dataAtual = moment(dataInicio);

    const f = moment(dataFim).add(1, 'months').format('YYYY-MM-DD');

    while (dataAtual.isSameOrBefore(f)) {

        const inicioMes = dataAtual.startOf('month').format('YYYY-MM-DD');
        const fimMes = dataAtual.endOf('month').format('YYYY-MM-DD');

        intervalo.push({ inicio: inicioMes, fim: fimMes });

        dataAtual.add(1, 'months');
    }

    return intervalo;
}

export async function RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA(dtInicioSafra, dtFimSafra) {
    let result = [];
    try {
        const obj = await retornaDataInicioAndFimParaPesquisa(dtFimSafra);

        const arrayDatas = await calcularIntervaloDeDatasComUmValor(obj.dtInicio, obj.dtFim);

        let arr = [];
        for (let i = 0; i < arrayDatas.length; i++) {
            const item = arrayDatas[i];

            const nm = moment(item).format('MMMM');
            const nomeMesMaiusculo = await retornaNomeDoMesMaiusculo(nm);

            const x = {
                dtMes: item,
                mes: nomeMesMaiusculo,
                status: true,
            }
            arr.push(x);
        }

        result = arr;

    } catch (error) {
        console.log('Erro na classe CalculoRelatorioSangriaAndProducao metodo RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA', error);
    }
    return result;
}

async function calcularIntervaloDeDatasComUmValor(dataInicio, dataFim) {
    const datas = [];
    let dataAtual = moment(dataInicio);

    while (dataAtual.isSameOrBefore(dataFim)) {
        datas.push(dataAtual.format('YYYY-MM-DD'));
        dataAtual.add(1, 'months');
    }

    return datas;
}


export async function retornaDataInicioAndFimParaPesquisa(dtFimSafra) {

    const anoInicio = moment(dtFimSafra).subtract(1, 'years').format('YYYY');
    const anoFim = moment(dtFimSafra).format('YYYY');

    const stringInit = `${anoInicio}-09-01`;
    const stringFim = `${anoFim}-08-31`;

    const result = {
        dtInicio: stringInit,
        dtFim: stringFim,
    }
    return result;
}
