import React, { useState, useEffect } from "react";
import {
    Flex,
    useColorModeValue,
    Box,
    Text,
    Input,
    Button,
    SimpleGrid,
    Select,
    Badge,
    Wrap,
    WrapItem,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import MessageSreen from 'components/message/MessageSreen';
import moment from 'moment';

export default function TarefasSafraList() {

    const maxCaracter = 3;
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');

    const [freque1, setFreque1] = useState('');
    const [freque2, setFreque2] = useState('');
    const [freque3, setFreque3] = useState('');
    const [freque4, setFreque4] = useState('');
    const [freque5, setFreque5] = useState('');
    const [freque6, setFreque6] = useState('');
    const [freque7, setFreque7] = useState('');

    const [showInput1, setShowInput1] = useState(true);
    const [showInput2, setShowInput2] = useState(true);
    const [showInput3, setShowInput3] = useState(true);
    const [showInput4, setShowInput4] = useState(true);
    const [showInput5, setShowInput5] = useState(true);
    const [showInput6, setShowInput6] = useState(true);
    const [showInput7, setShowInput7] = useState(true);

    const [listUa, setListUa] = useState([]);
    const [uaSelecionado, setUaSelecionado] = useState('');
    const [listUaCompleto, setListUaCompleto] = useState([]);
    const [listSafra, setListSafra] = useState([]);
    const [safraSelecionado, setSafraSelecionado] = useState('');
    const [listTarefasOriginal, setListTarefasOriginal] = useState([]);
    const [listPessoa, setListPessoa] = useState([]);
    const [pessoaSelecionado, setPessoaSelecionado] = useState('');
    const [vigente, setVigente] = useState(true);
    const [sangriaAtiva, setSangriaAtiva] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const cardShadow = useColorModeValue('0px 2px 10px rgba(0, 0, 0, 0.8)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');

            const response = await Api.getRequest(`api/ua/retornaPropriedadesAndSafrasQueUsuarioTemAcesso?idUsuario=${idUsuarioLogado}`);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                setListUaCompleto(res);

                if (res.length > 0) {
                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            res.map(function (item) {
                                const obj = {
                                    label: item.nomeFazenda,
                                    value: `${item.idUa}`
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListUa(arrayPess);
                }
            }
            setTelaCarregada(true);
        }
        loaderScreen();
    }, []);

    const validarTarefas = async () => {
        const valid = await validarInputs();

        if (valid) {
            setNumStatusResposta(ActionTypes.CODIGO_VERIFICAR_CAMPOS_OBRIGATORIOS);
            showAlert();
        } else {
            salvarTarefas();
        }
    }

    const salvarTarefas = async () => {
        try {
            const arrayTarefas = await criarJsonTarefa();
            const jsonTarefas = {
                idSafra: safraSelecionado,
                idPessoa: pessoaSelecionado,
                listTarefaSafra: arrayTarefas,
            }

            const response = await Api.putRequest(`tarefaSafra/insertAndUpdateTarefaSafra`, jsonTarefas);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            console.log('Error na classe TarefasSafraList metodo salvarValores', error);
        }
    }

    async function criarJsonTarefa() {
        let arrayPess = [];
        await new Promise(resolve => {
            resolve(
                listTarefasOriginal.map(function (item) {

                    let letraCustomizada = '';
                    if (item.idTarefa == 1) {
                        letraCustomizada = freque1;

                    } else if (item.idTarefa == 2) {
                        letraCustomizada = freque2;

                    } else if (item.idTarefa == 3) {
                        letraCustomizada = freque3;

                    } else if (item.idTarefa == 4) {
                        letraCustomizada = freque4;

                    } else if (item.idTarefa == 5) {
                        letraCustomizada = freque5;

                    } else if (item.idTarefa == 6) {
                        letraCustomizada = freque6;

                    } else if (item.idTarefa == 7) {
                        letraCustomizada = freque7;

                    }
                    const obj = {
                        ...item,
                        letraCustomizada: letraCustomizada,
                    }
                    arrayPess.push(obj);
                })
            )
        });
        return arrayPess;
    }

    const showAlert = () => {
        window.scrollTo(0, 0);

        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const validarInputs = async () => {
        let valid = false;

        if (freque1.trim().length < 1 && !showInput1) { valid = true }
        if (freque2.trim().length < 1 && !showInput2) { valid = true }
        if (freque3.trim().length < 1 && !showInput3) { valid = true }
        if (freque4.trim().length < 1 && !showInput4) { valid = true }
        if (freque5.trim().length < 1 && !showInput5) { valid = true }
        if (freque6.trim().length < 1 && !showInput6) { valid = true }
        if (freque7.trim().length < 1 && !showInput7) { valid = true }

        return valid;
    }

    const changeFreque1 = (e) => {
        const val = e.trim();
        setFreque1(val);
    }
    const changeFreque2 = (e) => {
        const val = e.trim();
        setFreque2(val);
    }
    const changeFreque3 = (e) => {
        const val = e.trim();
        setFreque3(val);
    }
    const changeFreque4 = (e) => {
        const val = e.trim();
        setFreque4(val);
    }
    const changeFreque5 = (e) => {
        const val = e.trim();
        setFreque5(val);
    }
    const changeFreque6 = (e) => {
        const val = e.trim();
        setFreque6(val);
    }
    const changeFreque7 = (e) => {
        const val = e.trim();
        setFreque7(val);
    }

    const changeUa = async (val, res) => {
        try {
            limpaCampos();
            setSafraSelecionado('');
            setPessoaSelecionado('');
            setUaSelecionado(val);
            if (val != '') {
                const arrayUa = res.filter(x => x.idUa == val);
                const safras = arrayUa.length > 0 ? arrayUa[0].listaSafraItem : [];
                if (safras.length > 0) {
                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            safras.map(function (item) {
                                const obj = {
                                    label: item.nomeSafra,
                                    value: `${item.idSafra}`
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListSafra(arrayPess);
                } else {
                    setListSafra([]);
                    setListPessoa([]);
                }
            }
        } catch (error) {
            console.log('Error na classe TarefasSafraList metodo changeUa', error);
        }
    }

    const changeSafra = async (val) => {
        try {
            limpaCampos();
            setPessoaSelecionado('');
            setSafraSelecionado(val);
            if (val != '') {
                const url = `api/pessoa/listaPessoaPorSafra?idSafra=${val}`;
                const response = await Api.getRequest(url);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;
                    if (res.length > 0) {
                        let arrayPess = [];
                        await new Promise(resolve => {
                            resolve(
                                res.map(function (item) {
                                    let dtDeletado = '';
                                    let bgColor = 'white';
                                    if (item.dtDeletado != null) {
                                        bgColor = '#ffe6e6';
                                        dtDeletado = ` - desativado ${moment(item.dtDeletado).format('MM/YY')}`
                                    }
                                    const obj = {
                                        label: `${item.nome} ${dtDeletado}`,
                                        value: `${item.idPessoa}`,
                                        color: bgColor
                                    }    
                                    arrayPess.push(obj);
                                })
                            )
                        });
                        setListPessoa(arrayPess);
                    } else {
                        setListPessoa([]);
                    }
                } else {
                    //setLoading(false);
                }
            }
        } catch (error) {
            console.log('Error na classe TarefasSafraList metodo changeSafra', error);
        }
    }

    const pesquiarTarefas = async (val) => {
        try {
            limpaCampos();
            setPessoaSelecionado(val);
            if (val != '') {
                //setLoading(true);
                const url = `tarefaSafra/retornaTarefasParaUmaSafra?idSafra=${safraSelecionado}&idPessoa=${val}`;
                const response = await Api.getRequest(url);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    setVigente(res.vigente);
                    setSangriaAtiva(res.sangriaAtiva);

                    const listTarefaSafraItem = res.listTarefaSafraItem;
                    setListTarefasOriginal(listTarefaSafraItem);

                    await carregarInputs(listTarefaSafraItem);
                    //setLoading(false);
                } else {
                    //setLoading(false);
                }
            }
        } catch (error) {
            //setLoading(false);
            console.log('Error na classe TarefasSafraList metodo pesquiarTarefas', error);
        }
    }

    async function carregarInputs(res = []) {
        for (let i = 0; i < res.length; i++) {
            const item = res[i];

            const val = item.letraCustomizada;

            if (item.idTarefa == 1) {
                setFreque1(val);
                setShowInput1(false);
            } else if (item.idTarefa == 2) {
                setFreque2(val);
                setShowInput2(false);
            } else if (item.idTarefa == 3) {
                setFreque3(val);
                setShowInput3(false);
            } else if (item.idTarefa == 4) {
                setFreque4(val);
                setShowInput4(false);
            } else if (item.idTarefa == 5) {
                setFreque5(val);
                setShowInput5(false);
            } else if (item.idTarefa == 6) {
                setFreque6(val);
                setShowInput6(false);
            } else if (item.idTarefa == 7) {
                setFreque7(val);
                setShowInput7(false);
            }
        }
    }

    const limpaCampos = () => {
        setFreque1('');
        setFreque2('');
        setFreque3('');
        setFreque4('');
        setFreque5('');
        setFreque6('');
        setFreque7('');
        setShowInput1(true);
        setShowInput2(true);
        setShowInput3(true);
        setShowInput4(true);
        setShowInput5(true);
        setShowInput6(true);
        setShowInput7(true);
    }

    function renderUaAndSafra() {
        //const ischeckd = listSafra.length > 0 ? false : true;
        const ischeckd = uaSelecionado != '' ? false : true;
        const ischeckPessoa = safraSelecionado != '' ? false : true;
        return (
            <Box position={'relative'}>
                <Box px={'5px'} mt={'10px'} py={'20px'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.700'} boxShadow={cardShadow} >
                    <Wrap spacing='2px' py={'5px'} px={'15px'} justify={'space-around'} align={'center'}>
                        <WrapItem>
                            <Flex flexDirection={'column'} justify='center' align='center'>
                                <Box>
                                    <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%'>Propriedades</Text>
                                </Box>
                                <Select
                                    value={uaSelecionado}
                                    errorBorderColor='crimson'
                                    placeholder="selecione"
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    fontSize={'15px'}
                                    fontWeight={'bold'}
                                    onChange={(e) => changeUa(e.target.value, listUaCompleto)}
                                    w={'250px'}
                                    h={'35px'}>
                                    {listUa.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Flex>
                        </WrapItem>
                        <WrapItem>
                            <Flex flexDirection={'column'} justify='center' align='center'>
                                <Box>
                                    <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%'>Safras</Text>
                                </Box>
                                <Select
                                    value={safraSelecionado}
                                    errorBorderColor='crimson'
                                    placeholder="selecione"
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    fontSize={'15px'}
                                    fontWeight={'bold'}
                                    onChange={(e) => changeSafra(e.target.value)}
                                    w={'250px'}
                                    h={'35px'}
                                    disabled={ischeckd}>
                                    {listSafra.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Flex>
                        </WrapItem>
                        <WrapItem>
                            <Flex flexDirection={'column'} justify='center' align='center'>
                                <Box>
                                    <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%'>Seringueiros</Text>
                                </Box>
                                <Select
                                    value={pessoaSelecionado}
                                    errorBorderColor='crimson'
                                    placeholder="selecione"
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    fontSize={'15px'}
                                    fontWeight={'bold'}
                                    onChange={(e) => pesquiarTarefas(e.target.value)}
                                    w={'250px'}
                                    h={'35px'}
                                    disabled={ischeckPessoa}>
                                    {listPessoa.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: item.color }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Flex>
                        </WrapItem>
                    </Wrap>
                </Box>
                <Box position={'absolute'} top={'15px'} right={'10px'}>
                    {safraSelecionado != '' && pessoaSelecionado != '' && (
                        <Box>
                            {sangriaAtiva ?
                                <Badge colorScheme='green' variant='solid'>Vigente</Badge>
                                :
                                <Badge colorScheme='red' variant='solid'>Finalizada</Badge>
                            }
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }

    function renderInputs() {
        const fontSizeP = '15px';
        return (
            <Box
                my={'20px'}
                w={'100%'}
                borderWidth={'1px'}
                borderRadius={'10px'}
                borderColor={'blackAlpha.600'}>
                <Text color={'blackAlpha.900'} fontSize={'15px'} fontWeight={'bold'} align={'center'}>Tarefas/Lote</Text>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 7, "2xl": 7 }} gap='5px' mb='20px' mt={'5px'} >
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text opacity={showInput1 ? 0.5 : 1} color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'center'}>1</Text>
                            <Input
                                value={freque1}
                                onChange={(e) => changeFreque1(e.target.value)}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                type="text"
                                fontSize={'15px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                maxLength={maxCaracter}
                                disabled={showInput1}
                            />
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text opacity={showInput2 ? 0.5 : 1} color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'center'}>2</Text>
                            <Input
                                value={freque2}
                                onChange={(e) => changeFreque2(e.target.value)}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                type="text"
                                fontSize={'15px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                maxLength={maxCaracter}
                                disabled={showInput2}
                            />
                        </Box>
                    </Card>
                    <Card px='10px' py='1px'>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                            <Text opacity={showInput3 ? 0.5 : 1} color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'center'}>3</Text>
                            <Input
                                value={freque3}
                                onChange={(e) => changeFreque3(e.target.value)}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                type="text"
                                fontSize={'15px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                maxLength={maxCaracter}
                                disabled={showInput3}
                            />
                        </Box>
                    </Card>
                    {!showInput4 ?
                        <Card px='10px' py='1px'>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                                <Text opacity={showInput4 ? 0.5 : 1} color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'center'}>4</Text>
                                <Input
                                    value={freque4}
                                    onChange={(e) => changeFreque4(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'15px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={maxCaracter}
                                    disabled={showInput4}
                                />
                            </Box>
                        </Card>
                        :
                        <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' align={'center'}></Text>
                    }
                    {!showInput5 ?
                        <Card px='10px' py='1px'>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                                <Text opacity={showInput5 ? 0.5 : 1} color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'center'}>5</Text>
                                <Input
                                    value={freque5}
                                    onChange={(e) => changeFreque5(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'15px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={maxCaracter}
                                    disabled={showInput5}
                                />
                            </Box>
                        </Card>
                        :
                        <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' align={'center'}></Text>
                    }
                    {!showInput6 ?
                        <Card px='10px' py='1px'>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                                <Text opacity={showInput6 ? 0.5 : 1} color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'center'}>6</Text>
                                <Input
                                    value={freque6}
                                    onChange={(e) => changeFreque6(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'15px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={maxCaracter}
                                    disabled={showInput6}
                                />
                            </Box>
                        </Card>
                        :
                        <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' align={'center'}></Text>
                    }
                    {!showInput7 ?
                        <Card px='10px' py='1px'>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 0 }} w={'100%'} >
                                <Text opacity={showInput7 ? 0.5 : 1} color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'center'}>7</Text>
                                <Input
                                    value={freque7}
                                    onChange={(e) => changeFreque7(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'15px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    maxLength={maxCaracter}
                                    disabled={showInput7}
                                />
                            </Box>
                        </Card>
                        :
                        <Text color={textColor} fontSize={fontSizeP} fontWeight={'bold'} lineHeight='100%' align={'center'}></Text>
                    }
                </SimpleGrid>
                <Text color={'blackAlpha.600'} fontSize={'15px'} fontWeight={'bold'} align={'center'}>Máximo 3 caracteres</Text>
            </Box>
        )
    }

    function renderBotton() {
        return (
            <Flex justify='flex-end' align='center'>
                <Button isDisabled={pessoaSelecionado == '' ? true : false} variant="brand" onClick={validarTarefas}>Salvar</Button>
            </Flex>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px={'20px'} py={'10px'} >
                {isVisibleAlert && renderAlert()}
                <Box>
                    <Flex flexDirection={'column'} px='10px' justify='center' align='center'>
                        <Text color={textColor} fontSize='18px' fontWeight='bold' lineHeight='100%'>{'Customização das tarefas'}</Text>
                        <Text color={textColor} fontSize='15px' fontWeight='light' lineHeight='100%' py={'5px'} >{'Informe como deseja visualizar as tarefas do seringueiro.'}</Text>
                    </Flex>
                </Box>
                {renderUaAndSafra()}
                {renderInputs()}
                {renderBotton()}
            </Card>
        </Box>
    );
}
