import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    useColorModeValue,
    Text,
    Box,
    SimpleGrid,
    Image,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import moment from 'moment';
import { RETORNA_NOME_SEMANA } from '../../../util/Masck';
import { retornaNomeDoMes } from '../../../util/FuncoesUteis';
import { useHistory } from "react-router-dom";
import Tempo01 from 'assets/img/layout/tempo01.png';
import Tempo02 from 'assets/img/layout/tempo02.png';
import Tempo03 from 'assets/img/layout/tempo03.png';
import Tempo04 from 'assets/img/layout/tempo04.png';
import Tempo09 from 'assets/img/layout/tempo09.png';
import Tempo10 from 'assets/img/layout/tempo10.png';
import Tempo11 from 'assets/img/layout/tempo11.png';
import Tempo13 from 'assets/img/layout/tempo13.png';
import ArrowBlue from 'assets/img/layout/arrowBlue.png';
import ArrowRed from 'assets/img/layout/arrowRed.png';
import Gota from 'assets/img/layout/gota.png';
import VentoImg from 'assets/img/layout/vento.png';
import ChuvasAnuais from 'views/admin/tempo/componentes/ChuvasAnuais';
import ChuvasDiarias from 'views/admin/tempo/componentes/ChuvasDiarias';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import iconPdf from "assets/img/layout/pdf_icon_download.png";
import LoaderBlack from 'components/loading/LoaderBlack';

export default function PrevisaoTempo() {

    const history = useHistory();
    const pdfRef = useRef();
    const [loading, setLoading] = useState(false);
    const [arrayTempo, setArrayTempo] = useState([]);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [nomeMesSelecionado, setNomeMesSelecionado] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');
    const [nomeUa, setNomeUa] = useState('');
    const [nomeRelatorio, setNomeRelatorio] = useState('Previsao-tempo');
    const [marginText, setMarginText] = useState('0px');
    const [widthImage, setWidthImage] = useState('25px');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientColorLingth = [
        'linear(to-tr, teal.100, yellow.100)',
        'linear(to-t, blue.100, teal.100)',
        'linear(to-b, midnightblue, orange.700)',
    ]
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loadScreen() {
            try {
                //window.scrollTo(0, 0);

                const nomeMunicipioP = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');
                const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
                const dia = moment().format('DD');
                const ano = moment().format('YYYY');
                const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
                const nmRelatorio = `Previsao-tempo-${dia}-${nmMes}-${ano}`;
                setNomeRelatorio(nmRelatorio);
                setNomeMesSelecionado(`${nmMes}/${ano}`);
                setNomeMunicipio(nomeMunicipioP);
                setIdUf(idUfP);

                const nomeUaP = localStorage.getItem('@HE-nomeUa');
                setNomeUa(nomeUaP);

                await pesquisarClimaTempo();
                setTelaCarregada(true);

            } catch (error) {
                setTelaCarregada(true);
            }
        }
        loadScreen();
    }, []);


    const pesquisarClimaTempo = async () => {

        const dataHora = moment().format('YYYY-MM-DD HH:mm:ss');
        const json = localStorage.getItem('@HE-jsonClimaTempoRespostaApiOpenweathermap');
        const horaDaUltimaPesquisa = localStorage.getItem('@HE-horaDaUltimaPesquisa');

        if (horaDaUltimaPesquisa != null && json != null) {

            const startTime = moment(horaDaUltimaPesquisa);
            const end = moment(dataHora);
            const duration = moment.duration(end.diff(startTime));
            const dias = duration.asHours();

            if (dias < 3) {
                //console.log('a ultima pesquisa tem menos de 3 horas ', dias.toFixed(2));
                const res = JSON.parse(json);
                loadData(res);

            } else {
                console.log('A ULTIMA PESQUISA TEM MAIS DE TRES HORAS, ENTAO RESLIZAR UMA NOVA ', dias.toFixed(2));
                await pesquisaNaApiMeteorologia();
            }
        } else {
            await pesquisaNaApiMeteorologia();
        }
    }

    async function pesquisaNaApiMeteorologia() {

        const city = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');
        const state = localStorage.getItem('@HE-idUfCadastradadoNaUa');
        const nameState = localStorage.getItem('@HE-idNomeUfCadastradadoNaUa');
        const country = 'BR';
        const language = 'pt_br';

        const url = `https://api.openweathermap.org/data/2.5/forecast?q=${city},${state},${country}&lang=${language}&units=metric&appid=6d7fb938e718ed95dc8648337509b06b`;
        const res = await getDataApi(url);
        if (res != null) {
            loadData(res);
        } else {
            const url2 = `https://api.openweathermap.org/data/2.5/forecast?q=${nameState},${country}&lang=${language}&units=metric&appid=6d7fb938e718ed95dc8648337509b06b`;
            const res2 = await getDataApi(url2);
            if (res2 != null) {
                loadData(res2);
            }
        }
    }

    async function getDataApi(url) {
        var response = null;
        try {
            await fetch(url)
                .then((res) => res.json())
                .then((data) => {
                    if (data?.cod && data.cod === "404") {
                        response = null;
                    } else {
                        response = data;
                    }
                });
        } catch (error) {
            console.log('erro ao pesquisar ClimaTempo', error);
        }
        return response;
    }

    async function calculoTempo(data) {

        let mm1 = 0;
        let mm2 = 0;
        let mm3 = 0;
        let mm4 = 0;
        let mm5 = 0;
        let mm6 = 0;
        let speed1 = 0;
        let speed2 = 0;
        let speed3 = 0;
        let speed4 = 0;
        let speed5 = 0;
        let speed6 = 0;
        let tempMax1 = 0;
        let tempMin1 = 100;
        let tempMax2 = 0;
        let tempMin2 = 100;
        let tempMax3 = 0;
        let tempMin3 = 100;
        let tempMax4 = 0;
        let tempMin4 = 100;
        let tempMax5 = 0;
        let tempMin5 = 100;
        let tempMax6 = 0;
        let tempMin6 = 100;
        const day = moment().format('YYYY-MM-DD');
        const day1 = moment(day).format('YYYY-MM-DD');
        const day2 = moment(day1).add(1, 'day').format('YYYY-MM-DD');
        const day3 = moment(day2).add(1, 'day').format('YYYY-MM-DD');
        const day4 = moment(day3).add(1, 'day').format('YYYY-MM-DD');
        const day5 = moment(day4).add(1, 'day').format('YYYY-MM-DD');
        const day6 = moment(day5).add(1, 'day').format('YYYY-MM-DD');

        if (data.list.length > 0) {
            const res = data.list;

            for (let i = 0; i < res.length; i++) {
                const val = res[i];
                const dt = moment(val.dt_txt).format('YYYY-MM-DD');

                if (dt == day1) {
                    tempMin1 = val.main.temp_min < tempMin1 ? val.main.temp_min : tempMin1;
                    tempMax1 = val.main.temp_max > tempMax1 ? val.main.temp_max : tempMax1;
                    speed1 = val.wind.speed > speed1 ? val.wind.speed : speed1;

                    const mmP = val.rain != null ? val.rain['3h'] : 0;
                    mm1 = mm1 + mmP;
                }
                if (dt == day2) {
                    tempMin2 = val.main.temp_min < tempMin2 ? val.main.temp_min : tempMin2;
                    tempMax2 = val.main.temp_max > tempMax2 ? val.main.temp_max : tempMax2;
                    speed2 = val.wind.speed > speed2 ? val.wind.speed : speed2;

                    const mmP = val.rain != null ? val.rain['3h'] : 0;
                    mm2 = mm2 + mmP;
                }
                if (dt == day3) {
                    tempMin3 = val.main.temp_min < tempMin3 ? val.main.temp_min : tempMin3;
                    tempMax3 = val.main.temp_max > tempMax3 ? val.main.temp_max : tempMax3;
                    speed3 = val.wind.speed > speed3 ? val.wind.speed : speed3;

                    const mmP = val.rain != null ? val.rain['3h'] : 0;
                    mm3 = mm3 + mmP;
                }
                if (dt == day4) {
                    tempMin4 = val.main.temp_min < tempMin4 ? val.main.temp_min : tempMin4;
                    tempMax4 = val.main.temp_max > tempMax4 ? val.main.temp_max : tempMax4;
                    speed4 = val.wind.speed > speed4 ? val.wind.speed : speed4;

                    const mmP = val.rain != null ? val.rain['3h'] : 0;
                    mm4 = mm4 + mmP;
                }
                if (dt == day5) {
                    tempMin5 = val.main.temp_min < tempMin5 ? val.main.temp_min : tempMin5;
                    tempMax5 = val.main.temp_max > tempMax5 ? val.main.temp_max : tempMax5;
                    speed5 = val.wind.speed > speed5 ? val.wind.speed : speed5;

                    const mmP = val.rain != null ? val.rain['3h'] : 0;
                    mm5 = mm5 + mmP;
                }
                if (dt == day6) {
                    tempMin6 = val.main.temp_min < tempMin6 ? val.main.temp_min : tempMin6;
                    tempMax6 = val.main.temp_max > tempMax6 ? val.main.temp_max : tempMax6;
                    speed6 = val.wind.speed > speed6 ? val.wind.speed : speed6;

                    const mmP = val.rain != null ? val.rain['3h'] : 0;
                    mm6 = mm6 + mmP;
                }
            }

            speed1 = speed1 * 3.6;          // CONVERTENDO m/s em km/h
            speed1 = speed1.toFixed(0);

            speed2 = speed2 * 3.6;
            speed2 = speed2.toFixed(0);

            speed3 = speed3 * 3.6;
            speed3 = speed3.toFixed(0);

            speed4 = speed4 * 3.6;
            speed4 = speed4.toFixed(0);

            speed5 = speed5 * 3.6;
            speed5 = speed5.toFixed(0);

            speed6 = speed6 * 3.6;
            speed6 = speed6.toFixed(0);

            tempMin1 = tempMin1.toFixed(0);
            tempMax1 = tempMax1.toFixed(0);

            tempMin2 = tempMin2.toFixed(0);
            tempMax2 = tempMax2.toFixed(0);

            tempMin3 = tempMin3.toFixed(0);
            tempMax3 = tempMax3.toFixed(0);

            tempMin4 = tempMin4.toFixed(0);
            tempMax4 = tempMax4.toFixed(0);

            tempMin5 = tempMin5.toFixed(0);
            tempMax5 = tempMax5.toFixed(0);

            tempMin6 = tempMin6.toFixed(0);
            tempMax6 = tempMax6.toFixed(0);

            mm1 = mm1.toFixed(0);
            mm2 = mm2.toFixed(0);
            mm3 = mm3.toFixed(0);
            mm4 = mm4.toFixed(0);
            mm5 = mm5.toFixed(0);
            mm6 = mm6.toFixed(0);

            const arr = [
                { tempMin: tempMin1, tempMax: tempMax1, speed: speed1, mm: mm1 },
                { tempMin: tempMin2, tempMax: tempMax2, speed: speed2, mm: mm2 },
                { tempMin: tempMin3, tempMax: tempMax3, speed: speed3, mm: mm3 },
                { tempMin: tempMin4, tempMax: tempMax4, speed: speed4, mm: mm4 },
                { tempMin: tempMin5, tempMax: tempMax5, speed: speed5, mm: mm5 },
                { tempMin: tempMin6, tempMax: tempMax6, speed: speed6, mm: mm6 },
            ]
            return arr;
        }
    }

    async function loadData(data) {

        const day = moment().format('YYYY-MM-DD HH:mm:ss');
        localStorage.setItem('@HE-horaDaUltimaPesquisa', day);
        localStorage.setItem('@HE-jsonClimaTempoRespostaApiOpenweathermap', JSON.stringify(data));

        let result = [];
        let isDay1 = false;
        let isDay2 = false;
        let isDay3 = false;
        let isDay4 = false;
        let isDay5 = false;
        let isDay6 = false;
        const day1 = moment(day).format('YYYY-MM-DD');
        const day2 = moment(day1).add(1, 'day').format('YYYY-MM-DD');
        const day3 = moment(day2).add(1, 'day').format('YYYY-MM-DD');
        const day4 = moment(day3).add(1, 'day').format('YYYY-MM-DD');
        const day5 = moment(day4).add(1, 'day').format('YYYY-MM-DD');
        const day6 = moment(day5).add(1, 'day').format('YYYY-MM-DD');

        const tempCalc = await calculoTempo(data);

        if (data.list.length > 0) {
            const res = data.list;

            for (let i = 0; i < res.length; i++) {
                const val = res[i];

                const dt = moment(val.dt_txt).format('YYYY-MM-DD');

                if (dt == day1 && isDay1 == false) {
                    isDay1 = true;
                    const obj = await carregaArreyTempo(val);
                    const objres = {
                        ...obj,
                        tempMin: tempCalc[0].tempMin,
                        tempMax: tempCalc[0].tempMax,
                        speed: tempCalc[0].speed,
                        mm: tempCalc[0].mm,
                    }
                    result.push(objres);
                }

                if (dt == day2 && isDay2 == false) {
                    isDay2 = true;
                    const obj = await carregaArreyTempo(val);
                    const objres = {
                        ...obj,
                        tempMin: tempCalc[1].tempMin,
                        tempMax: tempCalc[1].tempMax,
                        speed: tempCalc[1].speed,
                        mm: tempCalc[1].mm,
                    }
                    result.push(objres);
                }

                if (dt == day3 && isDay3 == false) {
                    isDay3 = true;
                    const obj = await carregaArreyTempo(val);
                    const objres = {
                        ...obj,
                        tempMin: tempCalc[2].tempMin,
                        tempMax: tempCalc[2].tempMax,
                        speed: tempCalc[2].speed,
                        mm: tempCalc[2].mm,
                    }
                    result.push(objres);
                }

                if (dt == day4 && isDay4 == false) {
                    isDay4 = true;
                    const obj = await carregaArreyTempo(val);
                    const objres = {
                        ...obj,
                        tempMin: tempCalc[3].tempMin,
                        tempMax: tempCalc[3].tempMax,
                        speed: tempCalc[3].speed,
                        mm: tempCalc[3].mm,
                    }
                    result.push(objres);
                }

                if (dt == day5 && isDay5 == false) {
                    isDay5 = true;
                    const obj = await carregaArreyTempo(val);
                    const objres = {
                        ...obj,
                        tempMin: tempCalc[4].tempMin,
                        tempMax: tempCalc[4].tempMax,
                        speed: tempCalc[4].speed,
                        mm: tempCalc[4].mm,
                    }
                    result.push(objres);
                }

                if (dt == day6 && isDay6 == false) {
                    isDay6 = true;
                    const obj = await carregaArreyTempo(val);
                    const objres = {
                        ...obj,
                        tempMin: tempCalc[5].tempMin,
                        tempMax: tempCalc[5].tempMax,
                        speed: tempCalc[5].speed,
                        mm: tempCalc[5].mm,
                    }
                    result.push(objres);
                }
            }
            setArrayTempo(result);
        }
    }

    const carregaArreyTempo = async (item) => {
        let result = null;
        try {
            let temperaturaP = `${item.main.temp}`;
            let chuvaP = item.weather != null ? item.weather[0].description : '';

            temperaturaP = temperaturaP.substring(0, 2);
            const nomeSemanaApreviado = await RETORNA_NOME_SEMANA(item.dt_txt);

            result = {
                nomeSemana: nomeSemanaApreviado,
                dt: moment(item.dt_txt).format('DD/MM/YYYY'),
                mesAndAno: moment(item.dt_txt).format('DD/MM'),
                temperatura: `${temperaturaP}`,
                icon: `http://openweathermap.org/img/wn/${item.weather[0].icon}@2x.png`,
                idIcon: item.weather[0].icon,
                country: 'BR',
                chuva: chuvaP,
            }

        } catch (error) {
            console.log('erro', error);
        }
        return result;
    }

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('15px');
        setWidthImage('15px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setWidthImage('25px');
            setLoading(false);
        }, 2000);
    }

    const gerarPDF = async () => {
        const input = pdfRef.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('landscape', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeigh = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeigh = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeigh / imgHeigh);
            const ratio22 = ratio - 0.02;
            pdf.addImage(imgData, 'PNG', 5, 10, pdfWidth - 10, imgHeigh * ratio22);
            pdf.save(`${nomeRelatorio}.pdf`);
        });
    }

    const telaLancarChuva = () => {
        history.push("/admin/lancar-chuvas");
    }

    function renderPrevisaoDoTempo() {
        const widthP = '120px';
        const heightP = '240px';
        const mtP = '5px';
        return (
            <Flex justify='space-between' align={'center'} my={'10px'}>
                {arrayTempo.map((item, index) => {
                    let img = item.icon;
                    if (item.idIcon == '01n' || item.idIcon == '01d') {
                        img = Tempo01;
                    } else if (item.idIcon == '02n' || item.idIcon == '02d') {
                        img = Tempo02;
                    } else if (item.idIcon == '03n' || item.idIcon == '03d') {
                        img = Tempo03;
                    } else if (item.idIcon == '04n' || item.idIcon == '04d') {
                        img = Tempo04;
                    } else if (item.idIcon == '09n' || item.idIcon == '09d') {
                        img = Tempo09;
                    } else if (item.idIcon == '10n' || item.idIcon == '10d') {
                        img = Tempo10;
                    } else if (item.idIcon == '11n' || item.idIcon == '11d') {
                        img = Tempo11;
                    } else if (item.idIcon == '13n' || item.idIcon == '13d') {
                        img = Tempo13;
                    }
                    return (
                        <Flex key={index} w={widthP} h={heightP} py={'5px'} mr={'25px'} flexDirection={'column'} justify='center' align='center' borderWidth={'1px'} borderRadius={'10px'} borderColor={'blackAlpha.300'} bg={'blackAlpha.100'}>

                            <Flex w={'100%'} justify='space-around' align='center'>
                                <Box w={'50%'}>
                                    <Text mb={marginText} color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>{item.nomeSemana}</Text>
                                </Box>
                                <Box w={'50%'}>
                                    <Text mb={marginText} color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>{item.mesAndAno}</Text>
                                </Box>
                            </Flex>

                            <Flex w={'100%'} justify='center' align='center'>
                                <Image objectFit='contain' src={img} w={'50px'} h={'45px'} alt={'img'} borderRadius={'10px'} />
                            </Flex>

                            <Flex w={'100%'} justify='space-around' align='center' mt={'0px'}>
                                <Box w={'40%'}>
                                    <Image objectFit='contain' w={widthImage} h={'25px'} src={ArrowRed} alt={'img'} />
                                </Box>
                                <Box w={'60%'}>
                                    <Text mb={marginText} color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>{`${item.tempMax}ºC`}</Text>
                                </Box>
                            </Flex>

                            <Flex w={'100%'} justify='space-around' align='center' mt={mtP}>
                                <Box w={'40%'}>
                                    <Image objectFit='contain' w={widthImage} h={'25px'} src={ArrowBlue} alt={'img'} />
                                </Box>
                                <Box w={'60%'}>
                                    <Text mb={marginText} color={textColor} fontWeight={'bold'} fontSize={'15px'} lineHeight={'100%'}>{`${item.tempMin}ºC`}</Text>
                                </Box>
                            </Flex>

                            <Flex w={'100%'} justify='space-around' align='center' mt={mtP}>
                                <Box w={'40%'}>
                                    <Image objectFit='contain' w={widthImage} h={'20px'} src={Gota} alt={'img'} />
                                </Box>
                                <Box w={'60%'}>
                                    <Text mb={marginText} color={textColor} fontWeight={'bold'} fontSize={'15px'} lineHeight={'100%'}>{item.mm}</Text>
                                </Box>
                            </Flex>

                            <Flex w={'100%'} justify='space-around' align='center' mt={mtP}>
                                <Box w={'40%'}>
                                    <Image objectFit='contain' w={'25px'} h={'20px'} src={VentoImg} alt={'img'} />
                                </Box>
                                <Box w={'60%'}>
                                    <Text mb={marginText} color={textColor} fontWeight={'bold'} fontSize={'15px'} lineHeight={'100%'}>{`${item.speed}`}&nbsp; km/h</Text>
                                </Box>
                            </Flex>
                            <Box w={'100%'} mt={'10px'}>
                                <Text mb={marginText} color={textColor} fontWeight={'bold'} fontSize={'15px'} lineHeight={'100%'}>{item.chuva}</Text>
                            </Box>
                        </Flex>
                    )
                })}
                <Flex w={widthP} h={heightP} flexDirection={'column'} justify='space-around' align='center' boxShadow={'lg'} borderWidth={'1px'} borderRadius={'10px'} borderColor={'blackAlpha.300'} bgGradient={gradientColorLingth}>
                    <Flex w={'100%'} justify='space-around' align='center' mt={mtP}>
                        <Text color={'white'} fontSize={'18px'} fontWeight='700' lineHeight={'100%'}>Resumo</Text>
                    </Flex>
                    <Flex flexDirection={'column'} w={'100%'} justify='center' align='center' mt={mtP}>
                        <Text color={'white'} fontSize={'13px'} fontWeight='700' lineHeight={'100%'}>Total de Chuva</Text>
                        <Text color={'gray.200'} fontSize={'13px'} fontWeight='300' lineHeight={'100%'}>2 - 4 mm</Text>
                    </Flex>
                    <Flex flexDirection={'column'} w={'100%'} justify='center' align='center' mt={mtP}>
                        <Text color={'white'} fontSize={'13px'} fontWeight='700' lineHeight={'100%'}>Dias Com Chuva</Text>
                        <Text color={'gray.200'} fontSize={'13px'} fontWeight='300' lineHeight={'100%'}>1 dia</Text>
                    </Flex>
                    <Flex flexDirection={'column'} w={'100%'} justify='center' align='center' mt={mtP}>
                        <Text color={'white'} fontSize={'13px'} fontWeight='700' lineHeight={'100%'}>Maior Vl. de chuva</Text>
                        <Text color={'gray.200'} fontSize={'13px'} fontWeight='300' lineHeight={'100%'}>4 mm</Text>
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'30px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px='10px' py='0px' align='center' direction='column' w='100%'>
                <Box px={'15px'} position={'relative'} pb={'20px'}>
                    <Box ref={pdfRef} w='100%'>
                        <Box px={'15px'}>
                            <Box position={'relative'}>
                                <Flex flexDirection={'column'} justify='center' align='center'>
                                    <Text color={'orange.600'} fontSize='20px' fontWeight={'bold'}>{`Previsão do Tempo`}</Text>
                                    <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{`${nomeMesSelecionado} - ${nomeUa}`}</Text>
                                    <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{`${nomeMunicipio}/${idUf}`}</Text>
                                </Flex>
                                <Box position={'absolute'} top={'1px'} left={'10px'}>
                                    <Image h='50px' w='140px' src={LogoHevea} alt='logo' />
                                </Box>
                            </Box>
                            {renderPrevisaoDoTempo()}
                            <Flex w={'100%'} mt={'50px'} mb={'5px'} justify='space-between' align='center'>
                                <Flex w={'50%'} justify='start' align='center'>
                                    <Text mb={marginText} color={textColor} fontWeight={'bold'} fontSize={'15px'} mr={'10px'} align={'start'} lineHeight={'100%'}>Milímetros Mensais</Text>
                                    <TooltipIcon
                                        label={
                                            <Box py={'5px'} >
                                                <Text align={'center'}>{'Milímetros Mensais'}</Text>
                                                <Text align={'start'} mt={'5px'}>{'Lançamentos cadastrados pelo usuário'}</Text>
                                            </Box>
                                        }
                                        height={'20px'} />
                                </Flex>



                                <Flex w={'50%'} justify='start' align='center'>
                                    <Text mb={marginText} color={textColor} fontWeight={'bold'} fontSize={'15px'} mr={'10px'} align={'start'} lineHeight={'100%'}>Milímetros Diário</Text>
                                    <TooltipIcon
                                        label={
                                            <Box py={'5px'} >
                                                <Text align={'center'}>{'Milímetros Diários'}</Text>
                                                <Text align={'start'} mt={'5px'}>{'Lançamentos cadastrados pelo usuário'}</Text>
                                            </Box>
                                        }
                                        height={'20px'} />
                                </Flex>
                            </Flex>
                        </Box>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='10px' spacing={3} mb={'20px'} px={'15px'}>
                            <SimpleGrid
                                columns={{ base: 1, md: 1, xl: 1 }}
                                gap='4px'
                                mb='2px'
                                borderWidth={'2px'}
                                borderRadius={'10px'}
                                borderColor={'blackAlpha.800'}
                                boxShadow='lg'>
                                <ChuvasAnuais marginText={marginText} />
                            </SimpleGrid>
                            <SimpleGrid
                                columns={{ base: 1, md: 1, xl: 1 }}
                                gap='4px'
                                mb='2px'
                                borderWidth={'2px'}
                                borderRadius={'10px'}
                                borderColor={'blackAlpha.800'}
                                boxShadow='lg'>
                                <ChuvasDiarias marginText={marginText} />

                                <Flex px={'10px'} pb={'10px'} justify='end' align='center'>
                                    <Button variant="brand" onClick={telaLancarChuva}>
                                        <Text mb={marginText} color={'white'} fontSize='14px' fontWeight={'bold'} lineHeight={'100%'}>{'Lançar Chuva'}</Text>
                                    </Button>
                                </Flex>
                            </SimpleGrid>
                        </SimpleGrid>
                    </Box>
                    <Box position={'absolute'} top={'5px'} right={'5px'}>
                        <Flex justify='end' align='center'>
                            <Image boxSize='35px' objectFit='contain' src={iconPdf} alt='Dan Abramov' cursor={'pointer'} onClick={downloadPdf} />
                        </Flex>
                    </Box>
                </Box>
                {loading && <LoaderBlack isOpen={loading} />}
            </Card>
        </Box>
    );
}
